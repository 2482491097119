@import "https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap"; /*! @@@@@@@@@@@@@@@@@@@@@@

Template Name: Edukon
Template URI: https://themeforest.net/user/CodexCoder
Author: CodexCoder
Author URI: https://themeforest.net/user/CodexCoder
Description: Description
Version: 1.0.0
Text Domain: edukon
Tags: academy, children, education, institute, kids, kids care, kids care home, kids school, kinder garden, kindergarten, nursery, play school, pre-school, responsive

@@@@@@@@@@@@@@@@@@@@@@ */
.news-letter .section-wrapper,
footer.style-2 .footer-bottom .section-wrapper,
footer.style-2
  .footer-top
  .footer-item.twitter-post
  .footer-inner
  .footer-content
  .content
  ul
  li,
.widget.letest-product .widget-wrapper ul li,
.widget.widget-tags ul.widget-wrapper,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent,
.comment-respond .add-comment .comment-form,
.comments .comment-list .comment,
.navigations-part,
.csdc-lists ul li,
.course-side-detail .csd-content .sidebar-social .ss-content ul,
.course-side-detail .csd-title,
.authors,
.contact-form,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box,
.shop-cart .section-wrapper .cart-top table tbody tr td.product-item,
.shop-single .review .review-content .description .post-item,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  .rating
  ul,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  .rating,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .posted-on,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta,
.shop-single .review .review-nav,
.shop-single .product-details .post-content form,
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .cart-button,
.shop-page .shop-product-wrap .product-list-item,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .product-action-link,
.app-section .section-wrapper ul,
.date li,
.date,
.workshop-join .workshop-join-form form .input-area,
.workshop-join .workshop-join-form form,
.instructor-section.style-2 .section-wrapper .count-item .count-inner,
.contact-info-section .section-wrapper,
.about-section.style-4 .about-item .counter-part .count-item .count-inner,
.about-section.style-4 .about-item .about-content .details li p,
.about-section.style-4 .about-item .about-content .details li,
.about-section.style-4 .about-items,
.about-section .about-right .section-wrapper ul li,
.course-section.style-3 .section-header .course-filter-group ul,
.course-section.style-3 .section-header,
.course-section.style-2 .section-header .course-navigations,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content,
.banner-section.style-4 .banner-content ul,
.banner-section.style-4 .banner-content form,
.banner-section .section-wrapper .banner-content form,
.menu-search-form form,
.menu > ul,
.header-wrapper .menu-area,
.header-wrapper,
.header-top-area .left,
.header-top-area,
.header-section.style-2 .logo-search-acte,
.feature-item .feature-inner,
.event-item .event-inner .event-content .event-content-info ul,
.event-item .event-inner .event-content,
.contact-item,
.skill-item .skill-inner,
.achieve-item .achieve-inner,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider,
.post-item .post-inner .post-footer,
.post-item .post-inner .post-content .meta-post ul,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left,
.stu-feed-item .stu-feed-inner .stu-feed-top,
.instructor-item.style-2 .instructor-inner,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-item.style-3 .course-inner .course-thumb .course-info,
.course-item.style-2 .course-inner,
.course-item .course-inner .course-content .course-footer,
.course-item .course-inner .course-content .course-details,
.course-item .course-inner .course-content .course-category,
.social-icons,
.account-form .form-group .checkgroup,
.default-pagination {
  display: flex;
  flex-wrap: wrap;
}
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent {
  flex-wrap: nowrap;
}
.news-letter .section-wrapper .news-form form .nf-list,
.news-letter .section-wrapper,
.news-footer-wrap,
.footer-top .footer-item .footer-inner .footer-content .title h4,
.widget.widget-search .search-wrapper,
.blog-single .section-wrapper .post-item .post-inner .post-content .video-thumb,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-content
  h6
  span,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-content
  p
  span,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  .or-items
  .or-left,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li,
.overview-announce-section .course-view-bottom ul li a,
.course-view-section .course-view .video-part .content-wrapper,
.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left,
.course-select-area .csa-body ul li .card .card-header h5 button,
.shop-single
  .review
  .review-content
  .description
  .post-item
  .post-content
  ul
  li,
.shop-single .product-details .post-content form .select-product select,
.shop-single .product-details .post-content form .select-product,
.shop-single .product-details .product-thumb,
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .thumb,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb,
.shop-page .shop-product-wrap .product-item .product-thumb,
.shop-page .shop-product-wrap .product-list-item .product-thumb,
.skill-section .container,
.skill-section,
.choose-section,
.student-feedbak-section .section-wrapper .sf-left .sfl-thumb,
.student-feedbak-section,
.register-section .section-wrapper *,
.register-section .section-wrapper,
.clients-section.style-3 .section-wrapper .clients-slider2,
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-thumb,
.clients-section.style-3 .section-header,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb
  img,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb,
.clients-section.style-2 .section-wrapper .clients,
.feature-section *,
.feature-section,
.newsletters-section .newsletter-area .news-mass i,
.newsletters-section .newsletter-area,
.newsletters-section,
.workshop-join .section-wrapper .workshop-title h5,
.event-section.style-2 .section-wrapper,
.event-section *,
.event-section,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  span,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-thumb
  img,
.instructor-single-section .instructor-wrapper .instructor-single-top,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider,
.instructor-section.style-2 .section-wrapper,
.instructor-section.style-2,
.contact-info-section .section-wrapper .cinfo-item .cinfo-inner .cinfo-thumb,
.contact-info-section .section-wrapper .cinfo-or span,
.contact-info-section .section-wrapper,
.about-section.style-3 .about-left,
.about-section .about-right,
.about-section .about-left,
.about-section,
.course-section.style-3,
.course-section.style-2 .section-header,
.category-section.style-4 .section-wrapper .category-item .category-inner,
.pageheader-section.style-2 .pageheader-content .course-category .course-cate,
.pageheader-section.style-2 .pageheader-thumb,
.banner-section.style-5 .section-wrapper .banner-content,
.banner-section.style-4 .banner-content form,
.banner-section.style-4,
.banner-section.style-2 .section-wrapper .thumb-part,
.banner-section.style-2,
.banner-section .section-wrapper .banner-thumb,
.banner-section .section-wrapper .banner-content form,
.banner-section .cbs-content-list ul li.ccl-shape a,
.banner-section,
.menu-item-has-children > a,
.header-bar,
.feature-item .feature-inner .feature-content .lab-btn-text span,
.event-item .event-inner .event-content .event-date-info .edi-box h4,
.achieve-item .achieve-inner,
.count-item .count-inner .count-icon i,
.count-item .count-inner .count-icon,
.post-item.qute-post .post-inner .post-thumb,
.post-item.video-post .post-thumb,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider,
.post-item .post-inner .post-footer .pf-right,
.course-item.style-5 .course-inner .course-thumb,
.course-item.style-4 .course-inner .course-thumb,
.course-item.style-3 .course-inner .course-thumb,
.course-item.style-2 .course-inner .course-thumb,
.course-item .course-inner .course-content .course-category .course-cate a,
.course-item .course-inner .course-content,
.cart-plus-minus,
.or span,
.or,
.shape-img,
.video-button,
.select-item {
  position: relative;
}
.news-letter .section-wrapper .news-form form .nf-list::after,
.news-footer-wrap .fs-shape img,
.footer-top .footer-item .footer-inner .footer-content .title h4::after,
.widget.widget-search .search-wrapper button,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  blockquote::before,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-content
  h6
  span::after,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-content
  p
  span::after,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .view-details,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  .or-items
  .or-left:after,
.overview-announce-section .course-view-bottom ul li a::after,
.course-view-section .course-view .video-part .content-wrapper .content-icon,
.course-select-area
  .csa-body
  ul
  li
  .card
  .card-body
  .course-lists
  .csa-left
  > i,
.course-select-area .csa-body ul li .card .card-header h5 button .icon,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .outline-select
  .select-icon,
.shop-single
  .review
  .review-content
  .description
  .post-item
  .post-content
  ul
  li::before,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  .review-title
  h5::after,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  .review-title
  h5::before,
.shop-single .product-details .post-content form .select-product i,
.shop-single .product-details .product-thumb .pro-single-prev,
.shop-single .product-details .product-thumb .pro-single-next,
.shop-page .modal .modal-dialog .modal-content button.close,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .product-action-link,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb::after,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .pro-thumb::after,
.skill-section::before,
.choose-section::after,
.student-feedbak-section .section-wrapper .sf-left .sfl-thumb .video-button,
.student-feedbak-section::before,
.register-section .section-wrapper::after,
.clients-section.style-3 .section-wrapper .clients-slider-nav,
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-thumb
  .quote,
.clients-section.style-3 .section-header .abs-title,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::before,
.clients-section.style-2 .section-wrapper .clients .client-list,
.feature-section.style-3 .feature-shape,
.feature-section::after,
.newsletters-section::after,
.workshop-join .section-wrapper .workshop-title h5::after,
.event-section.style-2 .section-wrapper .event-navi-item,
.event-section::after,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .skill-item
  .skill-thumb
  .circles
  .circle
  strong,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr::after,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr::before,
.instructor-single-section .instructor-wrapper .instructor-single-top::before,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi,
.instructor-section.style-2::after,
.contact-info-section .section-wrapper .cinfo-or,
.contact-info-section .section-wrapper::after,
.about-section.style-3 .about-left .about-left-content::after,
.about-section.style-3 .about-left .about-left-content,
.about-section.style-3 .about-left .abs-thumb,
.about-section::before,
.course-section.style-3 .course-shape,
.course-section.style-2 .section-header .course-navigations,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner::after,
.pageheader-section.style-2
  .pageheader-content
  .course-category
  .course-cate::before,
.pageheader-section.style-2 .pageheader-thumb .video-button,
.banner-section.style-5::after,
.banner-section.style-4 .banner-content form button,
.banner-section.style-2 .section-wrapper .thumb-part .abs-thumb,
.banner-section.style-2::before,
.banner-section .section-wrapper .banner-content form .banner-icon,
.banner-section .cbs-content-list ul li.ccl-shape a::after,
.banner-section .cbs-content-list ul li.ccl-shape,
.banner-section .all-shapes::after,
.banner-section .all-shapes::before,
.banner-section .all-shapes,
.menu-search-form form,
.menu-item-has-children > a::before,
.menu-item-has-children > a::after,
.header-bar span,
.header-section,
.feature-item .feature-inner .feature-content .lab-btn-text span::after,
.feature-item .feature-inner .feature-content .lab-btn-text span::before,
.event-item .event-inner .event-content .event-date-info .edi-box h4::after,
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-next,
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-prev,
.post-item .post-inner .post-footer .pf-right .comment-count,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-item.style-3 .course-inner .course-thumb::after,
.course-item.style-3 .course-inner .course-thumb .course-info,
.course-item .course-inner .course-price,
.course-item
  .course-inner
  .course-content
  .course-category
  .course-cate
  a::before,
.cart-plus-minus .qtybutton,
.or::before,
.or::after,
.shape-img::after,
.video-button::before,
.video-button::after,
.select-item .select-icon {
  position: absolute;
  content: "";
}
.student-feedbak-section .section-wrapper .sf-left .sfl-thumb .video-button,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .skill-item
  .skill-thumb
  .circles
  .circle
  strong,
.pageheader-section.style-2 .pageheader-thumb .video-button,
.banner-section .all-shapes::after,
.banner-section .all-shapes::before,
.shape-img::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.widget.letest-product .widget-wrapper ul li .product-thumb,
.widget.recipe-categori,
.widget .widget-header,
.comments .comment-list .comment .comment-list .comment,
.comments .comment-list .comment .com-content,
.comments .comment-list .comment .com-thumb,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-thumb,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-thumb,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right.overview-description,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li,
.shop-cart .section-wrapper .cart-bottom,
.shop-single .product-details,
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .product-container,
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .thumb,
.shop-page .shop-product-wrap .product-item .product-thumb,
.shop-page .shop-product-wrap .product-list-item .product-thumb,
.clients-section.style-2,
.feature-section.style-3,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item
  .instructor-inner
  .instructor-thumb,
.instructor-section.style-2,
.course-section.style-3,
.course-section.style-2 .section-wrapper .course-slider,
.sponsor-section .section-wrapper,
.banner-section.style-4 .banner-content form,
.banner-section,
.client-item .client-inner .client-thumb,
.event-item .event-inner .event-thumb,
.achieve-item .achieve-inner,
.post-item.style-3 .post-inner .post-thumb,
.post-item.style-2 .post-inner .post-thumb,
.course-item.style-5 .course-inner,
.course-item.style-3 .course-inner,
.course-item .course-inner .course-thumb,
.preloader,
.yellow-color-section .post-item.style-3 .post-inner .post-thumb,
.cart-plus-minus,
.post-item.style-2 .post-inner .post-content a > h2,
.widget.widget-post ul.widget-wrapper li .post-content a h6,
.post-item.style-2 .post-inner .post-content h3,
.post-item .post-inner .post-content h4,
.course-item .course-inner .course-content h4 {
  overflow: hidden;
}
.news-letter .section-wrapper .news-form form .nf-list input[type="submit"],
.widget.widget-search .search-wrapper button,
.comment-respond .add-comment .comment-form .lab-btn,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .view-details,
.course-select-area .csa-body ul li .card .card-body .course-lists,
.shop-single .product-details .post-content form .lab-btn,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb,
.course-video .course-video-content .accordion-item .accordion-header button,
.course-section.style-3 .section-header .course-filter-group ul li,
.header-bar,
.header-wrapper .menu-area .ellepsis-bar i,
.search-icon {
  cursor: pointer;
}
.news-letter .section-wrapper .news-form form .nf-list,
.news-letter .section-wrapper .news-title,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu,
.widget.shop-widget .widget-wrapper .shop-menu li a,
.widget.widget-post ul.widget-wrapper li .post-thumb a img,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags,
.blog-single .section-wrapper .post-item .post-inner .post-content img,
.blog-single .section-wrapper .post-item .post-inner .post-content iframe,
.blog-single .section-wrapper .post-item .post-inner .post-thumb img,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent,
.comment-respond .add-comment .comment-form input[type="text"],
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev,
.authors .author-thumb img,
.map-area .maps iframe,
.map-area .maps,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .cart-page-input-text,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .outline-select.shipping-select,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .outline-select
  select,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout,
.shop-cart .section-wrapper .cart-top table,
.shop-single .review .review-content .description .post-item .post-content,
.shop-single .review .review-content .description .post-item .post-thumb,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  textarea,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  input,
.shop-single .review .review-content .review-showing .content li .post-content,
.shop-single .product-details .post-content form .discount-code,
.shop-single .product-details .post-content form .select-product select,
.shop-single .product-details .post-content form .select-product,
.shop-single
  .product-details
  .product-thumb
  .pro-single-thumbs
  .single-thumb
  img,
.shop-single .product-details .product-thumb .pro-single-top .single-thumb img,
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .thumb
  .pro-thumb
  img,
.shop-page .shop-product-wrap .product-list-item .product-content,
.shop-page .shop-product-wrap .product-list-item .product-thumb img,
.shop-page .shop-product-wrap .product-list-item .product-thumb,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .product-action-link,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb img,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb img,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb::after,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .pro-thumb::after,
.skill-section::before,
.student-feedbak-section .section-wrapper .sf-left .sfl-thumb img,
.feature-section::after,
.newsletters-section::after,
.date li .count-title,
.date li p,
.event-section::after,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .subtitle,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-thumb
  img,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-thumb,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item
  .instructor-inner
  .instructor-thumb
  img,
.course-video .course-video-content .accordion-item .accordion-header button,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-thumb
  img,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner::after,
.banner-section.style-7
  .section-wrapper
  .banner-bottom
  .flag-item
  .flag-thumb
  img,
.banner-section.style-5::after,
.banner-section.style-4 .banner-content form input,
.menu-search-form,
.header-bar span,
.header-section.header-fixed,
.header-section,
.event-item .event-inner .event-content .event-content-info,
.event-item .event-inner .event-thumb img,
.post-item.qute-post .post-inner .post-thumb .qute-content,
.post-item .post-inner .post-thumb a img,
.instructor-item.style-2 .instructor-inner .instructor-content,
.instructor-item.style-2 .instructor-inner .instructor-thumb,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-item.style-3 .course-inner .course-thumb::after,
.course-item.style-3 .course-inner .course-thumb .course-info,
.course-item.style-2 .course-inner .course-thumb,
.course-item .course-inner .course-thumb img,
.or::before,
.or::after,
.account-form .form-group button,
.account-form .form-group input,
.select-item select,
input,
textarea,
select {
  width: 100%;
}
.comment-respond
  .add-comment
  .comment-form
  p.comment-form-cookies-consent
  input {
  width: auto;
}
.widget.widget-post ul.widget-wrapper li .post-thumb a,
.map-area .maps iframe,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb::after,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .pro-thumb::after,
.skill-section::before,
.feature-section::after,
.newsletters-section .newsletter-area::after,
.newsletters-section::after,
.workshop-join .section-wrapper .workshop-title h5::after,
.event-section::after,
.instructor-section.style-2::after,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner::after,
.banner-section.style-5::after,
.or::before,
.or::after {
  height: 100%;
}
.course-item.style-3 .course-inner .course-thumb::after {
  height: 50%;
}
.post-item .post-inner .post-footer .pf-right .comment-count {
  font-weight: 700;
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .video-thumb
  .video-button,
.newsletters-section .newsletter-area .news-mass::after,
.newsletters-section .newsletter-area .news-mass::before,
.newsletters-section .newsletter-area .news-mass,
.newsletters-section .newsletter-area::after,
.contact-info-section .section-wrapper .cinfo-or::after,
.contact-info-section .section-wrapper .cinfo-or::before,
.count-item .count-inner .count-icon::after,
.count-item .count-inner .count-icon::before,
.post-item.qute-post .post-inner .post-thumb .qute-content,
.post-item.video-post .post-thumb .video-button {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.news-letter .section-wrapper,
.widget.widget-tags ul.widget-wrapper,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .product-action-link,
.app-section .section-wrapper ul,
.offer-section .section-wrapper .date,
.banner-section.style-4 .banner-content ul,
.course-item.style-3 .course-inner .course-content .course-details,
.course-item.style-3 .course-inner .course-thumb .course-info,
.default-pagination {
  justify-content: center;
}
footer.style-2 .footer-bottom .section-wrapper,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section,
.comment-respond .add-comment .comment-form,
.comments .comment-list .comment,
.navigations-part,
.csdc-lists ul li,
.course-side-detail .csd-title,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta,
.shop-single .product-details .post-content form,
.workshop-join .workshop-join-form form .input-area,
.workshop-join .workshop-join-form form,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item,
.contact-info-section .section-wrapper,
.course-section.style-3 .section-header,
.header-wrapper,
.header-top-area,
.event-item .event-inner .event-content,
.achieve-item .achieve-inner,
.post-item .post-inner .post-footer,
.stu-feed-item .stu-feed-inner .stu-feed-top,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-item .course-inner .course-content .course-footer,
.course-item .course-inner .course-content .course-details {
  justify-content: space-between;
}
.shop-single .product-details .post-content p.rating,
.course-item.style-5 .course-inner .course-content .course-footer {
  justify-content: flex-start;
}
.navigations-part .right {
  justify-content: flex-end;
}
.news-letter .section-wrapper,
footer.style-2 .footer-bottom .section-wrapper,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box,
.shop-single .review .review-content .description .post-item,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  .rating,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta,
.shop-single .product-details .post-content form,
.shop-single .product-details .post-content p.rating,
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .cart-button,
.shop-page .shop-product-wrap .product-item,
.shop-page .shop-product-wrap .product-list-item,
.about-section .about-right .section-wrapper ul li,
.course-section.style-3 .section-header,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content,
.header-wrapper .menu-area,
.header-wrapper,
.header-top-area,
.header-section.style-2 .logo-search-acte,
.feature-item .feature-inner,
.contact-item,
.skill-item .skill-inner,
.post-item .post-inner .post-footer,
.post-item .post-inner .post-content .meta-post ul,
.instructor-item.style-2 .instructor-inner,
.course-item.style-2 .course-inner,
.course-item .course-inner .course-content .course-footer,
.account-form .form-group .checkgroup {
  align-items: center;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item {
  align-items: flex-start;
}
.blog-single .section-wrapper .post-item .post-inner .post-content iframe,
.comment-respond .add-comment .comment-form .lab-btn,
.comment-respond .add-comment .comment-form input,
.comment-respond .add-comment .comment-form textarea {
  border: none;
}
.comment-respond .add-comment .comment-form .lab-btn,
.comment-respond .add-comment .comment-form input,
.comment-respond .add-comment .comment-form textarea {
  outline: none;
}
.widget.shop-widget .widget-wrapper .shop-menu li a,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  blockquote
  cite,
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev,
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-content
  a,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  .ratting,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item
  .instructor-inner
  .instructor-thumb
  img,
.course-video .course-video-content .accordion-item .accordion-header button,
.header-wrapper .logo a,
.feature-item .feature-inner .feature-content a,
.event-item.style-2 .event-inner .event-content > span,
.event-item.style-2 .event-inner .event-content,
.post-item .post-inner .post-thumb a,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content span,
.course-item.style-5 .course-inner .course-content .course-cate,
.category-item .category-inner .category-content span,
.or,
.video-button {
  display: block;
}
footer.style-2
  .footer-top
  .footer-item
  .footer-inner
  .footer-content
  .title
  h4::after,
.student-feedbak-section::before,
.feature-section.style-3::after,
.feature-section.style-2::after,
.newsletters-section.style-2 .newsletter-area::after,
.newsletters-section.style-2::after,
.instructor-single-section .instructor-wrapper .instructor-single-top::before,
.about-section.style-4::before,
.about-section::before,
.banner-section.style-2::before,
.header-section.style-7.header-fixed .header-top,
.course-item.style-4
  .course-inner
  .course-thumb
  .course-category
  .course-cate
  a::before {
  display: none;
}
footer.style-2
  .footer-top
  .footer-item.twitter-post
  .footer-inner
  .footer-content
  .content
  ul
  li
  p
  a,
.comments .comment-list .comment .com-content .com-title .com-title-meta span,
.authors .author-content span,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::before,
.workshop-join .section-wrapper .workshop-title h5,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  span,
.course-video .course-video-content .accordion-item .accordion-header span,
.pageheader-section.style-2 .pageheader-content .phs-thumb .course-reiew,
.pageheader-section.style-2 .pageheader-content .phs-thumb span,
.header-bar span,
.header-top-area .social-icons li a,
.feature-item .feature-inner .feature-content .lab-btn-text span,
.count-item .count-inner .count-icon i,
.post-item .post-inner .post-footer .pf-right .comment-count,
.course-item.style-3 .course-inner .course-thumb .course-info li span,
.scrollToTop i,
.lab-btn,
.section-header .subtitle,
a {
  display: inline-block;
}
.widget.widget-post ul.widget-wrapper li .post-content a h6,
.post-item.style-2 .post-inner .post-content h3,
.post-item .post-inner .post-content h4,
.course-item .course-inner .course-content h4 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.post-item.style-2 .post-inner .post-content a > h2 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.news-letter .section-wrapper .news-form form .nf-list input[type="submit"],
.news-letter .section-wrapper .news-title h3,
.footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul
  li
  span,
.footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul
  li
  i,
.footer-top .footer-item.our-address .footer-inner .footer-content .content p,
.footer-top .footer-item .footer-inner .footer-content .content ul li a,
.footer-top .footer-item .footer-inner .footer-content .title h4,
.widget.widget-tags ul.widget-wrapper li a.active,
.widget.widget-tags ul.widget-wrapper li a:hover,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li
  a:hover,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li
  a
  .active,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  blockquote
  cite
  a,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote p,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  blockquote::before,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote,
.course-side-cetagory .csc-title h5,
.course-side-detail .csd-content .sidebar-social .ss-content ul li a,
.course-side-detail .csd-title h4,
.course-side-detail .csd-title p,
.shop-single .product-details .product-thumb .pro-single-prev,
.shop-single .product-details .product-thumb .pro-single-next,
.group-select-section .group-select-left span,
.group-select-section .group-select-left i,
.skill-section .section-header .title,
.achieve-part
  .col:last-child
  .achieve-item
  .achieve-inner
  .achieve-content
  .lab-btn
  span,
.register-section .section-header .title,
.register-section .section-header p,
.feature-section.style-3 .section-wrapper .feature-register form .lab-btn span,
.newsletters-section .newsletter-area .news-mass,
.workshop-join .section-wrapper .workshop-title h5,
.instructor-section.style-2 .section-wrapper .instructor-content .subtitle,
.instructor-section.style-2 .section-wrapper .instructor-content .title,
.instructor-section.style-2 .section-wrapper .instructor-content p,
.instructor-section.style-2
  .section-wrapper
  .count-item
  .count-inner
  .count-content
  p,
.instructor-section.style-2
  .section-wrapper
  .count-item
  .count-inner
  .count-content
  span,
.about-section.style-3 .about-left .about-left-content p,
.about-section.style-3 .about-left .about-left-content h3,
.video-section .section-wrapper h3,
.course-video .course-video-content .accordion-item .accordion-header button,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content
  h6,
.pageheader-section.style-2 .pageheader-content .course-category .course-offer,
.pageheader-section.style-2 .pageheader-content .course-category .course-cate,
.banner-section.style-6 .section-wrapper .banner-content p,
.banner-section.style-6 .section-wrapper .banner-content .title,
.banner-section.style-5 .section-wrapper .banner-content .lab-btn:hover span,
.banner-section .cbs-content-list ul li.ccl-shape a,
.menu-search-form form button,
.header-section.style-6 .header-top .header-top-area .header-top-contact a,
.header-section.style-3 .header-top .header-top-area .header-top-reglog a,
.header-section.style-3 .header-top .header-top-area .header-top-contact a,
.count-item .count-inner .count-icon i,
.post-item.qute-post .post-inner .post-thumb .qute-content h4,
.post-item.style-3 .post-inner .post-content .lab-btn:hover span,
.post-item .post-inner .post-footer .pf-right .comment-count,
.course-item.style-3 .course-inner .course-content .lab-btn:hover span,
.course-item.style-3 .course-inner .course-thumb .course-info li span.price,
.course-item .course-inner .course-price,
.course-item .course-inner .course-content .course-category .course-cate a,
.social-icons li a,
.or,
.clients-slider-nav:hover,
.course-navi {
  color: #fff;
}
.news-letter .section-wrapper .news-form form .nf-list input[type="email"],
.widget.widget-search,
.widget,
.blog-single .section-wrapper .post-item .post-inner .post-content,
.comment-respond,
.comments,
.course-side-cetagory,
.course-side-detail .csd-content,
.authors,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .outline-select,
.shop-cart
  .section-wrapper
  .cart-bottom
  .cart-checkout-box
  .cart-checkout
  input,
.shop-single .review,
.shop-single .product-details .post-content form .select-product,
.shop-single .product-details,
.shop-single .sidebar-widget .widget-search form input,
.shop-page .shop-product-wrap .product-item,
.shop-page .shop-product-wrap .product-list-item,
.shop-page .sidebar-widget .widget-search form input,
.skill-section .section-header .lab-btn,
.register-section .section-wrapper,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.date li,
.event-section.style-2 .section-wrapper .event-navi-item,
.instructor-single-section .instructor-wrapper .instructor-single-bottom,
.instructor-single-section .instructor-wrapper .instructor-single-top,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi,
.instructor-section.style-3 .section-wrapper .instructor-bottom,
.instructor-section.style-2 .section-wrapper .instructor-content .lab-btn,
.offer-section .section-wrapper,
.contact-info-section .section-wrapper .cinfo-item .cinfo-inner .cinfo-thumb,
.course-video
  .course-video-content
  .accordion-item
  .accordion-collapse
  .video-item-list
  li:nth-child(even),
.course-section.style-3 .section-header,
.banner-section.style-7 .section-wrapper .banner-bottom,
.banner-section.style-4 .banner-content ul li,
.banner-section.style-4 .banner-content form input,
.banner-section.style-4 .banner-content form select,
.menu-search-form form input,
.header-wrapper .menu-area .login,
.header-section.style-6 .header-bottom .header-wrapper,
.header-section.style-3 .header-bottom,
.header-section.style-3
  .header-top
  .header-top-area
  .header-top-contact
  a.lab-btn,
.header-section.style-2 .logo-search-acte .header-search form,
.feature-item .feature-inner,
.client-item .client-inner,
.event-item.style-2,
.contact-item,
.skill-item .skill-inner,
.achieve-item .achieve-inner,
.post-item .post-inner,
.stu-feed-item .stu-feed-inner,
.instructor-item,
.course-item.style-5 .course-inner,
.course-item.style-4 .course-inner,
.course-item.style-3 .course-inner .course-content .lab-btn,
.course-item.style-3 .course-inner .course-thumb .course-info li span,
.course-item .course-inner,
.category-item .category-inner,
.account-wrapper,
.video-button::before,
.video-button::after,
.video-button,
.clients-slider-nav {
  background-color: #fff;
}
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul.office-address
  li,
footer.style-2
  .footer-top
  .footer-item
  .footer-inner
  .footer-content
  .content
  ul
  li
  a,
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title h4,
.widget.letest-product .widget-wrapper ul li .product-content h6 a,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a,
.widget.shop-widget .widget-wrapper .shop-menu li a,
.widget.widget-archive .widget-wrapper li a,
.widget.widget-category .widget-wrapper li a,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li
  a,
.comments
  .comment-list
  .comment
  .com-content
  .com-title
  .reply
  .comment-reply-link,
.comments .comment-list .comment .com-content .com-title .com-title-meta h6 a,
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev,
.title-border,
.shop-cart
  .section-wrapper
  .cart-bottom
  .cart-checkout-box
  .cart-checkout
  input,
.shop-cart
  .section-wrapper
  .cart-top
  table
  tbody
  tr
  td.product-item
  .p-content
  a,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .posted-on
  a,
.shop-page .modal .modal-dialog .modal-content button.close:hover,
.shop-page .shop-product-wrap .product-item .product-content h5 a,
.shop-page .shop-product-wrap .product-list-item .product-content h5 a,
.shop-page .shop-title .product-view-mode a,
.shop-page .shop-title p,
.achieve-part .col .achieve-item .achieve-inner .achieve-content .lab-btn span,
.app-section .section-header .lab-btn span,
.register-section .section-wrapper .register-form .lab-btn span,
.clients-section.style-3 .section-wrapper .clients-slider-nav:hover,
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-thumb
  .quote,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner:hover
  .feature-icon,
.newsletters-section.style-2
  .newsletter-area
  .section-wrapper
  .newsletter-form
  button
  span,
.event-section.style-2 .section-wrapper .event-navi-item,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi,
.offer-section .section-wrapper .lab-btn span,
.contact-info-section .section-wrapper .cinfo-or,
.about-section.style-4
  .about-item
  .counter-part
  .count-item
  .count-inner
  .count-content
  p,
.about-section.style-4
  .about-item
  .counter-part
  .count-item
  .count-inner
  .count-content
  span,
.about-section.style-4 .about-item .about-content .details li p,
.course-section.style-4 .section-header .subtitle,
.course-section.style-3 .section-header .course-filter-group ul li.active,
.course-section.style-3 .section-header .course-filter-group ul li:hover,
.course-section.style-3 .section-header .course-filter-group ul li,
.category-section.style-4 .section-wrapper .lab-btn span,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content
  .cate-icon,
.banner-section.style-7
  .section-wrapper
  .banner-top
  .banner-content
  .lab-btn
  span,
.banner-section.style-7 .section-wrapper .banner-top .banner-content p,
.banner-section.style-5 .section-wrapper .banner-content .lab-btn span,
.banner-section.style-5 .section-wrapper .banner-content .subtitle,
.banner-section.style-4 .banner-content ul li,
.banner-section.style-4 .banner-content form select,
.banner-section.style-4 .banner-content h2,
.menu-search-form form input,
.header-section.style-7 .header-bottom .header-wrapper .menu-area .signup,
.header-section.style-5 .header-bottom .menu > ul > li ul li a:hover,
.header-section.style-5 .header-bottom .menu > ul > li ul li a.active,
.header-section.style-4 .lab-btn span,
.header-section.style-4 .menu > ul li ul li a:hover,
.header-section.style-4 .menu > ul li ul li a.active,
.event-item.style-2 .event-inner .event-content .lab-btn span,
.course-item.style-5 .course-inner .course-content .course-footer .course-price,
.course-item.style-4
  .course-inner
  .course-thumb
  .course-category
  .course-reiew
  .ratting
  i,
.course-item.style-4
  .course-inner
  .course-thumb
  .course-category
  .course-cate
  a,
.course-item.style-3
  .course-inner
  .course-thumb
  .course-info
  li
  span.course-name,
.scrollToTop.yellow-color i,
.yellow-color-section
  .post-item.style-3
  .post-inner
  .post-content
  .lab-btn
  span,
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner
  .course-content
  .lab-btn
  span,
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner
  .course-thumb
  ul
  li
  .price,
.cart-plus-minus .qtybutton,
.cart-plus-minus .cart-plus-minus-box,
.video-button:hover i,
.clients-slider-nav,
a:hover,
a:focus,
a:active,
a:visited,
a {
  color: #101115;
}
footer.style-2 .footer-top.dark-view,
.skill-section,
.feature-section.style-3 .section-wrapper .feature-register form .lab-btn,
.instructor-section.style-2,
.banner-section.style-5 .section-wrapper .banner-content .lab-btn:hover,
.header-section.style-7 .header-bar span,
.header-section.style-7 .menu-item-has-children > a::after,
.header-section.style-7 .menu-item-has-children > a::before,
.header-section.style-4 .menu-item-has-children > a::after,
.header-section.style-4 .menu-item-has-children > a::before {
  background: #101115;
}
footer.style-2 .footer-bottom .section-wrapper .footer-bottom-list a:hover,
footer.style-2
  .footer-top
  .footer-item.twitter-post
  .footer-inner
  .footer-content
  .content
  ul
  li
  p
  a,
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul.office-address
  li
  i,
footer.style-2
  .footer-top
  .footer-item
  .footer-inner
  .footer-content
  .content
  ul
  li
  a:hover,
.widget.letest-product .widget-wrapper ul li:hover .product-content h6 a,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a.active,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a:hover,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li.open > a,
.widget.shop-widget .widget-wrapper .shop-menu li.open > a,
.widget.widget-post ul.widget-wrapper li .post-content a h6:hover,
.widget.widget-archive .widget-wrapper li a.active,
.widget.widget-archive .widget-wrapper li a:hover,
.widget.widget-category .widget-wrapper li a.active,
.widget.widget-category .widget-wrapper li a:hover,
.comments .comment-list .comment .com-content .com-title .ratting,
.comments
  .comment-list
  .comment
  .com-content
  .com-title
  .reply
  .comment-reply-link:hover,
.comments
  .comment-list
  .comment
  .com-content
  .com-title
  .com-title-meta
  h6
  a:hover,
.navigations-part .left .next:hover,
.navigations-part .left .next .active,
.navigations-part .left .prev:hover,
.navigations-part .left .prev .active,
.navigations-part .right .next:hover,
.navigations-part .right .next .active,
.navigations-part .right .prev:hover,
.navigations-part .right .prev .active,
.navigations-part .left .title:hover,
.navigations-part .left .title .active,
.navigations-part .right .title:hover,
.navigations-part .right .title .active,
.course-side-cetagory .csc-content .csdc-lists ul li:hover .csdc-left a,
.course-side-cetagory .csc-content .csdc-lists ul li:hover .csdc-right,
.form-message.error,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .cart-overview
  ul
  li
  .pull-right,
.shop-cart
  .section-wrapper
  .cart-bottom
  .cart-checkout-box
  .cart-checkout
  input[type="submit"]:hover,
.shop-cart
  .section-wrapper
  .cart-top
  table
  tbody
  tr
  td.product-item
  .p-content
  a:hover,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .posted-on
  a:hover,
.shop-page .modal .modal-dialog .modal-content button.close,
.shop-page .shop-product-wrap .product-item .product-content h5 a:hover,
.shop-page .shop-product-wrap .product-list-item .product-content h5 a:hover,
.shop-page .shop-title .product-view-mode a.active,
.shop-page .section-header h4 span,
.date li .count-title,
.date li p,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  .ratting
  i,
.instructor-section .section-wrapper .footer-btn p a,
.course-single-section .course-item .course-inner .course-content ul li i,
.pageheader-section.style-2
  .pageheader-content
  .phs-thumb
  .course-reiew
  .ratting,
.header-wrapper .menu-area .ellepsis-bar i,
.header-section.style-3
  .header-top
  .header-top-area
  .header-top-contact
  a.lab-btn
  span,
.feature-item .feature-inner:hover .feature-content a h5,
.event-item .event-inner .event-content .event-content-info ul li i,
.event-item .event-inner:hover .event-content .event-content-info h3,
.event-item .event-inner:hover .event-content .event-content-info h5,
.post-item.style-3 .post-inner .post-content .lab-btn span,
.post-item.style-2 .post-inner .post-content h2.opps,
.post-item.style-2 .post-inner:hover .post-content a > h2,
.post-item.style-2 .post-inner:hover .post-content h3,
.post-item .post-inner .post-content .meta-post ul li a:hover,
.post-item .post-inner .post-content .meta-post ul li i,
.post-item .post-inner:hover .post-content h4,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-right .ratting i,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content h6:hover,
.instructor-item .instructor-inner .instructor-content .ratting i,
.instructor-item:hover .instructor-inner .instructor-content h4,
.course-item.style-3 .course-inner .course-content .lab-btn span,
.course-item.style-3 .course-inner .course-thumb .course-info li span.ratting,
.course-item
  .course-inner
  .course-content
  .course-footer
  .course-author
  .ca-name:hover,
.course-item .course-inner .course-content .course-details .couse-count i,
.course-item .course-inner .course-content .course-details .couse-topic i,
.course-item
  .course-inner
  .course-content
  .course-category
  .course-reiew
  .ratting
  i,
.course-item .course-inner:hover .course-content h4,
.category-item .category-inner .category-content h4:hover,
.category-item .category-inner .category-content h6:hover,
.category-item .category-inner:hover .category-content h6,
.account-form .form-group a:hover,
.video-button i,
.course-showing-part .course-showing-part-right .select-item .select-icon,
.course-showing-part .course-showing-part-right .select-item select,
.theme-color {
  color: #318ce7;
}
.news-letter .section-wrapper .news-form form .nf-list::after,
.form-message.success,
.skill-section .section-header .lab-btn span {
  color: #318ce7;
}
.widget.widget-tags ul.widget-wrapper li a.active,
.widget.widget-tags ul.widget-wrapper li a:hover,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li
  a:hover,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li
  a
  .active,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote,
.course-side-cetagory .csc-title,
.course-side-detail .csd-title,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping button,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .outline-select
  .select-icon,
.shop-cart
  .section-wrapper
  .cart-bottom
  .cart-checkout-box
  .coupon
  input[type="submit"],
.shop-cart .section-wrapper .cart-top table thead tr,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  button,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  .review-title
  h5::before,
.shop-single .review .review-nav li.active,
.shop-single .product-details .post-content form .lab-btn,
.shop-page
  .shop-product-wrap
  .product-item
  .product-thumb
  .product-action-link
  a,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .product-action-link
  a,
.group-select-section,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::before,
.feature-section.style-3 .section-wrapper .feature-register form .lab-btn:hover,
.newsletters-section .newsletter-area .news-mass::after,
.newsletters-section .newsletter-area .news-mass::before,
.newsletters-section .newsletter-area .news-mass,
.workshop-join .section-wrapper .workshop-title h5,
.about-section.style-3 .about-left .about-left-content,
.pageheader-section.style-2 .pageheader-content .course-category .course-offer,
.banner-section .cbs-content-list ul li.ccl-shape a::after,
.banner-section .cbs-content-list ul li.ccl-shape a,
.menu-search-form form button,
.header-bar span,
.header-wrapper .menu-area .signup,
.header-section.style-3 .header-top,
.feature-item .feature-inner .feature-content .lab-btn-text span::after,
.feature-item .feature-inner .feature-content .lab-btn-text span::before,
.feature-item .feature-inner .feature-content .lab-btn-text span,
.count-item .count-inner .count-icon i,
.post-item.style-3 .post-inner .post-content .lab-btn:hover,
.post-item .post-inner .post-footer .pf-right .comment-count,
.course-item.style-3 .course-inner .course-content .lab-btn:hover,
.course-item.style-3 .course-inner .course-thumb .course-info li span.price,
.course-item .course-inner .course-price,
.course-item .course-inner .course-content .course-category .course-cate a,
.scrollToTop i,
.cart-plus-minus .qtybutton:hover,
.social-icons li a,
.or,
.clients-slider-nav:hover,
.course-navi {
  background: #318ce7;
}
.news-letter .section-wrapper .news-form form .nf-list input[type="submit"],
.footer-top .footer-item .footer-inner .footer-content .title h4::after,
.course-side-detail .csd-content .course-enroll .lab-btn,
.skill-section::before,
.achieve-part
  .col:last-child
  .achieve-item
  .achieve-inner
  .achieve-content
  .lab-btn,
.achievement-section.style-3
  .counter-part
  .col:nth-child(2)
  .count-item
  .count-inner
  .count-icon
  i,
.instructor-section.style-2
  .section-wrapper
  .count-item:nth-child(3n + 2)
  .count-inner
  .count-icon
  i,
.about-section.style-4
  .about-item
  .counter-part
  .count-item:nth-child(3n + 2)
  .count-inner
  .count-icon
  i,
.course-video .course-video-content .accordion-item .accordion-header button,
.pageheader-section.style-2 .pageheader-content .course-category .course-cate,
.banner-section .cbs-content-list ul li.shape-1 a::after,
.post-item.qute-post .post-inner .post-thumb,
.course-navi:hover,
.course-navi.active {
  background: #318ce7;
}
.widget.widget-search .search-wrapper button,
.widget.widget-search .search-wrapper input,
.comment-respond .add-comment .comment-form input,
.comment-respond .add-comment .comment-form textarea,
.newsletters-section .newsletter-area::after {
  background: #ecf0f3;
}
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  p,
.widget.widget-tags ul.widget-wrapper li a,
.comment-respond .add-comment .comment-form input[type="text"],
.comments .comment-list .comment .com-content .com-title .com-title-meta span,
.navigations-part .left .title,
.navigations-part .right .title,
.choose-section .section-header .subtitle,
.instructor-section.style-3 .section-header .subtitle,
.offer-section .section-wrapper .date li p,
.banner-section.style-4 .banner-content form button,
.banner-section.style-4 .banner-content form input,
.course-item.style-5
  .course-inner
  .course-content
  .course-footer
  .course-price
  del,
.account-form .form-group a,
.account-form .form-group .checkgroup label,
.section-header .subtitle.desc-color,
p {
  color: #555;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr::after,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr::before {
  background: #555;
}
footer.style-2 .footer-bottom,
.feature-section.style-3,
.section-bg-ash {
  background: #f9f9f9;
}
.news-letter .section-wrapper .news-form form .nf-list::after,
.news-letter .section-wrapper,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .scocial-media,
.course-side-detail .csd-content .sidebar-social .ss-content ul li a,
.form-message,
.shop-cart .section-wrapper .cart-top table thead tr th:nth-child(3),
.register-section .section-wrapper,
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-thumb
  .quote,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.feature-section.style-3 .section-wrapper .feature-register,
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-icon,
.newsletters-section.style-2 .newsletter-area .section-wrapper,
.newsletters-section .newsletter-area .news-mass,
.event-section.style-2 .section-wrapper .event-navi-item,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi,
.offer-section .section-wrapper,
.contact-info-section .section-wrapper .cinfo-item .cinfo-inner,
.contact-info-section .section-wrapper .cinfo-or,
.about-section.style-3 .about-left .about-left-content,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content
  .cate-icon,
.header-top-area .social-icons li a,
.client-item .client-inner,
.event-item.style-2 .event-inner .event-content,
.event-item .event-inner .event-content .event-date-info .edi-box,
.contact-item .contact-thumb,
.count-item .count-inner .count-icon i,
.count-item .count-inner,
.post-item.qute-post .post-inner .post-thumb .qute-content,
.post-item .post-inner .post-footer .pf-right .comment-count,
.instructor-item .instructor-inner,
.course-item .course-inner .course-price,
.scrollToTop i,
.social-icons li a,
.account-wrapper,
.video-button,
.clients-slider-nav,
.course-navi {
  text-align: center;
}
footer.style-2 .footer-bottom .section-wrapper p,
.navigations-part .left,
.instructor-section.style-2 .section-wrapper .count-item .count-inner,
.about-section.style-4 .about-item .counter-part .count-item .count-inner,
.instructor-item.style-2 .instructor-inner {
  text-align: left;
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  blockquote
  cite,
.navigations-part .right {
  text-align: right;
}
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a,
.widget .widget-header .title,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  .title,
.account-wrapper .title {
  text-transform: capitalize;
}
.comment-respond .add-comment .comment-form .lab-btn,
.four-zero-section .four-zero-content .title,
.or {
  text-transform: uppercase;
}
.comments .comment-list .comment .comment-list .comment {
  list-style: none;
}
.news-letter .section-wrapper .news-title h3,
.widget.letest-product .widget-wrapper ul li .product-content h6,
.widget.letest-product .widget-wrapper ul,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu,
.widget.shop-widget .widget-wrapper .shop-menu,
.widget.widget-post ul.widget-wrapper li .post-content p,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags,
.comments .comment-list .comment .com-content .com-title .com-title-meta h6,
.comments .comment-list,
.navigations-part .right,
.navigations-part .left,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul,
.shop-single .review .review-content .description .post-item .post-content ul,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  .rating
  ul,
.shop-single .review .review-content .review-showing .content,
.shop-single .review .review-nav,
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .cart-button
  .cart-plus-minus,
.shop-page .shop-product-wrap .product-item .product-content h5,
.shop-page .shop-product-wrap .product-list-item .product-content h5,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .skill-item
  p,
.instructor-section.style-2
  .section-wrapper
  .count-item
  .count-inner
  .count-icon,
.about-section.style-4
  .about-item
  .counter-part
  .count-item
  .count-inner
  .count-icon,
.header-top-area .social-icons,
.course-item .course-inner .course-content .course-footer .course-author,
.cart-plus-minus .qtybutton,
.cart-plus-minus .cart-plus-minus-box,
.account-form .form-group .checkgroup label {
  margin: 0;
}
.news-letter .section-wrapper .news-form form .nf-list input,
.footer-top .footer-item.our-address .footer-inner .footer-content .content p,
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title,
.widget.letest-product .widget-wrapper ul li .product-content p,
.widget .widget-wrapper,
.widget:last-child,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .scocial-media,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote p,
.comment-respond
  .add-comment
  .comment-form
  p.comment-form-cookies-consent
  label,
.comment-respond
  .add-comment
  .comment-form
  p.comment-form-cookies-consent
  input,
.comments .comment-list .comment .com-content p,
.form-message,
.contact-section .contact-form .form-group:last-child,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .cart-overview
  ul
  li
  .pull-right,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon input,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-content
  p,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .posted-on
  p,
.shop-single .product-details .post-content form .discount-code input,
.shop-page .shop-title p,
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-content
  .client-info
  .client-name,
.date li .count-title,
.date li p,
.workshop-join .section-wrapper .workshop-title h5,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item
  .instructor-inner
  .instructor-content
  h5,
.instructor-section .section-wrapper .footer-btn p,
.about-section.style-3 .about-left .about-left-content p,
.about-section.style-3 .about-left .about-left-content h3,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content
  h6,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-thumb,
.header-top-area .social-icons li p,
.client-item .client-inner .client-content .client-info .client-name,
.event-item .event-inner .event-content .event-date-info .edi-box p,
.contact-item .contact-content p,
.skill-item .skill-inner .skill-content p,
.count-item .count-inner .count-content p,
.post-item.qute-post .post-inner .post-thumb .qute-content h4,
.stu-feed-item .stu-feed-inner .stu-feed-bottom p,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content h6,
.stu-feed-item:last-child .stu-feed-inner,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-showing-part .course-showing-part-left p {
  margin-bottom: 0px;
}
.blog-single .section-wrapper .post-item .post-inner .post-content h2,
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev,
.authors .author-content span,
.shop-single .product-details .post-content p.rating,
.shop-single .product-details .post-content h6,
.shop-page .paginations,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  .ratting,
.instructor-section.style-2 .section-wrapper .instructor-content .subtitle,
.course-single-section .course-item .course-inner .course-content ul,
.pageheader-section.style-2 .pageheader-content .course-category,
.contact-item {
  margin-bottom: 10px;
}
.post-item.style-3 .post-inner .post-content p,
.post-item.style-2 .post-inner .post-content p,
.course-item.style-3 .course-inner .course-content .course-details {
  margin-bottom: 25px;
}
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul.office-address,
.newsletter-form button,
.pageheader-section.style-2 .pageheader-content .phs-thumb {
  margin-top: 10px;
}
.instructor-section .section-wrapper .footer-btn p a {
  margin-left: 5px;
}
.feature-item .feature-inner .feature-content .lab-btn-text:hover span,
.course-item
  .course-inner
  .course-content
  .course-category
  .course-reiew
  .ratting-count {
  margin-left: 10px;
}
footer.style-2 .footer-bottom .section-wrapper .footer-bottom-list a:last-child,
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul
  li
  i,
.widget.shop-widget .widget-wrapper .shop-menu li.menu-item-has-children,
.comments .comment-list .comment .comment-list,
.course-section.style-2
  .section-header
  .course-navigations
  .course-navi:last-child,
.header-section.style-6
  .header-top
  .header-top-area
  .header-top-contact
  a:last-child,
.event-item .event-inner .event-content .event-content-info ul li:last-child,
.post-item .post-inner .post-content .meta-post ul li:last-child,
.course-item.style-3
  .course-inner
  .course-content
  .course-details
  div:last-child {
  margin-right: 0;
}
.widget.widget-archive .widget-wrapper li a i,
.widget.widget-category .widget-wrapper li a i,
.csdc-lists ul li .csdc-left i,
.course-side-detail .csd-title .csdt-right p i,
.group-select-section .group-select-left i,
.choose-section .section-header ul li i,
.instructor-section.style-2
  .section-wrapper
  .count-item
  .count-inner
  .count-content
  span,
.about-section.style-4
  .about-item
  .counter-part
  .count-item
  .count-inner
  .count-content
  span,
.course-single-section .course-item .course-inner .course-content ul li i,
.pageheader-section.style-2
  .pageheader-content
  .phs-thumb
  .course-reiew
  .ratting,
.banner-section.style-4 .banner-content ul li i,
.header-top-area .left li i,
.event-item .event-inner .event-content .event-content-info ul li i,
.post-item .post-inner .post-content .meta-post ul li i,
.instructor-item.style-2 .instructor-inner .instructor-content .ins-info li i,
.instructor-item .instructor-footer ul li i,
.course-item .course-inner .course-content .course-footer .course-author img,
.course-item .course-inner .course-content .course-details .couse-count i,
.course-item .course-inner .course-content .course-details .couse-topic i,
.social-icons li {
  margin-right: 5px;
}
.footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul
  li
  i,
.footer-top .footer-item .footer-inner .footer-content .content ul li a i,
footer.style-2
  .footer-top
  .footer-item.twitter-post
  .footer-inner
  .footer-content
  .content
  ul
  li
  i,
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul.office-address
  li
  i,
.comment-respond
  .add-comment
  .comment-form
  p.comment-form-cookies-consent
  input,
.course-section.style-2 .section-header .course-navigations .course-navi,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content
  .cate-icon,
.pageheader-section.style-2 .pageheader-content .phs-thumb img,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-thumb,
.course-item.style-5
  .course-inner
  .course-content
  .course-footer
  .course-price
  del {
  margin-right: 10px;
}
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul.office-address,
.widget .widget-header .title,
.comments .comment-list .comment .com-thumb,
.four-zero-section .four-zero-content .title,
.four-zero-section .four-zero-content img,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom,
.shop-single .review .review-content .review-showing .content li .post-thumb,
.shop-single .review,
.choose-section .section-header .title,
.feature-section.style-3 .section-wrapper .feature-register form input,
.feature-section.style-3 .section-wrapper .feature-register form select,
.newsletter-form input,
.workshop-join .workshop-join-form form .input-area input,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .skill-item
  .skill-thumb,
.instructor-section.style-2 .section-wrapper .instructor-content .title,
.offer-section .section-header .title,
.course-video .course-video-title,
.post-item .post-inner .post-content .meta-post,
.course-item.style-4 .course-inner .course-content h5,
.category-item .category-inner .category-thumb,
.account-form .form-group,
p {
  margin-bottom: 15px;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  button,
.post-item.qute-post .post-inner .post-thumb .qute-content h4 {
  margin-top: 15px;
}
.course-item.style-5
  .course-inner
  .course-content
  .course-footer
  .course-price {
  margin-left: 15px;
}
footer.style-2 .footer-bottom .section-wrapper .footer-bottom-list a,
.date li,
.pageheader-section.style-2 .pageheader-content .phs-thumb span,
.header-section.style-6 .header-top .header-top-area .header-top-contact a,
.event-item .event-inner .event-content .event-content-info ul li,
.skill-item .skill-inner .skill-thumb,
.post-item .post-inner .post-content .meta-post ul li,
.course-item.style-3 .course-inner .course-content .course-details div,
.account-form .form-group .checkgroup input[type="checkbox"] {
  margin-right: 15px;
}
.blog-single .section-wrapper .post-item .post-inner .post-content p,
.blog-single .section-wrapper .post-item .post-inner .post-content img,
.blog-single .section-wrapper .post-item .post-inner .post-content iframe,
.comment-respond .add-comment .comment-form input,
.comment-respond .add-comment .comment-form textarea,
.authors .author-content p,
.register-section .section-wrapper .register-form input,
.feature-item .feature-inner .feature-thumb,
.event-item.style-2 .event-inner .event-content ul,
.event-item.style-2 .event-inner .event-content > span,
.achieve-item .achieve-inner .achieve-thumb,
.instructor-item .instructor-inner .instructor-thumb,
.course-item.style-5 .course-inner .course-content .course-cate,
.account-wrapper .title,
.course-showing-part {
  margin-bottom: 20px;
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .scocial-media,
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-content
  .client-info {
  margin-top: 20px;
}
.header-wrapper .menu-area .ellepsis-bar {
  margin-left: 20px;
}
.news-letter .section-wrapper .news-form form .nf-list input,
.footer-top .footer-item.our-address .footer-inner .footer-content .content p,
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title,
.widget.letest-product .widget-wrapper ul li .product-content p,
.widget .widget-wrapper,
.widget:last-child,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .scocial-media,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote p,
.comment-respond
  .add-comment
  .comment-form
  p.comment-form-cookies-consent
  label,
.comment-respond
  .add-comment
  .comment-form
  p.comment-form-cookies-consent
  input,
.comments .comment-list .comment .com-content p,
.form-message,
.contact-section .contact-form .form-group:last-child,
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .cart-overview
  ul
  li
  .pull-right,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon input,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-content
  p,
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .posted-on
  p,
.shop-single .product-details .post-content form .discount-code input,
.shop-page .shop-title p,
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-content
  .client-info
  .client-name,
.date li .count-title,
.date li p,
.workshop-join .section-wrapper .workshop-title h5,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item
  .instructor-inner
  .instructor-content
  h5,
.instructor-section .section-wrapper .footer-btn p,
.about-section.style-3 .about-left .about-left-content p,
.about-section.style-3 .about-left .about-left-content h3,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content
  h6,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-thumb,
.header-top-area .social-icons li p,
.client-item .client-inner .client-content .client-info .client-name,
.event-item .event-inner .event-content .event-date-info .edi-box p,
.contact-item .contact-content p,
.skill-item .skill-inner .skill-content p,
.count-item .count-inner .count-content p,
.post-item.qute-post .post-inner .post-thumb .qute-content h4,
.stu-feed-item .stu-feed-inner .stu-feed-bottom p,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content h6,
.stu-feed-item:last-child .stu-feed-inner,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-showing-part .course-showing-part-left p {
  margin-bottom: 0px;
}
.widget.widget-post ul.widget-wrapper li .post-content a h6,
.comments .comment-list .comment .com-content .com-title .com-title-meta span,
.shop-single .product-details .post-content h4,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  p.ins-dege,
.course-video .course-video-content .accordion-item .accordion-header button,
.contact-item .contact-content .title,
.skill-item .skill-inner .skill-content h5,
.post-item .post-inner .post-content p,
.instructor-item.style-2 .instructor-inner .instructor-content h4,
.instructor-item .instructor-inner .instructor-content p,
.instructor-item .instructor-inner .instructor-content h4,
.course-item.style-5 .course-inner .course-content h5 {
  margin-bottom: 5px;
}
.footer-top .footer-item .footer-inner .footer-content .title,
.widget,
.course-side-detail,
.map-area,
.four-zero-section .four-zero-content p,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview h3,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping h3,
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  input,
.shop-single .product-details .post-content p,
.shop-page .shop-product-wrap .product-item,
.shop-page .shop-product-wrap .product-list-item,
.shop-page .shop-title,
.skill-section .section-header .title,
.choose-section .section-header p,
.register-section .section-wrapper h4,
.feature-section.style-3 .section-wrapper .feature-register h3,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-thumb,
.instructor-section.style-2 .section-wrapper .instructor-content p,
.offer-section .section-wrapper .date,
.about-section.style-4 .about-item .about-content p,
.banner-section.style-5 .section-wrapper .banner-content p,
.banner-section.style-4 .banner-content form,
.banner-section.style-4 .banner-content h2,
.event-item.style-2 .event-inner .event-content .lab-btn,
.event-item.style-2 .event-inner .event-content h5,
.account-form .form-group button {
  margin-bottom: 30px;
}
.comments,
.form-message.success,
.form-message.error,
.instructor-section .section-wrapper .footer-btn,
.client-item .client-inner .client-content .client-info,
.account-form .form-group button {
  margin-top: 30px;
}
.header-bar {
  margin-left: 30px;
}
.instructor-section.style-2
  .section-wrapper
  .count-item
  .count-inner
  .count-icon,
.about-section.style-4
  .about-item
  .counter-part
  .count-item
  .count-inner
  .count-icon {
  margin-right: 30px;
}
.widget.letest-product,
.widget.shop-widget,
.widget.recipe-categori,
.widget.widget-tags ul.widget-wrapper li,
.widget.widget-tags,
.widget.widget-instagram ul.widget-wrapper li,
.widget.widget-instagram,
.widget.widget-post ul.widget-wrapper,
.widget.widget-post,
.widget.widget-archive .widget-wrapper li,
.widget.widget-category .widget-wrapper li,
.widget.widget-search .search-wrapper button,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li,
.newsletters-section.style-2 .newsletter-area .section-wrapper,
.newsletters-section.style-2 .newsletter-area .section-header,
.event-section
  .section-wrapper
  .event-right
  .event-item
  .event-inner
  .event-content,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li,
.course-single-section .course-item .course-inner .course-content,
.course-single-section .course-item .course-inner,
.category-section.style-4 .section-wrapper .category-item .category-inner,
.pageheader-section.style-2,
.banner-section.style-4 .banner-content form button,
.header-top-area .social-icons li,
.event-item.style-2 .event-inner .event-content,
.post-item.style-3 .post-inner .post-content,
.post-item.style-3 .post-inner,
.post-item.style-2 .post-inner,
.course-item.style-5 .course-inner,
.course-item.style-4
  .course-inner
  .course-thumb
  .course-category
  .course-cate
  a,
.course-item.style-4 .course-inner,
.course-item.style-3 .course-inner,
.video-button {
  padding: 0;
}
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote {
  padding: 25px;
}
.course-side-detail .csd-content,
.banner-section.style-7 .section-wrapper .banner-bottom,
input,
textarea,
select {
  padding: 10px;
}
.comment-respond .add-comment .comment-form textarea,
.course-side-cetagory .csc-content,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.about-section.style-3 .about-left .about-left-content,
.course-video
  .course-video-content
  .accordion-item
  .accordion-collapse
  .video-item-list
  li,
.course-video .course-video-content .accordion-item .accordion-header button,
.menu-search-form form input,
.header-section.style-6 .header-bottom .header-wrapper,
.skill-item .skill-inner,
.post-item .post-inner .post-content,
.post-item .post-inner,
.instructor-item .instructor-footer,
.course-item.style-2 .course-inner .course-content {
  padding: 15px;
}
.comments .comment-list {
  padding: 20px;
}
.event-item .event-inner .event-content,
.post-item.style-2 .post-inner .post-content,
.instructor-item.style-2 .instructor-inner,
.course-item.style-3 .course-inner .course-content {
  padding: 30px;
}
.comments .comment-list .comment .comment-list .comment:last-child {
  padding-bottom: 0;
}
.course-item.style-4 .course-inner .course-content .course-footer {
  padding-top: 5px;
}
.header-top-area .social-icons li:first-child {
  padding-right: 10px;
}
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu,
.event-section
  .section-wrapper
  .event-right
  .event-item
  .event-inner
  .event-content,
.event-item .event-inner .event-content .event-date-info .edi-box h4 {
  padding-bottom: 15px;
}
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu,
.course-item.style-5 .course-inner .course-content .course-footer,
.course-item .course-inner .course-content .course-footer {
  padding-top: 15px;
}
.widget.letest-product .widget-wrapper ul li .product-content,
.banner-section.style-5 .section-wrapper .banner-content,
.contact-item .contact-content {
  padding-left: 15px;
}
.course-item.style-5 .course-inner .course-content .course-footer .ratting {
  padding-right: 15px;
}
.comments .comment-list .comment .com-content {
  padding-bottom: 20px;
}
.post-item.style-3 .post-inner .post-content {
  padding-top: 20px;
}
.widget.widget-archive .widget-wrapper li a.active,
.widget.widget-archive .widget-wrapper li a:hover,
.widget.widget-category .widget-wrapper li a.active,
.widget.widget-category .widget-wrapper li a:hover {
  padding-left: 30px;
}
footer.style-2
  .footer-top
  .footer-item.twitter-post
  .footer-inner
  .footer-content
  .content
  ul
  li
  i {
  color: #55acee;
}
.choose-section .section-header ul li i,
.register-section .section-header .subtitle,
.feature-section.style-3
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-content
  .lab-btn-text:hover,
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-icon,
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner:hover
  .feature-content
  h5,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item:hover
  .instructor-inner
  .instructor-content
  h5,
.offer-section .section-wrapper .date li .count-title,
.offer-section .section-wrapper .title span,
.contact-info-section
  .section-wrapper
  .cinfo-item
  .cinfo-inner
  .cinfo-content
  h6,
.contact-info-section .section-header .title span,
.video-section .section-wrapper .video-thumb a i,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner:hover
  .category-content
  h6,
.category-section.style-4 .section-header .subtitle,
.banner-section.style-6 .section-wrapper .banner-content .subtitle,
.banner-section.style-4 .banner-content ul li i,
.banner-section.style-4 .banner-content h2 span,
.header-section.style-7 .header-bottom .header-wrapper .menu-area .login,
.header-section.style-5 .header-bottom .menu > ul > li.active > a,
.instructor-item.style-2 .instructor-inner .instructor-content .ins-info li i,
.instructor-item.style-2:hover .instructor-inner .instructor-content h4,
.course-item.style-5 .course-inner .course-content .course-footer .ratting,
.course-item.style-5 .course-inner:hover .course-content h5,
.course-item.style-4
  .course-inner
  .course-content
  .course-footer
  .ca-name:hover,
.course-item.style-4 .course-inner .course-content .course-footer .course-price,
.course-item.style-4 .course-inner:hover .course-content h5,
.yellow-color-section
  .post-item.style-3
  .post-inner
  .post-content
  .meta-post
  ul
  li
  i,
.yellow-color-section .post-item.style-3 .post-inner:hover .post-content h4,
.yellow-color-section.style-2
  .footer-bottom
  .section-wrapper
  .footer-bottom-list
  a:hover,
.yellow-color-section.style-2 .footer-bottom .section-wrapper p a:hover,
.yellow-color-section.style-2
  .footer-top
  .footer-item.twitter-post
  .footer-inner
  .footer-content
  .content
  ul
  li
  a,
.yellow-color-section.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  .office-address
  li
  i,
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner
  .course-content
  .course-details
  .couse-count
  i,
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner
  .course-content
  .course-details
  .couse-topic
  i,
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner
  .course-thumb
  ul
  li
  .ratting,
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner:hover
  .course-content
  h4,
.lab-btn-text.yellow-color:hover,
.lab-btn-text.yellow-color i,
.yellow-color,
.section-header .subtitle.yellow-color {
  color: #ffdf40;
}
.social-icons li a.facebook {
  background: #3b5998;
}
.course-side-detail .csd-content .sidebar-social .ss-content ul li a.twitter,
.social-icons li a.twitter {
  background: #55acee;
}
.social-icons li a.linkedin {
  background: #007bb5;
}
.social-icons li a.google {
  background: #dd4b39;
}
.social-icons li a.instagram {
  background: #e4405f;
}
.achievement-section.style-3
  .counter-part
  .col:nth-child(4)
  .count-item
  .count-inner
  .count-icon::after,
.achievement-section.style-3
  .counter-part
  .col:nth-child(4)
  .count-item
  .count-inner
  .count-icon::before,
.achievement-section.style-3
  .counter-part
  .col:nth-child(4)
  .count-item
  .count-inner
  .count-icon
  i,
.social-icons li a.behance {
  background: #1769ff;
}
.achievement-section.style-3
  .counter-part
  .col:nth-child(3)
  .count-item
  .count-inner
  .count-icon::after,
.achievement-section.style-3
  .counter-part
  .col:nth-child(3)
  .count-item
  .count-inner
  .count-icon::before,
.achievement-section.style-3
  .counter-part
  .col:nth-child(3)
  .count-item
  .count-inner
  .count-icon
  i,
.social-icons li a.dribbble {
  background: #ea4c89;
}
.course-side-detail .csd-content .sidebar-social .ss-content ul li a.rss,
.app-section .section-header .lab-btn:hover,
.register-section .section-wrapper .register-form .lab-btn,
.clients-section.style-3 .section-wrapper .clients-slider-nav:hover,
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-thumb
  .quote,
.feature-section.style-3 .section-wrapper .feature-register,
.feature-section.style-3
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-content
  .lab-btn-text
  span::after,
.feature-section.style-3
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-content
  .lab-btn-text
  span::before,
.feature-section.style-3
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-content
  .lab-btn-text
  span,
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner:hover
  .feature-icon,
.newsletters-section.style-2
  .newsletter-area
  .section-wrapper
  .newsletter-form
  button,
.event-section.style-2 .section-wrapper .event-navi-item:hover,
.event-section.style-2 .section-wrapper .event-navi-item.active,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi.active,
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi:hover,
.instructor-section.style-2
  .section-wrapper
  .count-item:nth-child(3n + 3)
  .count-inner
  .count-icon
  i,
.instructor-section.style-2::after,
.offer-section .section-wrapper .lab-btn,
.contact-info-section .section-wrapper .cinfo-or,
.about-section.style-4
  .about-item
  .counter-part
  .count-item:nth-child(3n + 3)
  .count-inner
  .count-icon
  i,
.course-section.style-4,
.course-section.style-3 .section-header .course-filter-group ul li.active,
.course-section.style-3 .section-header .course-filter-group ul li:hover,
.category-section.style-4 .section-wrapper .lab-btn:hover,
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content
  .cate-icon,
.banner-section.style-7
  .section-wrapper
  .banner-top
  .banner-content
  .lab-btn:hover,
.header-section.style-7 .header-bottom .header-wrapper .menu-area .signup,
.header-section.style-5 .header-bottom .lab-btn,
.header-section.style-5 .header-bottom .menu > ul > li ul li a:hover,
.header-section.style-5 .header-bottom .menu > ul > li ul li a.active,
.header-section.style-4 .lab-btn,
.header-section.style-4 .menu > ul li ul li a:hover,
.header-section.style-4 .menu > ul li ul li a.active,
.event-item.style-2 .event-inner .event-content .lab-btn,
.event-item.style-2 .event-inner .event-content h5,
.scrollToTop.yellow-color i,
.yellow-color-section
  .post-item.style-3
  .post-inner
  .post-content
  .lab-btn:hover,
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner
  .course-content
  .lab-btn,
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner
  .course-thumb
  ul
  li
  .price,
.yellow-color-section .section-header .course-navi {
  background: #ffdf40;
}
.social-icons li a.pinterest {
  background: #bd081c;
}
.course-side-detail .csd-content .sidebar-social .ss-content ul li a.vimeo,
.social-icons li a.vimeo {
  background: #3b5998;
}
@keyframes angle-move {
  0%,
  100% {
    transform: translateY(0) rotate(-6deg);
  }
  50% {
    transform: translateY(-20px) rotate(8deg);
  }
}
@keyframes up-down {
  0%,
  100% {
    transform: translateY(-20px) translateX(15px);
  }
  50% {
    transform: translateY(0);
  }
}
@keyframes up_down_2 {
  0%,
  100% {
    transform: translateY(15px) translateX(15px);
  }
  50% {
    transform: translateY(0);
  }
}
@keyframes up_down_3 {
  0%,
  100% {
    transform: translateY(-10px) translateX(1px);
  }
  50% {
    transform: translateY(0);
  }
}
@keyframes zoom {
  0%,
  100% {
    transform: scale(0.9) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(30deg);
  }
  80% {
    transform: rotate(-30deg);
  }
}
@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes rotate-rev {
  100% {
    transform: rotateZ(-360deg) translate(-50%, -50%);
  }
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes ripple {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes rotate-2 {
  100% {
    transform: rotate(150deg);
  }
}
@keyframes outer-ripple {
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-filter: alpha(opacity=50);
  }
  80% {
    transform: scale(1.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
  }
  100% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    -o-transform: scale(2.5);
  }
}
@-webkit-keyframes outer-ripple {
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  80% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    -o-transform: scale(2.5);
  }
  100% {
    transform: scale(3.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(3.5);
    -moz-transform: scale(3.5);
    -ms-transform: scale(3.5);
    -o-transform: scale(3.5);
  }
}
@-moz-keyframes outer-ripple {
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  80% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    -o-transform: scale(2.5);
  }
  100% {
    transform: scale(3.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(3.5);
    -moz-transform: scale(3.5);
    -ms-transform: scale(3.5);
    -o-transform: scale(3.5);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  90% {
    opacity: 0.5;
    -webkit-transform: translate(-50%, -50%) scale(1.8);
    -ms-transform: translate(-50%, -50%) scale(1.8);
    transform: translate(-50%, -50%) scale(1.8);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    -ms-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}
@-webkit-keyframes circle-2 {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle-2 {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  box-shadow: none;
}
html {
  scroll-behavior: auto !important;
}
body {
  background: #fff;
  color: #555;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #101115;
  font-weight: 700;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 1.3;
}
a > h1,
a > h2,
a > h3,
a > h4,
a > h5,
a > h6 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
h1 {
  font-size: 3.75rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.25rem;
  margin-top: -5px;
}
h6 {
  font-size: 1.125rem;
}
p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 24px;
}
a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
}
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  outline: none;
}
img {
  height: auto;
  max-width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 576px) {
  h1 {
    font-size: 3.75rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.875rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1.125rem;
  }
}
ul.lab-ul {
  margin: 0;
  padding: 0;
}
ul.lab-ul li {
  list-style: none;
}
input,
textarea,
select {
  outline: none;
  border: 1px solid #ecf0f3;
}
button {
  border: none;
  outline: none;
  padding: 13px 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.padding-tb {
  padding: 70px 0;
}
@media (min-width: 992px) {
  .padding-tb {
    padding: 110px 0;
  }
}
.section-bg {
  background: #fff9f1;
}
.section-header {
  margin-bottom: 50px;
}
.section-header .subtitle {
  font-size: 1.125rem;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #318ce7;
  font-weight: 500;
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);
}
.lab-btn {
  padding: 10px 30px;
  background: #318ce7;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 700;
}
.lab-btn span {
  color: #fff;
}
.lab-btn:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgba(241, 97, 38, 0.5);
}
.lab-btn-text {
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: capitalize;
}
.lab-btn-text i {
  color: #318ce7;
  margin-left: 5px;
}
.lab-btn-text:hover {
  color: #318ce7;
}
.header-shadow {
  box-shadow: 0 6px 10px rgba(85, 85, 85, 0.1);
}
.search-icon i {
  font-weight: 700;
}
.default-pagination {
  margin: -7px !important;
  margin-top: 31px !important;
}
@media (min-width: 768px) {
  .default-pagination {
    margin-top: 41px !important;
  }
}
.default-pagination li {
  padding: 7px;
}
.default-pagination li a {
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  color: #101115;
}
.default-pagination li a:hover,
.default-pagination li a.active {
  background: #318ce7;
  box-shadow: none;
  color: #fff;
}
.select-item select {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.select-item .select-icon {
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.course-showing-part .course-showing-part-left p {
  font-size: 1.125rem;
}
.course-showing-part .course-showing-part-right span {
  font-size: 1.125rem;
}
.course-showing-part .course-showing-part-right .select-item select {
  background: transparent;
  border: none;
}
.course-showing-part .course-showing-part-right .select-item .select-icon {
  right: 0;
}
.course-navi {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.clients-slider-nav {
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.video-button {
  z-index: 1;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 1.5rem;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 576px) {
  .video-button {
    width: 92px;
    height: 92px;
    line-height: 88px;
    font-size: 30px;
    border: 2px solid #cfd0fe;
  }
}
.video-button::before,
.video-button::after {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  opacity: 0.15;
  z-index: -10;
}
.video-button::before {
  z-index: -10;
  -webkit-animation: inner-ripple 2000ms linear infinite;
  -moz-animation: inner-ripple 2000ms linear infinite;
  animation: inner-ripple 2000ms linear infinite;
}
.video-button::after {
  z-index: -10;
  -webkit-animation: outer-ripple 2000ms linear infinite;
  -moz-animation: outer-ripple 2000ms linear infinite;
  animation: outer-ripple 2000ms linear infinite;
}
.shape-img::after {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/shape-img/01.png);
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
}
.account-wrapper {
  box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.1);
  padding: 60px 80px;
  max-width: 545px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .account-wrapper {
    padding: 50px 40px;
  }
}
@media screen and (max-width: 499px) {
  .account-wrapper {
    padding: 40px 15px;
  }
}
.account-wrapper .cate {
  font-style: normal;
}
.account-bottom .subtitle {
  margin-bottom: 23px;
}
@media (min-width: 576px) {
  .account-bottom .subtitle {
    font-size: 20px;
  }
}
.account-form .form-group input {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.account-form .form-group .checkgroup {
  flex-wrap: nowrap;
}
.account-form .form-group .checkgroup input[type="checkbox"] {
  width: 18px;
  height: 18px;
}
@media (max-width: 575px) {
  .account-form .form-group .checkgroup input[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}
@media (max-width: 575px) {
  .account-form .form-group .checkgroup label {
    font-size: 14px;
  }
}
.account-form .form-group a {
  text-decoration: underline;
}
@media (max-width: 575px) {
  .account-form .form-group a {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .account-form .form-group button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.or {
  margin: 24px auto 30px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  font-weight: 500;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.or span {
  z-index: 1;
}
.or::before,
.or::after {
  top: 50%;
  left: 50%;
  background: #318ce7;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(241, 97, 38, 0.3);
  animation: pulse 2s linear infinite;
  -webkit-animation: pulse 2s linear infinite;
  -moz-animation: pulse 2s linear infinite;
  opacity: 0;
}
.or::before {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
}
.social-icons {
  margin: -5px;
}
.social-icons li a {
  height: 36px;
  width: 36px;
  line-height: 36px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.social-icons li a:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}
.cart-plus-minus {
  border: 1px solid #ecf0f3;
  width: 120px;
  margin: 0 auto;
  cursor: pointer;
}
.cart-plus-minus .dec.qtybutton {
  border-right: 1px solid #ecf0f3;
  height: 40px;
  left: 0;
  top: 0;
}
.cart-plus-minus .cart-plus-minus-box {
  border: medium none;
  float: left;
  font-size: 14px;
  height: 40px;
  text-align: center;
  width: 120px;
  outline: none;
}
.cart-plus-minus .qtybutton {
  float: inherit;
  font-size: 14px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.cart-plus-minus .qtybutton:hover {
  color: #fff;
}
.cart-plus-minus .inc.qtybutton {
  border-left: 1px solid #ecf0f3;
  right: 0;
  top: 0;
}
.yellow-color-section
  .section-wrapper
  .course-item.style-3
  .course-inner
  .course-content
  .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.yellow-color-section .post-item.style-3 .post-inner .post-content .lab-btn {
  background: transparent;
  border: 2px solid #ffdf40;
}
.yellow-color-section
  .post-item.style-3
  .post-inner
  .post-content
  .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #318ce7;
}
.preloader .preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.preloader .preloader-inner .preloader-icon {
  width: 72px;
  height: 72px;
  display: inline-block;
  padding: 0px;
}
.preloader .preloader-inner .preloader-icon span {
  position: absolute;
  display: inline-block;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background: #fff;
  -webkit-animation: preloader-fx 1.6s linear infinite;
  animation: preloader-fx 1.6s linear infinite;
}
.preloader .preloader-inner .preloader-icon span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}
@keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
@keyframes price-ani {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0.1;
  }
}
.scrollToTop {
  position: fixed;
  bottom: -30%;
  right: 5%;
  z-index: 9;
}
.scrollToTop i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.5rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.scrollToTop i:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 20px 0px rgba(241, 97, 38, 0.5);
}
.scrollToTop.yellow-color i:hover {
  box-shadow: 0px 5px 20px 0px rgba(255, 223, 64, 0.5);
}
.listActive .list,
.gridActive .grid {
  color: #318ce7 !important;
}
.RevActive .rev,
.DescActive .desc {
  background: #318ce7 !important;
}
.search-close {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(16, 17, 21, 0.5);
  cursor: crosshair;
  font-size: 20px;
}
.category-item .category-inner {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 40px 30px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 1440px) {
  .category-item .category-inner {
    padding: 40px;
  }
}
.category-item .category-inner:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.category-item .category-inner .category-thumb img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.category-item .category-inner .category-content h6 {
  font-weight: 500;
}
.category-item .category-inner .category-content span {
  font-weight: 500;
  font-size: 1.125rem;
}
.course-item .course-inner {
  padding: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.course-item .course-inner:hover .course-thumb img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.course-item .course-inner .course-content {
  padding: 30px 15px 0;
}
@media (min-width: 576px) {
  .course-item .course-inner .course-content .course-category {
    margin-bottom: 15px;
  }
}
.course-item .course-inner .course-content .course-category .course-cate {
  margin-right: 10px;
}
@media (max-width: 991px) {
  .course-item .course-inner .course-content .course-category .course-cate {
    margin-bottom: 10px;
  }
}
.course-item .course-inner .course-content .course-category .course-cate a {
  padding: 3px 15px;
  padding-left: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: 500;
}
@media (max-width: 1439px) {
  .course-item .course-inner .course-content .course-category .course-cate a {
    font-size: small;
  }
}
.course-item
  .course-inner
  .course-content
  .course-category
  .course-cate
  a::before {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.5);
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.course-item
  .course-inner
  .course-content
  .course-category
  .course-reiew
  .ratting
  i {
  font-size: 1rem;
}
.course-item .course-inner .course-content .course-details {
  margin: 0px 0 15px;
}
.course-item .course-inner .course-content .course-details .couse-count,
.course-item .course-inner .course-content .course-details .couse-topic {
  font-size: 1.125rem;
}
.course-item .course-inner .course-content .course-footer {
  border-top: 1px solid #ecf0f3;
}
.course-item
  .course-inner
  .course-content
  .course-footer
  .course-author
  .ca-name {
  font-size: 1.125rem;
  font-weight: 500;
}
.course-item .course-inner .course-price {
  width: 60px;
  height: 60px;
  line-height: 60px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  font-weight: 700;
  font-size: 1.25rem;
  right: 20px;
  top: -30px;
}
@media (min-width: 576px) {
  .course-item.style-2 .course-inner .course-thumb {
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .course-item.style-2 .course-inner .course-thumb {
    width: 100%;
  }
}
.course-item.style-2 .course-inner .course-thumb .course-price {
  top: 10px;
  right: auto;
  left: 10px;
}
@media (min-width: 576px) {
  .course-item.style-2 .course-inner .course-content {
    width: 60%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .course-item.style-2 .course-inner .course-content {
    width: 100%;
  }
}
.course-item.style-3 .course-inner {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.course-item.style-3 .course-inner .course-thumb .course-info {
  bottom: 10px;
  z-index: 9;
}
.course-item.style-3 .course-inner .course-thumb .course-info li {
  margin: 3px;
}
.course-item.style-3 .course-inner .course-thumb .course-info li span {
  padding: 5px 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.course-item.style-3
  .course-inner
  .course-thumb
  .course-info
  li
  span.course-name {
  font-weight: 700;
}
.course-item.style-3 .course-inner .course-thumb .course-info li span.ratting {
  font-size: small;
  line-height: 24px;
}
.course-item.style-3 .course-inner .course-thumb::after {
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    #05193700,
    #05193747,
    #05193794,
    #051937c4,
    #051937db
  );
}
.course-item.style-3 .course-inner .course-content {
  padding-bottom: 40px;
}
.course-item.style-3 .course-inner .course-content .lab-btn {
  border: 1px solid #ecf0f3;
}
.course-item.style-3 .course-inner .course-content .lab-btn:hover {
  border-color: transparent;
}
.course-item.style-4 .course-inner {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.course-item.style-4 .course-inner:hover .course-thumb img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.course-item.style-4 .course-inner .course-thumb .course-category {
  padding: 5px 15px;
  left: 0;
  bottom: 0;
  background: rgba(255, 223, 64, 0.85);
}
.course-item.style-4 .course-inner .course-thumb .course-category .course-cate {
  margin-right: 10px;
}
@media (max-width: 991px) {
  .course-item.style-4
    .course-inner
    .course-thumb
    .course-category
    .course-cate {
    margin-bottom: 10px;
  }
}
.course-item.style-4
  .course-inner
  .course-thumb
  .course-category
  .course-cate
  a {
  font-weight: 700;
}
.course-item.style-4 .course-inner .course-content {
  padding: 20px 15px 5px;
}
.course-item.style-4
  .course-inner
  .course-content
  .course-footer
  .course-price {
  position: static;
  font-size: 1.25rem;
  font-weight: 500;
  width: auto;
  height: auto;
  line-height: 1;
  background: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.course-item.style-5 .course-inner .course-content {
  padding: 30px 23px 20px;
}
.course-item.style-5 .course-inner .course-content .course-footer .ratting {
  font-size: 1rem;
  border-right: 1px solid #ecf0f3;
}
.course-item.style-5
  .course-inner
  .course-content
  .course-footer
  .course-price {
  position: static;
  font-size: 1.125rem;
  font-weight: 700;
  width: auto;
  height: auto;
  line-height: 1;
  background: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.course-item.style-5
  .course-inner
  .course-content
  .course-footer
  .course-price
  del {
  font-weight: 500;
}
.instructor-item {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.instructor-item:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.instructor-item .instructor-inner {
  padding: 30px 15px 20px;
}
.instructor-item .instructor-inner .instructor-thumb img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.instructor-item .instructor-inner .instructor-content .ratting i {
  font-size: 1rem;
}
.instructor-item .instructor-footer {
  border-top: 1px solid #ecf0f3;
}
.instructor-item.style-2 {
  box-shadow: 0 2px 4px rgba(16, 17, 21, 0.06);
}
@media (min-width: 576px) {
  .instructor-item.style-2 .instructor-inner .instructor-thumb {
    width: 30%;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .instructor-item.style-2 .instructor-inner .instructor-thumb {
    width: 50%;
  }
}
.instructor-item.style-2 .instructor-inner .instructor-thumb img {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
@media (max-width: 575px) {
  .instructor-item.style-2 .instructor-inner .instructor-thumb img {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .instructor-item.style-2 .instructor-inner .instructor-content {
    width: 70%;
    padding-left: 30px;
  }
}
@media (min-width: 992px) {
  .instructor-item.style-2 .instructor-inner .instructor-content {
    width: 50%;
  }
}
.instructor-item.style-2
  .instructor-inner
  .instructor-content
  .social-icons
  li
  a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.instructor-item.style-2 .instructor-inner .instructor-content .ins-info {
  margin: 10px 0 20px;
}
.stu-feed-item .stu-feed-inner {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(136, 136, 136, 0.1);
  padding: 20px 30px;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .stu-feed-item .stu-feed-inner {
    padding: 10px 30px;
  }
}
.stu-feed-item .stu-feed-inner .stu-feed-top {
  padding: 15px 0;
  border-bottom: 1px solid #ecf0f3;
}
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-thumb img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content span {
  font-size: 1.125rem;
}
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-right .ratting i {
  font-size: 1rem;
}
.stu-feed-item .stu-feed-inner .stu-feed-bottom {
  padding: 20px 0 10px;
}
.stu-feed-item .stu-feed-inner .stu-feed-bottom p {
  font-size: 1.125rem;
}
.post-item .post-inner {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(16, 17, 21, 0.1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.post-item .post-inner:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.post-item .post-inner .post-content {
  padding-top: 20px;
}
.post-item .post-inner .post-content .meta-post ul li a {
  font-weight: 500;
}
.post-item .post-inner .post-content p {
  font-size: 1.125rem;
}
.post-item .post-inner .post-footer {
  margin: 0 15px;
  padding-top: 15px;
  border-top: 1px solid #ecf0f3;
}
.post-item .post-inner .post-footer .pf-right i {
  font-size: 1.5rem;
}
.post-item .post-inner .post-footer .pf-right .comment-count {
  width: 16px;
  height: 16px;
  line-height: 16px;
  top: -5px;
  left: 5px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  font-size: 12px;
  box-shadow: 0 3px 10px rgba(16, 17, 21, 0.1);
}
.post-item.style-2 .post-inner:hover {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.post-item.style-2 .post-inner:hover .post-thumb a img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.post-item.style-2 .post-inner .post-content h2.not-ruselt {
  font-weight: 400;
}
.post-item.style-2 .post-inner .post-content h2.opps {
  margin: 10px 0 0;
}
@media (min-width: 992px) {
  .post-item.style-2 .post-inner .post-content h2.opps {
    font-size: 60px;
  }
}
.post-item.style-3 .post-inner {
  box-shadow: none;
}
@media (max-width: 575px) {
  .post-item.style-3 .post-inner {
    margin-bottom: 15px;
  }
}
.post-item.style-3 .post-inner:hover {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.post-item.style-3 .post-inner:hover .post-thumb a img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.post-item.style-3 .post-inner .post-content .lab-btn {
  background: transparent;
  border: 2px solid #318ce7;
}
.post-item.slider-post
  .post-inner:hover
  .post-thumb.post-thumb-slider
  .post-thumb-slider-next {
  left: 10px;
}
.post-item.slider-post
  .post-inner:hover
  .post-thumb.post-thumb-slider
  .post-thumb-slider-prev {
  right: 10px;
}
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider img {
  width: 100%;
}
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-next,
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-prev {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  color: #101115;
  z-index: 1;
  text-align: center;
  top: 50%;
  outline: none;
  cursor: pointer;
  transform: translateY(-50%);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-next:hover,
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-next
  .active,
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-prev:hover,
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-prev
  .active {
  background: #318ce7;
  color: #fff;
}
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-next {
  left: -10%;
}
.post-item.slider-post
  .post-inner
  .post-thumb.post-thumb-slider
  .post-thumb-slider-prev {
  right: -10%;
}
.post-item.qute-post .post-inner .post-thumb {
  height: 190px;
}
@media (min-width: 576px) {
  .post-item.qute-post .post-inner .post-thumb {
    height: 320px;
  }
}
.post-item.qute-post .post-inner .post-thumb .qute-content {
  padding: 20px;
}
.post-item.qute-post .post-inner .post-thumb .qute-content h4 {
  line-height: 1.5;
}
@media (max-width: 767px) {
  .post-item.qute-post .post-inner .post-thumb .qute-content h4 {
    display: none;
  }
}
.count-item .count-inner {
  padding: 5px 30px;
}
@media (min-width: 576px) {
  .count-item .count-inner {
    padding: 30px;
  }
}
.count-item .count-inner:hover .count-icon::before {
  -webkit-animation: circle-2 1.05s infinite;
  -moz-animation: circle-2 1.05s infinite;
  -ms-animation: circle-2 1.05s infinite;
  -o-animation: circle-2 1.05s infinite;
  animation: circle-2 1.05s infinite;
}
.count-item .count-inner:hover .count-icon::after {
  -webkit-animation: circle-2 1.08s infinite;
  -moz-animation: circle-2 1.08s infinite;
  -ms-animation: circle-2 1.08s infinite;
  -o-animation: circle-2 1.08s infinite;
  animation: circle-2 1.08s infinite;
}
.count-item .count-inner .count-icon {
  width: 90px;
  height: 90px;
  margin: 0 auto 30px;
}
.count-item .count-inner .count-icon i {
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 1.875rem;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  z-index: 1;
}
.count-item .count-inner .count-icon::after,
.count-item .count-inner .count-icon::before {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.count-item .count-inner .count-icon::before {
  width: calc(100% + 24px);
  height: calc(100% + 24px);
  background: rgba(241, 97, 38, 0.1);
}
.count-item .count-inner .count-icon::after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  background: rgba(241, 97, 38, 0.2);
}
.achieve-item .achieve-inner {
  padding: 30px;
  box-shadow: 0 5px 10px rgba(16, 17, 21, 0.1);
}
@media (min-width: 576px) {
  .achieve-item .achieve-inner .achieve-thumb {
    width: 65%;
    position: absolute;
    bottom: 0;
    right: -60px;
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .achieve-item .achieve-inner .achieve-content {
    width: 60%;
  }
}
.achieve-item .achieve-inner .achieve-content p {
  margin-bottom: 25px;
}
.skill-item .skill-inner {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .skill-item .skill-inner {
    text-align: center;
  }
}
.skill-item .skill-inner:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.5);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .skill-item .skill-inner .skill-thumb {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .skill-item .skill-inner .skill-content {
    width: 100%;
  }
}
.contact-item {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 20px 30px;
  box-shadow: 0 0px 10px rgba(136, 136, 136, 0.1);
}
.contact-item .contact-thumb {
  max-width: 50px;
}
.contact-item .contact-content {
  width: calc(100% - 50px);
}
@media (max-width: 575px) {
  .event-item .event-inner {
    margin-bottom: 15px;
  }
}
.event-item .event-inner:hover .event-thumb img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.event-item .event-inner .event-thumb {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.event-item .event-inner .event-content .event-date-info {
  width: 95px;
}
.event-item .event-inner .event-content .event-date-info .edi-box {
  padding: 15px 10px;
  border: 2px solid #318ce7;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media (max-width: 575px) {
  .event-item .event-inner .event-content .event-date-info .edi-box {
    margin-bottom: 10px;
  }
}
.event-item .event-inner .event-content .event-date-info .edi-box h4::after {
  width: 20px;
  height: 2px;
  border-bottom: 2px dotted #ecf0f3;
  bottom: 0;
  left: 50%;
  border-width: 5px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.event-item .event-inner .event-content .event-date-info .edi-box p {
  font-size: 1rem;
}
@media (min-width: 576px) {
  .event-item .event-inner .event-content .event-content-info {
    width: calc(100% - 110px);
  }
}
.event-item .event-inner .event-content .event-content-info h3,
.event-item .event-inner .event-content .event-content-info h5 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.event-item.style-2 .event-inner {
  border-right: 1px solid #f9f9f9;
}
.event-item.style-2 .event-inner .event-thumb {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.event-item.style-2 .event-inner .event-content h5 {
  padding: 10px 0;
}
.event-item.style-2 .event-inner .event-content ul li {
  padding: 5px 0;
}
.event-item.style-2 .event-inner .event-content .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.client-item .client-inner {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 50px 30px;
}
.client-item .client-inner .client-thumb {
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;
  border: 2px solid #318ce7;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.client-item .client-inner .client-content p {
  line-height: 1.5;
}
.client-item .client-inner .client-content .client-info .client-degi {
  font-size: small;
}
.feature-item .feature-inner {
  padding: 50px 30px;
  box-shadow: 0 2px 4px rgba(16, 17, 21, 0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  .feature-item .feature-inner {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-item .feature-inner {
    text-align: center;
  }
}
.feature-item .feature-inner:hover {
  box-shadow: 0 24px 46px rgba(16, 17, 21, 0.12);
}
.feature-item .feature-inner .feature-thumb {
  width: 100%;
}
@media (min-width: 576px) {
  .feature-item .feature-inner .feature-thumb {
    margin-right: 20px;
    margin-bottom: 0;
    width: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-item .feature-inner .feature-thumb {
    margin-bottom: 20px;
    width: 100%;
  }
}
@media (max-width: 575px) {
  .feature-item .feature-inner .feature-content {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-item .feature-inner .feature-content {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .feature-item .feature-inner .feature-content a h5 span {
    display: block;
  }
}
.feature-item .feature-inner .feature-content .lab-btn-text:hover span {
  opacity: 1;
}
.feature-item .feature-inner .feature-content .lab-btn-text span {
  width: 20px;
  height: 2px;
  margin-left: -15px;
  opacity: 0;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.feature-item .feature-inner .feature-content .lab-btn-text span::after,
.feature-item .feature-inner .feature-content .lab-btn-text span::before {
  width: 10px;
  height: 2px;
  right: 0;
}
.feature-item .feature-inner .feature-content .lab-btn-text span::after {
  transform: rotate(-45deg) translateY(4px);
}
.feature-item .feature-inner .feature-content .lab-btn-text span::before {
  transform: rotate(45deg) translateY(-4px);
}
.header-section {
  background: transparent;
  z-index: 999;
}
.header-section.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  animation: fadeInDown 0.5s;
  -webkit-animation: fadeInDown 0.5s;
  -moz-animation: fadeInDown 0.5s;
  box-shadow: 0 0 10px rgba(16, 17, 21, 0.1);
  z-index: 999;
}
@media (min-width: 992px) {
  .header-section.header-fixed {
    top: -48px;
  }
}
@media (min-width: 1200px) {
  .header-section.header-fixed {
    top: -55px;
  }
}
.header-section.header-fixed .header-bottom {
  background: #fdfdfd73;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.header-section.style-2 .header-wrapper {
  padding: 20px;
  box-shadow: 0 6px 10px rgba(85, 85, 85, 0.1);
}
.header-section.style-2 .logo-search-acte .header-cate {
  margin: 0 40px;
}
.header-section.style-2 .logo-search-acte .header-cate select {
  border: none;
  background: transparent;
}
.header-section.style-2 .logo-search-acte .header-search form {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.header-section.style-2 .logo-search-acte .header-search form input {
  border: none;
  width: auto;
}
.header-section.style-2 .logo-search-acte .header-search form button {
  background: transparent;
}
@media (min-width: 992px) {
  .header-section.style-2.header-fixed {
    top: 0;
  }
}
@media (min-width: 1200px) {
  .header-section.style-2.header-fixed {
    top: 0;
  }
}
.header-section.style-3 {
  top: 0;
}
@media (min-width: 992px) {
  .header-section.style-3 {
    position: static;
  }
}
.header-section.style-3.header-fixed {
  position: fixed;
  border: none;
}
.header-section.style-3 .header-top .header-top-area {
  padding: 8px 0;
}
@media (max-width: 575px) {
  .header-section.style-3 .header-top .header-top-area {
    text-align: center;
  }
}
.header-section.style-3 .header-top .header-top-area .header-top-contact a {
  letter-spacing: 1.5px;
  padding: 0 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
}
.header-section.style-3
  .header-top
  .header-top-area
  .header-top-contact
  a:last-child {
  border-right: none;
}
.header-section.style-3
  .header-top
  .header-top-area
  .header-top-contact
  a.lab-btn {
  padding: 3px 15px;
  font-size: 14px;
  letter-spacing: normal;
}
.header-section.style-3
  .header-top
  .header-top-area
  .header-top-contact
  a.lab-btn:hover {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  box-shadow: none;
}
@media (max-width: 575px) {
  .header-section.style-3 .header-top .header-top-area .header-top-reglog {
    width: 100%;
  }
}
.header-section.style-3 .header-top .header-top-area .header-top-reglog a {
  padding: 0 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
}
.header-section.style-3
  .header-top
  .header-top-area
  .header-top-reglog
  a:last-child {
  padding-right: 0;
  border-right: none;
}
.header-section.style-3 .header-bottom {
  box-shadow: 0 6px 10px rgba(85, 85, 85, 0.1);
}
@media (min-width: 992px) {
  .header-section.style-3 .header-bottom .header-wrapper {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .header-section.style-3 .header-bottom .menu > ul {
    margin-right: 20px;
  }
}
@media (min-width: 992px) {
  .header-section.style-3 .header-bottom .menu > ul > li:hover a::after,
  .header-section.style-3 .header-bottom .menu > ul > li.active a::after {
    -webkit-transform: scaleX(1) translateY(-50%);
    -ms-transform: scaleX(1) translateY(-50%);
    transform: scaleX(1) translateY(-50%);
  }
}
@media (min-width: 992px) {
  .header-section.style-3
    .header-bottom
    .menu
    > ul
    > li.menu-item-has-children
    > a::before {
    display: none;
  }
}
@media (min-width: 992px) {
  .header-section.style-3 .header-bottom .menu > ul > li > a {
    padding: 30px 22px;
  }
}
@media (min-width: 992px) {
  .header-section.style-3 .header-bottom .menu > ul > li > a::after {
    width: 100%;
    height: 2px;
    left: 0;
    top: 100%;
    position: absolute;
    content: "";
    background: #318ce7;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transform-origin: left;
    z-index: 1;
  }
}
.header-section.style-4 {
  border: none;
}
.header-section.style-4.header-fixed {
  top: 0;
}
.header-section.style-4 .header-top {
  background: #fdfdfd73;
  padding: 15px 0;
}
@media (max-width: 991px) {
  .header-section.style-4 .menu > ul {
    background: #ffdf40;
  }
  .header-section.style-4 .menu > ul li a {
    color: #101115;
    border-top: 1px solid rgba(16, 17, 21, 0.1);
  }
  .header-section.style-4 .menu > ul li a:hover {
    background: #ffdf40;
  }
}
.header-section.style-4 .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.header-section.style-5.header-fixed {
  top: 0;
}
@media (min-width: 992px) {
  .header-section.style-5 .header-bottom .menu > ul > li > a::after {
    background: #ffdf40;
  }
}
.header-section.style-5 .header-bottom .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.header-section.style-6 .header-top {
  background: transparent;
}
.header-section.style-6 .header-bottom .header-wrapper {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.header-section.style-6.header-fixed {
  box-shadow: none;
}
.header-section.style-6.header-fixed .header-top {
  display: none;
}
.header-section.style-6.header-fixed .header-bottom {
  background: transparent;
  backdrop-filter: blur(0px);
}
.header-section.style-6.header-fixed .header-bottom .header-wrapper {
  backdrop-filter: blur(20px);
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.header-section.style-7 {
  top: 0;
}
@media (min-width: 992px) {
  .header-section.style-7 .header-top-area .social-icons li a {
    color: #ffdf40;
  }
}
@media (min-width: 992px) {
  .header-section.style-7 .menu > ul li ul {
    background: #fff;
    box-shadow: 0 0 10px rgba(16, 17, 21, 0.1);
  }
  .header-section.style-7 .menu > ul li ul li a {
    color: #101115;
  }
  .header-section.style-7 .menu > ul li ul li a:hover,
  .header-section.style-7 .menu > ul li ul li a.active {
    background: #ffdf40;
    color: #101115;
  }
  .header-section.style-7 .menu > ul li ul li a.active::after,
  .header-section.style-7 .menu > ul li ul li a.active::before {
    background: #101115;
  }
}
@media (max-width: 991px) {
  .header-section.style-7 .menu > ul {
    background: #ffdf40;
  }
  .header-section.style-7 .menu > ul li a {
    color: #101115;
    border-top: 1px solid rgba(25, 24, 24, 0.1);
  }
  .header-section.style-7 .menu > ul li a:hover {
    background: rgba(255, 223, 64, 0.6);
  }
}
.header-top {
  border-bottom: 1px solid rgba(16, 17, 21, 0.1);
}
@media (max-width: 991px) {
  .header-top {
    display: none;
    background-color: rgba(241, 97, 38, 0.95);
  }
  .header-top.open {
    display: block !important;
  }
}
.header-top-area .social-icons li {
  border-left: 1px solid rgba(16, 17, 21, 0.1);
}
.header-top-area .social-icons li a {
  height: 50px;
  line-height: 50px;
  width: 50px;
  color: #318ce7;
  background: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.header-top-area .social-icons li:first-child {
  border-left: none;
}
.header-top-area .social-icons li:nth-child(2) {
  border-left: none;
}
.header-top-area .left {
  margin: 0 -25px;
}
.header-top-area .left li {
  padding: 15px 25px;
  border-right: 1px solid rgba(16, 17, 21, 0.1);
}
.header-top-area .left li:last-child {
  border-right: none;
}
@media (max-width: 1199px) {
  .header-top-area .left li {
    font-size: 14px;
    padding: 9px 15px;
  }
  .header-top-area .social-icons li a {
    height: 46px;
    line-height: 46px;
    width: 46px;
  }
}
@media (max-width: 991px) {
  .header-top-area .left {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header-top-area .left li {
    flex-grow: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-right: none;
    color: #fff;
  }
  .header-top-area .left li:first-child {
    border-top: none;
  }
  .header-top-area .social-icons {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header-top-area .social-icons li {
    flex-grow: 1;
    border-color: rgba(255, 255, 255, 0.1);
  }
  .header-top-area .social-icons li p,
  .header-top-area .social-icons li a {
    color: #fff;
  }
}
.header-wrapper {
  padding: 19px 0;
}
@media (min-width: 576px) {
  .header-wrapper {
    position: relative;
  }
}
.header-wrapper .logo a img {
  max-width: 100%;
}
@media (max-width: 575px) {
  .header-wrapper .logo {
    width: 160px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .header-wrapper .logo {
    width: 200px;
  }
}
.header-wrapper .menu-area .login,
.header-wrapper .menu-area .signup {
  display: none;
}
@media (min-width: 768px) {
  .header-wrapper .menu-area .login,
  .header-wrapper .menu-area .signup {
    padding: 10px;
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  .header-wrapper .menu-area .login,
  .header-wrapper .menu-area .signup {
    padding: 15px;
  }
}
.header-wrapper .menu-area .login {
  color: #318ce7;
  border-radius: 2px 0 0 2px;
}
.header-wrapper .menu-area .signup {
  color: #fff;
  border-radius: 0 2px 2px 0;
}
.header-wrapper .menu-area .ellepsis-bar {
  font-size: 20px;
}
@media (max-width: 575px) {
  .header-wrapper .menu-area {
    padding: 15px 0;
  }
  .header-wrapper .menu-area .ellepsis-bar {
    margin-left: 20px;
  }
}
@media (min-width: 992px) {
  .menu > ul {
    margin-right: 30px;
  }
}
.menu > ul > li > a {
  color: #101115;
  font-size: 1rem;
  font-weight: 700;
  padding: 15px 22px;
  text-transform: capitalize;
}
.menu > ul > li > a i {
  margin-right: 5px;
}
.menu > ul > li ul {
  margin-right: 0;
}
.menu > ul > li ul li {
  width: 100%;
}
@media (min-width: 992px) {
  .menu > ul li {
    position: relative;
  }
  .menu > ul li ul {
    position: absolute;
    top: calc(100%);
    left: 0;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
    z-index: 9;
    background: #fff;
    width: 220px;
    padding: 0;
    -webkit-transform: translateY(35px);
    -ms-transform: translateY(35px);
    transform: translateY(35px);
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 10px rgba(16, 17, 21, 0.1);
  }
  .menu > ul li ul li {
    padding: 0;
  }
  .menu > ul li ul li a {
    padding: 10px 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    border-bottom: 1px solid #ecf0f3;
  }
  .menu > ul li ul li a:hover,
  .menu > ul li ul li a.active {
    background: #318ce7;
    padding-left: 22px;
    color: #fff;
  }
  .menu > ul li ul li a.active::after,
  .menu > ul li ul li a.active::before {
    background: #fff;
  }
  .menu > ul li ul li:last-child a {
    border-bottom: none;
  }
  .menu > ul li ul li ul {
    left: 100%;
    top: 0;
    display: none;
  }
  .menu > ul li:hover > ul {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
    display: block;
  }
}
@media (max-width: 991px) {
  .menu > ul {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    overflow: auto;
    max-height: 400px;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: top;
    background: #318ce7;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  .menu > ul li {
    width: 100%;
    padding: 0;
  }
  .menu > ul li a {
    display: block;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .menu > ul li a:hover {
    background: rgba(241, 97, 38, 0.6);
  }
  .menu > ul li a.show::before {
    display: none;
  }
  .menu > ul li ul {
    padding-left: 20px;
    display: none;
    position: static !important;
    padding: 0;
    background: transparent;
    transform: translate3d(0px, 0px, 0px) !important;
    border: none;
  }
  .menu > ul li ul li {
    width: 100%;
  }
  .menu > ul li ul li a {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  .menu > ul.active {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}
.header-bar {
  width: 25px;
  height: 20px;
}
.header-bar span {
  height: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  left: 0;
}
.header-bar span:first-child {
  top: 0;
}
.header-bar span:nth-child(2) {
  top: 52%;
  transform: translateY(-65%);
}
.header-bar span:last-child {
  bottom: 0;
}
.header-bar.active span:first-child {
  -webkit-transform: rotate(45deg) translate(3px, 9px);
  -ms-transform: rotate(45deg) translate(3px, 9px);
  transform: rotate(45deg) translate(3px, 9px);
}
.header-bar.active span:nth-child(2) {
  opacity: 0;
}
.header-bar.active span:last-child {
  -webkit-transform: rotate(-45deg) translate(3px, -9px);
  -ms-transform: rotate(-45deg) translate(3px, -9px);
  transform: rotate(-45deg) translate(3px, -9px);
}
@media (max-width: 575px) {
  .header-bar {
    width: 15px;
    height: 16px;
  }
  .header-bar span {
    height: 2px;
    width: 20px;
  }
  .header-bar.active span:first-child {
    -webkit-transform: rotate(45deg) translate(4px, 6px);
    -ms-transform: rotate(45deg) translate(4px, 6px);
    transform: rotate(45deg) translate(4px, 6px);
  }
  .header-bar.active span:nth-child(2) {
    opacity: 0;
  }
  .header-bar.active span:last-child {
    -webkit-transform: rotate(-45deg) translate(4px, -6px);
    -ms-transform: rotate(-45deg) translate(4px, -6px);
    transform: rotate(-45deg) translate(4px, -6px);
  }
}
.menu-item-has-children > a::after {
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  width: 10px;
  height: 2px;
  background: #fff;
}
.menu-item-has-children > a::before {
  top: 50%;
  transform: translateY(-50%);
  right: 29px;
  width: 2px;
  height: 10px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 992px) {
  .menu-item-has-children > a::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 10px;
    height: 2px;
    background-color: #101115;
  }
  .menu-item-has-children > a::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    width: 2px;
    height: 10px;
    background-color: #101115;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
@media (min-width: 992px) {
  .menu-item-has-children .menu-item-has-children a::after {
    right: 11px;
  }
  .menu-item-has-children .menu-item-has-children a::before {
    right: 15px;
  }
}
@media (min-width: 992px) {
  .menu-item-has-children .menu-item-has-children a:hover::after,
  .menu-item-has-children .menu-item-has-children a:hover::before {
    background: #fff;
  }
}
@media (min-width: 992px) {
  .menu-item-has-children:hover > a:before {
    transform: translateY(-50%) rotate(90deg);
    opacity: 0;
  }
}
.menu-item-has-children.open > a:before {
  transform: translateY(-50%) rotate(90deg);
  opacity: 0;
}
.shop-menu li a::after,
.shop-menu li a::before {
  background: #101115;
  right: 0;
}
.shop-menu li a::before {
  right: 4px;
}
.shop-menu li:hover > a {
  color: #318ce7 !important;
}
.shop-menu li:hover > a::before {
  -webkit-transform: translateY(-50%) rotate(0deg);
  -ms-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
  opacity: 1;
}
.shop-menu li:hover > a:hover::after,
.shop-menu li:hover > a:hover::before {
  background: #318ce7;
}
.shop-menu li.open > a::after {
  background: #318ce7;
}
.shop-menu li.open > a::before {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
  opacity: 0;
}
.shop-menu li ul li a:hover::after,
.shop-menu li ul li a:hover::before {
  background: #101115;
}
.menu-search-form {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(16, 17, 21, 0.7);
  visibility: hidden;
  opacity: 0;
  z-index: 99999;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
}
.menu-search-form.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.menu-search-form form {
  max-width: 600px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.menu-search-form form input {
  width: calc(100% - 50px);
  border: 1px solid #ecf0f3;
  outline: none;
}
.menu-search-form form button {
  width: 50px;
}
.banner-section {
  background-image: url(../images/bg-img/light_blur_blue.png);
  background-size: cover;
  padding-top: 170px;
}
@media (min-width: 576px) {
  .banner-section {
    padding-top: 215px;
  }
}
.banner-section .all-shapes {
  display: none;
  width: 700px;
  height: 700px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 1px dashed #ff70d2;
  border-width: 1.75px;
  top: 25%;
  right: 0%;
  animation: rotate 110s infinite linear;
}
@media (min-width: 1600px) {
  .banner-section .all-shapes {
    right: 15%;
  }
}
.banner-section .all-shapes::after,
.banner-section .all-shapes::before {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.banner-section .all-shapes::before {
  width: calc(100% - 15%);
  height: calc(100% - 15%);
  border: 1px dashed #8b74ff;
  border-width: 1.8px !important;
  animation: rotate-rev 110s infinite linear;
}
.banner-section .all-shapes::after {
  width: calc(100% - 30%);
  height: calc(100% - 30%);
  border: 1px dashed #fdca92;
  border-width: 1.5px !important;
}
@media (min-width: 1200px) {
  .banner-section .all-shapes {
    display: block;
  }
}
@media (min-width: 1600px) {
  .banner-section .cbs-content-list {
    display: block !important;
  }
}
.banner-section .cbs-content-list ul li.ccl-shape {
  z-index: 2;
}
.banner-section .cbs-content-list ul li.ccl-shape a {
  padding: 10px 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.banner-section .cbs-content-list ul li.ccl-shape a::after {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: -35px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner-section .cbs-content-list ul li.shape-1 {
  animation: up-down 6s infinite linear;
  top: 38%;
  right: 5%;
}
.banner-section .cbs-content-list ul li.shape-1 a {
  background-image: linear-gradient(
    to left,
    #00c0a6,
    #00b1c9,
    #009df4,
    #0080ff,
    #1d4dfa
  );
}
.banner-section .cbs-content-list ul li.shape-1 a::after {
  left: auto;
  right: -40px;
}
@media (min-width: 1200px) {
  .banner-section .cbs-content-list ul li.shape-1 {
    top: 28%;
    right: 5%;
  }
}
@media (min-width: 1600px) {
  .banner-section .cbs-content-list ul li.shape-1 {
    top: 45%;
    right: 46%;
  }
}
.banner-section .cbs-content-list ul li.shape-2 {
  animation: up_down_2 6s infinite linear;
  top: 40%;
  right: 19%;
}
.banner-section .cbs-content-list ul li.shape-2 a {
  background-image: linear-gradient(
    to right,
    #ff5dcc,
    #ff4ca2,
    #ff4878,
    #ff514f,
    #318ce7
  );
}
.banner-section .cbs-content-list ul li.shape-2 a::after {
  left: -40px;
  background: #ff5dcc;
}
@media (min-width: 1200px) {
  .banner-section .cbs-content-list ul li.shape-2 {
    top: 31%;
    right: 17%;
  }
}
@media (min-width: 1600px) {
  .banner-section .cbs-content-list ul li.shape-2 {
    top: 30%;
    right: 10%;
  }
}
.banner-section .cbs-content-list ul li.shape-3 {
  animation: up_down_2 8s infinite linear;
  top: 59%;
  right: 38%;
}
.banner-section .cbs-content-list ul li.shape-3 a {
  background-image: linear-gradient(
    to right,
    #52f781,
    #a5e150,
    #d3c83a,
    #f0ae43,
    #fe955c
  );
}
.banner-section .cbs-content-list ul li.shape-3 a::after {
  left: -50px;
  background: #fe955c;
}
@media (min-width: 1200px) {
  .banner-section .cbs-content-list ul li.shape-3 {
    top: 52%;
    right: 36%;
  }
}
@media (min-width: 1600px) {
  .banner-section .cbs-content-list ul li.shape-3 {
    top: 42%;
    right: 9%;
  }
}
.banner-section .cbs-content-list ul li.shape-4 {
  animation: up_down_3 6s infinite linear;
  top: 26%;
  right: 26%;
}
.banner-section .cbs-content-list ul li.shape-4 a {
  background-image: linear-gradient(
    to right,
    #fe955c,
    #ff816d,
    #ff6d86,
    #ff5fa7,
    #ff5dcc
  );
}
.banner-section .cbs-content-list ul li.shape-4 a::after {
  left: -38px;
  background: #ff5dcc;
}
@media (min-width: 1200px) {
  .banner-section .cbs-content-list ul li.shape-4 {
    top: 21%;
    right: 34%;
  }
}
@media (min-width: 1600px) {
  .banner-section .cbs-content-list ul li.shape-4 {
    top: 53%;
    right: 5%;
  }
}
.banner-section .cbs-content-list ul li.shape-5 {
  animation: up_down_3 6s infinite linear;
  top: 50%;
  right: 45%;
}
.banner-section .cbs-content-list ul li.shape-5 a {
  background-image: linear-gradient(
    to right,
    #785eff,
    #009fff,
    #00c8ff,
    #00e4ce,
    #52f781
  );
}
.banner-section .cbs-content-list ul li.shape-5 a::after {
  background: #785eff;
}
@media (min-width: 1200px) {
  .banner-section .cbs-content-list ul li.shape-5 {
    top: 46%;
    right: 46%;
  }
}
@media (min-width: 1600px) {
  .banner-section .cbs-content-list ul li.shape-5 {
    top: 65%;
    right: 8%;
  }
}
.banner-section .section-wrapper .banner-content .subtitle {
  color: #318ce7;
  letter-spacing: 5px;
  font-size: 18px;
}
.banner-section .section-wrapper .banner-content .title {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .banner-section .section-wrapper .banner-content .title {
    font-size: 66px;
    line-height: 1.1;
  }
}
.banner-section .section-wrapper .banner-content .desc {
  margin-bottom: 25px;
}
@media (min-width: 576px) {
  .banner-section .section-wrapper .banner-content .desc {
    font-size: 20px;
    line-height: 1.5;
  }
}
.banner-section .section-wrapper .banner-content form {
  background: #fff;
  justify-content: space-between;
  box-shadow: 0 5px 10px rgba(16, 17, 21, 0.1);
}
.banner-section .section-wrapper .banner-content form .banner-icon {
  left: 15px;
  top: 50%;
  font-weight: 700;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner-section .section-wrapper .banner-content form input {
  width: calc(100% - 132px);
  border: none;
  padding: 18px;
  padding-left: 40px;
}
.banner-section .section-wrapper .banner-content form button {
  color: #fff;
  background: #318ce7;
}
.banner-section .section-wrapper .banner-content .banner-catagory {
  margin-top: 25px;
}
@media (max-width: 991px) {
  .banner-section
    .section-wrapper
    .banner-content
    .banner-catagory
    ul
    li:first-child
    a {
    padding-left: 0;
  }
}
.banner-section .section-wrapper .banner-content .banner-catagory ul li a {
  padding: 0 5px;
  font-weight: 500;
  text-decoration: underline;
}
@media (min-width: 576px) {
  .banner-section .section-wrapper .banner-content .banner-catagory ul li a {
    padding: 0 10px;
  }
}
.banner-section
  .section-wrapper
  .banner-content
  .banner-catagory
  ul
  li
  a:hover {
  color: #318ce7;
}
.banner-section .section-wrapper .banner-thumb {
  z-index: 2;
  margin-top: 30px;
}
@media (min-width: 992px) {
  .banner-section .section-wrapper .banner-thumb {
    margin-top: 0;
  }
}
.banner-section.style-2 {
  background-image: url(../images/bg-img/02.jpg);
  padding-top: 175px;
}
.banner-section.style-2::before {
  width: 50%;
  height: 100%;
  top: 0;
  border-bottom: 875px solid #318ce7;
  border-right: 960px solid transparent;
  z-index: 1;
}
@media (min-width: 1440px) {
  .banner-section.style-2::before {
    display: block;
  }
}
.banner-section.style-2 .section-wrapper .thumb-part .abs-thumb {
  bottom: 0;
  left: -10%;
}
.banner-section.style-2 .section-wrapper .thumb-part .abs-thumb img {
  max-width: none;
}
.banner-section.style-2 .section-wrapper .banner-thumb {
  animation: bounce-1 5s infinite linear;
}
.banner-section.style-3 {
  background-image: url(../images/bg-img/03.jpg);
  background-position: left;
  padding: 170px 0;
  margin-top: 98px;
}
@media (min-width: 992px) {
  .banner-section.style-3 {
    padding: 255px 0;
    background-position: center;
    margin-top: 0;
  }
}
.banner-section.style-3 .banner-content .lab-btn {
  padding: 18px 40px;
}
.banner-section.style-4 {
  background-image: url(../../assets/images/bg-img/06.jpg);
  z-index: 1;
  padding-bottom: 100px;
}
@media (min-width: 992px) {
  .banner-section.style-4 {
    padding-top: 313px;
    padding-bottom: 215px;
  }
}
.banner-section.style-4 .banner-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
.banner-section.style-4 .banner-content h2 {
  font-size: 46px;
  line-height: 50px;
  font-weight: 300;
}
.banner-section.style-4 .banner-content h2 span {
  font-weight: 500;
}
@media (max-width: 575px) {
  .banner-section.style-4 .banner-content h2 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}
.banner-section.style-4 .banner-content form {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
@media (max-width: 575px) {
  .banner-section.style-4 .banner-content form {
    border-radius: 0px;
    margin-bottom: 20px;
  }
}
.banner-section.style-4 .banner-content form select {
  padding: 21px 15px;
  outline: none;
  width: 200px;
  border: none;
}
@media (max-width: 575px) {
  .banner-section.style-4 .banner-content form select {
    width: 100%;
    border-bottom: 1px solid #ecf0f3;
  }
}
.banner-section.style-4 .banner-content form input {
  padding: 22px 50px 22px 15px;
  border: none;
  width: calc(100% - 200px);
}
@media (max-width: 575px) {
  .banner-section.style-4 .banner-content form input {
    width: 100%;
  }
}
.banner-section.style-4 .banner-content form button {
  background: transparent;
  right: 30px;
  top: 21px;
  font-weight: 700;
}
@media (max-width: 575px) {
  .banner-section.style-4 .banner-content form button {
    top: auto;
    bottom: 20px;
  }
}
.banner-section.style-4 .banner-content p {
  font-size: 1.25rem;
  margin-bottom: 100px;
}
@media (max-width: 575px) {
  .banner-section.style-4 .banner-content p {
    margin-bottom: 30px;
  }
}
.banner-section.style-4 .banner-content ul li {
  padding: 10px 15px;
  margin: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.banner-section.style-4 .banner-content ul li i {
  font-size: 22px;
}
.banner-section.style-5 {
  background-image: url(../images/bg-img/08.jpg);
  background-position: center;
  background-attachment: fixed;
  padding: 260px 0 200px;
}
@media (min-width: 992px) {
  .banner-section.style-5 {
    padding: 300px 0;
  }
}
.banner-section.style-5::after {
  right: 0;
  top: 0;
  background: rgba(255, 223, 64, 0.85);
}
@media (min-width: 768px) {
  .banner-section.style-5::after {
    width: 50%;
  }
}
.banner-section.style-5 .section-wrapper .banner-content {
  z-index: 1;
}
@media (min-width: 576px) {
  .banner-section.style-5 .section-wrapper .banner-content {
    padding-left: 30px;
  }
}
.banner-section.style-5 .section-wrapper .banner-content .title span {
  font-weight: 400;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner-section.style-5 .section-wrapper .banner-content .title {
    font-size: 56px;
  }
}
.banner-section.style-5 .section-wrapper .banner-content p {
  font-weight: 500;
}
.banner-section.style-5 .section-wrapper .banner-content .lab-btn {
  background: transparent;
  border: 2px solid #101115;
  padding: 14px 30px;
}
.banner-section.style-5 .section-wrapper .banner-content .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(16, 17, 21, 0.5);
}
.banner-section.style-6 {
  background-image: url(../images/bg-img/09.jpg);
  background-position: center;
  background-attachment: fixed;
  padding: 286px 0 200px;
}
@media (min-width: 992px) {
  .banner-section.style-6 {
    padding: 465px 0 300px;
  }
}
@media (min-width: 1200px) {
  .banner-section.style-6 .section-wrapper .banner-content .title {
    font-size: 72px;
  }
}
.banner-section.style-6 .section-wrapper .banner-content p {
  font-size: 1.25rem;
}
.banner-section.style-7 {
  background-image: url(../images/bg-img/14.jpg);
  background-size: cover;
  background-position: right;
  margin-top: 98px;
}
@media (min-width: 992px) {
  .banner-section.style-7 {
    margin-top: 153px;
  }
}
@media (min-width: 1200px) {
  .banner-section.style-7 {
    background-position: center;
  }
}
.banner-section.style-7 .section-wrapper .banner-top {
  padding-bottom: 170px;
}
@media (min-width: 576px) {
  .banner-section.style-7 .section-wrapper .banner-top {
    padding-bottom: 230px;
  }
}
.banner-section.style-7 .section-wrapper .banner-top .banner-content .lab-btn {
  background: transparent;
  border: 2px solid #101115;
  padding: 14px 30px;
}
.banner-section.style-7
  .section-wrapper
  .banner-top
  .banner-content
  .lab-btn:hover {
  border-color: #ffdf40;
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.banner-section.style-7 .section-wrapper .banner-bottom {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.pageheader-section {
  padding: 250px 0 100px;
  background-image: url(../images/pageheader/bg/blue.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.pageheader-section .pageheader-content h2 {
  margin-bottom: 10px;
}
.pageheader-section.style-2 {
  padding-top: 250px;
  background-image: url(../images/pageheader/bg/02.jpg);
}
@media (max-width: 991px) {
  .pageheader-section.style-2 {
    padding-bottom: 60px;
    padding-top: 150px;
  }
}
.pageheader-section.style-2 .pageheader-thumb {
  margin-bottom: 30px;
  border: 10px solid #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
@media (min-width: 992px) {
  .pageheader-section.style-2 .pageheader-thumb {
    margin-bottom: -60px;
  }
}
.pageheader-section.style-2 .pageheader-content .course-category .course-cate {
  padding: 3px 15px;
  padding-left: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: 500;
  margin-right: 10px;
}
.pageheader-section.style-2
  .pageheader-content
  .course-category
  .course-cate::before {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.5);
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.pageheader-section.style-2 .pageheader-content .course-category .course-offer {
  padding: 3px 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.pageheader-section.style-2 .pageheader-content .phs-thumb img {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.pageheader-section.style-2 .pageheader-content .phs-thumb span {
  font-size: 1.125rem;
  font-weight: 500;
}
.sponsor-section {
  padding: 50px 0;
}
.category-section
  .section-wrapper
  .col
  .category-item
  .category-inner
  .category-content
  span {
  color: #00a556;
}
.category-section
  .section-wrapper
  .col:nth-of-type(6n + 2)
  .category-item
  .category-inner
  .category-content
  span {
  color: #31aabd;
}
.category-section
  .section-wrapper
  .col:nth-of-type(6n + 3)
  .category-item
  .category-inner
  .category-content
  span {
  color: #eec93d;
}
.category-section
  .section-wrapper
  .col:nth-of-type(6n + 4)
  .category-item
  .category-inner
  .category-content
  span {
  color: #cf9fb7;
}
.category-section
  .section-wrapper
  .col:nth-of-type(6n + 5)
  .category-item
  .category-inner
  .category-content
  span {
  color: #fe6225;
}
.category-section
  .section-wrapper
  .col:nth-of-type(6n + 6)
  .category-item
  .category-inner
  .category-content
  span {
  color: #f79a3d;
}
.category-section.style-2 .section-wrapper .col .category-item .category-inner {
  background: url(../../assets/images/category/bg/01.jpg) no-repeat;
  background-size: cover !important;
}
.category-section.style-2
  .section-wrapper
  .col
  .category-item
  .category-inner
  .category-thumb {
  margin-top: 60px;
  margin-bottom: 20px;
}
.category-section.style-2
  .section-wrapper
  .col
  .category-item
  .category-inner
  .category-content
  h6,
.category-section.style-2
  .section-wrapper
  .col
  .category-item
  .category-inner
  .category-content
  span {
  color: #fff !important;
}
.category-section.style-2
  .section-wrapper
  .col:nth-of-type(8n + 2)
  .category-item
  .category-inner {
  background: url(../../assets/images/category/bg/02.jpg) no-repeat;
}
.category-section.style-2
  .section-wrapper
  .col:nth-of-type(8n + 3)
  .category-item
  .category-inner {
  background: url(../../assets/images/category/bg/03.jpg) no-repeat;
}
.category-section.style-2
  .section-wrapper
  .col:nth-of-type(8n + 4)
  .category-item
  .category-inner {
  background: url(../../assets/images/category/bg/04.jpg) no-repeat;
}
.category-section.style-2
  .section-wrapper
  .col:nth-of-type(8n + 5)
  .category-item
  .category-inner {
  background: url(../../assets/images/category/bg/05.jpg) no-repeat;
}
.category-section.style-2
  .section-wrapper
  .col:nth-of-type(8n + 6)
  .category-item
  .category-inner {
  background: url(../../assets/images/category/bg/06.jpg) no-repeat;
}
.category-section.style-2
  .section-wrapper
  .col:nth-of-type(8n + 7)
  .category-item
  .category-inner {
  background: url(../../assets/images/category/bg/07.jpg) no-repeat;
}
.category-section.style-2
  .section-wrapper
  .col:nth-of-type(8n + 8)
  .category-item
  .category-inner {
  background: url(../../assets/images/category/bg/08.jpg) no-repeat;
}
@media (min-width: 992px) {
  .category-section.style-3 .section-header {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .category-section.style-3 .section-header .title {
    line-height: 1.1;
  }
}
.category-section.style-4 .section-wrapper .category-item .category-inner {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner::after {
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to top,
    #051937bd,
    #0519379e,
    #05193759,
    #05193703,
    #05193700
  );
}
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-thumb
  img {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content {
  bottom: 20px;
  left: 20px;
  z-index: 1;
}
.category-section.style-4
  .section-wrapper
  .category-item
  .category-inner
  .category-content
  .cate-icon {
  width: 36px;
  height: 36px;
  line-height: 36px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.category-section.style-4 .section-wrapper .lab-btn {
  background: transparent;
  border: 2px solid #ffdf40;
}
.category-section.style-4 .section-wrapper .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.course-section
  .section-wrapper
  .col:nth-of-type(6n + 2)
  .course-item
  .course-inner
  .course-content
  .course-category
  .course-cate
  a {
  background: #00a556;
}
.course-section
  .section-wrapper
  .col:nth-of-type(6n + 2)
  .course-item
  .course-inner
  .course-content
  .course-category
  .course-cate
  a {
  background: #00a556;
}
.course-section
  .section-wrapper
  .col:nth-of-type(6n + 3)
  .course-item
  .course-inner
  .course-content
  .course-category
  .course-cate
  a {
  background: #eec93d;
}
.course-section
  .section-wrapper
  .col:nth-of-type(6n + 4)
  .course-item
  .course-inner
  .course-content
  .course-category
  .course-cate
  a {
  background: #cf9fb7;
}
.course-section
  .section-wrapper
  .col:nth-of-type(6n + 5)
  .course-item
  .course-inner
  .course-content
  .course-category
  .course-cate
  a {
  background: #31aabd;
}
.course-section
  .section-wrapper
  .col:nth-of-type(6n + 6)
  .course-item
  .course-inner
  .course-content
  .course-category
  .course-cate
  a {
  background: #f79a3d;
}
.course-section.style-2 .section-header .course-navigations {
  right: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .course-section.style-2 .section-header .course-navigations {
    display: none;
  }
}
.course-section.style-3 {
  background: url(../../assets/images/shape-img/01.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
.course-section.style-3 .course-shape {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.course-section.style-3 .course-shape.one {
  left: 0;
  animation: outer-ripple 15s infinite;
}
.course-section.style-3 .course-shape.two {
  right: 0;
  animation: outer-ripple 10s infinite;
}
.course-section.style-3 .section-header {
  padding: 15px 30px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
@media (max-width: 991px) {
  .course-section.style-3 .section-header {
    text-align: center;
  }
  .course-section.style-3 .section-header .title {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .course-section.style-3 .section-header .course-filter-group {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .course-section.style-3 .section-header .course-filter-group ul {
    justify-content: center;
  }
}
.course-section.style-3 .section-header .course-filter-group ul li {
  padding: 2px 15px;
  margin: 2px;
  font-weight: 500;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.course-single-section .course-item .course-inner {
  box-shadow: none;
  background: transparent;
}
.course-single-section .course-item .course-inner:hover h4 {
  color: #101115 !important;
}
.course-single-section .course-item .course-inner .course-content ul li {
  padding: 3px 0;
}
.course-video {
  margin: 30px 0;
}
.course-video .course-video-content .accordion-item {
  background: transparent;
  border: none;
}
@media (max-width: 575px) {
  .course-video .course-video-content .accordion-item .accordion-header button {
    flex-wrap: nowrap !important;
  }
}
.course-video .course-video-content .accordion-item .accordion-header span {
  font-size: 1.125rem;
  font-weight: 700;
  cursor: auto;
}
@media (max-width: 575px) {
  .course-video .course-video-content .accordion-item .accordion-header span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.course-video
  .course-video-content
  .accordion-item
  .accordion-header
  span:last-child {
  font-weight: 500;
}
.course-author {
  margin: 30px 0;
}
.video-section {
  background: url(../../assets/images/bg-img/10.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 1200px) {
  .video-section {
    padding: 250px 0;
  }
}
.video-section .section-wrapper .video-thumb a {
  margin: 30px auto 0;
}
.about-section::before {
  width: 50%;
  height: 100%;
  border-bottom: 753px solid #add8e6;
  border-right: 960px solid transparent;
}
@media (min-width: 1440px) {
  .about-section::before {
    display: block;
  }
}
.about-section .about-left {
  z-index: 1;
}
.about-section .about-right {
  z-index: 1;
}
.about-section .about-right .section-header {
  margin-bottom: 30px;
}
.about-section .about-right .section-header p {
  font-size: 1.125rem;
  margin-bottom: 0;
}
.about-section .about-right .section-wrapper ul li {
  padding: 15px 0;
}
.about-section .about-right .section-wrapper ul li:last-child {
  padding-bottom: 0;
}
.about-section .about-right .section-wrapper ul li .sr-left {
  width: 70px;
  margin-right: 15px;
}
.about-section .about-right .section-wrapper ul li .sr-left img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.about-section .about-right .section-wrapper ul li .sr-right {
  width: calc(100% - 85px);
}
.about-section .about-right .section-wrapper ul li .sr-right h5 {
  font-weight: 600;
}
.about-section .about-right .section-wrapper ul li .sr-right p {
  font-size: 1.125rem;
  margin-bottom: 0;
  max-width: 80%;
}
.about-section.style-2 {
  padding: 50px 0;
}
.about-section.style-2::before {
  display: none;
}
.about-section.style-2 .about-right .section-wrapper ul li {
  padding-bottom: 15px !important;
}
.about-section.style-3::before {
  display: none;
}
.about-section.style-3 .about-left {
  margin-bottom: 60px;
}
.about-section.style-3 .about-left .about-thumb img {
  box-shadow: 0 0 10px rgba(16, 17, 21, 0.1);
  border: 10px solid #fff;
}
.about-section.style-3 .about-left .abs-thumb {
  right: 15px;
  bottom: -60px;
  box-shadow: 0 0 10px rgba(16, 17, 21, 0.1);
}
@media (max-width: 575px) {
  .about-section.style-3 .about-left .abs-thumb {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .about-section.style-3 .about-left .abs-thumb {
    right: 260px;
  }
}
.about-section.style-3 .about-left .abs-thumb img {
  border: 10px solid #fff;
}
.about-section.style-3 .about-left .about-left-content {
  width: 150px;
  left: 0;
  bottom: 0;
  border: 10px solid #fff;
  box-shadow: 0 0 10px rgba(16, 17, 21, 0.1);
}
.about-section.style-3 .about-left .about-left-content::after {
  left: -10px;
  top: calc(100% + 10px);
  border-top: 60px solid #ededed;
  border-left: 60px solid transparent;
}
@media (min-width: 576px) {
  .about-section.style-3 .about-left .about-left-content h3 {
    font-size: 48px;
  }
}
.about-section.style-4 .about-items {
  margin: -15px 0;
}
.about-section.style-4 .about-item {
  width: calc(100% / 1);
  padding: 15px;
}
@media (min-width: 992px) {
  .about-section.style-4 .about-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1200px) {
  .about-section.style-4 .about-item {
    width: calc(100% / 3);
  }
}
.about-section.style-4 .about-item .image {
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.about-section.style-4 .about-item .image img {
  width: 100%;
}
.about-section.style-4 .about-item .about-content h2 span {
  font-weight: 300;
}
.about-section.style-4 .about-item .about-content > span {
  font-size: 22px;
  margin-bottom: 10px;
  display: block;
}
.about-section.style-4 .about-item .about-content .details li {
  padding: 5px 0;
}
.about-section.style-4 .about-item .about-content .details li p {
  margin: 0px;
  font-size: 16px;
  width: 100px;
  font-weight: 600;
  margin-right: 30px;
  justify-content: space-between;
}
.about-section.style-4
  .about-item
  .counter-part
  .count-item:nth-child(3n + 2)
  .count-inner
  .count-icon::before {
  background: rgba(38, 201, 118, 0.1);
}
.about-section.style-4
  .about-item
  .counter-part
  .count-item:nth-child(3n + 2)
  .count-inner
  .count-icon::after {
  background: rgba(38, 201, 118, 0.2);
}
.about-section.style-4
  .about-item
  .counter-part
  .count-item:nth-child(3n + 3)
  .count-inner
  .count-icon::before {
  background: rgba(255, 223, 64, 0.1);
}
.about-section.style-4
  .about-item
  .counter-part
  .count-item:nth-child(3n + 3)
  .count-inner
  .count-icon::after {
  background: rgba(255, 223, 64, 0.2);
}
.about-section.style-4 .about-item .counter-part .count-item .count-inner {
  padding: 18px 0;
}
.about-section.style-4
  .about-item
  .counter-part
  .count-item
  .count-inner
  .count-content
  span {
  font-weight: 400;
}
.about-section.style-4
  .about-item
  .counter-part
  .count-item
  .count-inner
  .count-content
  span.count {
  font-weight: 700;
}
.contact-info-section {
  background: url(../../assets/images/bg-img/11.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 992px) {
  .contact-info-section .section-wrapper {
    max-width: 70%;
    margin: auto;
  }
}
@media (min-width: 1200px) {
  .contact-info-section .section-wrapper {
    max-width: 50%;
  }
}
.contact-info-section .section-wrapper::after {
  width: 340px;
  height: 2px;
  left: 50%;
  top: 41%;
  border-bottom: 2px dashed #ecf0f3;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 576px) {
  .contact-info-section .section-wrapper::after {
    top: 65px;
  }
}
.contact-info-section .section-wrapper .cinfo-or {
  width: 50px;
  height: 50px;
  line-height: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  left: 50%;
  top: 37%;
  font-weight: 700;
  z-index: 1;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 576px) {
  .contact-info-section .section-wrapper .cinfo-or {
    top: 37px;
  }
}
.contact-info-section .section-wrapper .cinfo-or::after,
.contact-info-section .section-wrapper .cinfo-or::before {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.contact-info-section .section-wrapper .cinfo-or::before {
  width: 60px;
  height: 60px;
  background: rgba(255, 223, 64, 0.4);
}
.contact-info-section .section-wrapper .cinfo-or::after {
  width: 70px;
  height: 70px;
  background: rgba(255, 223, 64, 0.2);
}
.contact-info-section .section-wrapper .cinfo-or span {
  z-index: 1;
}
@media (max-width: 575px) {
  .contact-info-section .section-wrapper .cinfo-item {
    width: 100%;
    margin-bottom: 120px;
  }
}
.contact-info-section .section-wrapper .cinfo-item .cinfo-inner .cinfo-thumb {
  width: 120px;
  height: 120px;
  border: 2px solid #ffdf40;
  line-height: 116px;
  margin: 0 auto 30px;
  z-index: 1;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.contact-info-section
  .section-wrapper
  .cinfo-item
  .cinfo-inner
  .cinfo-content
  h6 {
  font-weight: 400;
}
.offer-section {
  background: url(../../assets/images/bg-img/12.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 1440px) {
  .offer-section {
    background-size: 120% 220%;
  }
}
@media (min-width: 992px) {
  .offer-section .section-header {
    max-width: 70%;
  }
}
.offer-section .section-wrapper {
  padding: 50px 30px;
  box-shadow: 0 24px 46px rgba(16, 17, 21, 0.12);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.offer-section .section-wrapper .title {
  font-weight: 500;
}
.offer-section .section-wrapper .date li {
  width: auto;
  height: auto;
  line-height: 1;
  background: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.offer-section .section-wrapper .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.instructor-section .section-wrapper .footer-btn p a {
  font-weight: 500;
}
.instructor-section.style-2::after {
  right: -130px;
  bottom: 0;
  width: 60%;
  transform: skewX(-20deg);
}
@media (max-width: 1199px) {
  .instructor-section.style-2::after {
    display: none;
  }
}
.instructor-section.style-2 .section-wrapper {
  z-index: 1;
}
.instructor-section.style-2 .section-wrapper .col:nth-child(3n + 2) {
  order: 2;
}
@media (min-width: 1200px) {
  .instructor-section.style-2 .section-wrapper .col:nth-child(3n + 2) {
    order: 2;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .instructor-section.style-2 .section-wrapper .col:nth-child(3n + 2) {
    order: 1;
  }
}
.instructor-section.style-2 .section-wrapper .col:nth-child(3n + 3) {
  order: 1;
}
@media (min-width: 1200px) {
  .instructor-section.style-2 .section-wrapper .col:nth-child(3n + 3) {
    order: 1;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .instructor-section.style-2 .section-wrapper .col:nth-child(3n + 3) {
    order: 2;
  }
}
.instructor-section.style-2
  .section-wrapper
  .count-item:nth-child(3n + 2)
  .count-inner
  .count-icon::before {
  background: rgba(38, 201, 118, 0.1);
}
.instructor-section.style-2
  .section-wrapper
  .count-item:nth-child(3n + 2)
  .count-inner
  .count-icon::after {
  background: rgba(38, 201, 118, 0.2);
}
.instructor-section.style-2
  .section-wrapper
  .count-item:nth-child(3n + 3)
  .count-inner
  .count-icon::before {
  background: rgba(255, 223, 64, 0.1);
}
.instructor-section.style-2
  .section-wrapper
  .count-item:nth-child(3n + 3)
  .count-inner
  .count-icon::after {
  background: rgba(255, 223, 64, 0.2);
}
.instructor-section.style-2 .section-wrapper .count-item .count-inner {
  padding: 20px 0;
}
.instructor-section.style-2
  .section-wrapper
  .count-item
  .count-inner
  .count-content
  span {
  font-weight: 400;
}
.instructor-section.style-2
  .section-wrapper
  .count-item
  .count-inner
  .count-content
  span.count {
  font-weight: 700;
}
@media (min-width: 1200px) {
  .instructor-section.style-2 .section-wrapper .instructor-thumb {
    -webkit-transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    transform: translateX(-60px);
  }
}
@media (min-width: 1200px) {
  .instructor-section.style-2 .section-wrapper .instructor-content .subtitle,
  .instructor-section.style-2 .section-wrapper .instructor-content .title,
  .instructor-section.style-2 .section-wrapper .instructor-content p {
    color: #101115;
  }
}
.instructor-section.style-2
  .section-wrapper
  .instructor-content
  .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.5);
}
.instructor-section.style-2 .section-wrapper .instructor-content .lab-btn span {
  color: #101115;
}
.instructor-section.style-3 .section-wrapper .instructor-bottom {
  padding: 0 15px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(16, 17, 21, 0.06);
  margin-top: 24px;
}
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi {
  width: 36px;
  height: 36px;
  line-height: 36px;
  top: 40%;
  z-index: 2;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(16, 17, 21, 0.06);
}
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi.instructor-slider-next {
  right: 0;
}
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-slider
  .instructor-navi.instructor-slider-prev {
  left: 0;
}
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item {
  box-shadow: none;
}
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item:hover {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item:hover
  .instructor-inner
  .instructor-thumb
  img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.instructor-section.style-3
  .section-wrapper
  .instructor-bottom
  .instructor-item
  .instructor-inner
  .instructor-thumb
  img {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.instructor-single-section .instructor-wrapper .instructor-single-top {
  padding: 30px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
@media (min-width: 576px) {
  .instructor-single-section .instructor-wrapper .instructor-single-top {
    padding: 60px 50px;
  }
}
.instructor-single-section .instructor-wrapper .instructor-single-top::before {
  width: 50%;
  height: 100%;
  top: 0;
  left: 0px;
  border-bottom: 690px solid #26c976;
  border-right: 648px solid transparent;
}
@media (min-width: 1200px) {
  .instructor-single-section
    .instructor-wrapper
    .instructor-single-top::before {
    display: block;
  }
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-thumb {
  box-shadow: 0px 2px 4px 3px rgba(83, 61, 82, 0.06);
}
@media (min-width: 576px) {
  .instructor-single-section
    .instructor-wrapper
    .instructor-single-top
    .instructor-single-item
    .instructor-single-thumb {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .instructor-single-section
    .instructor-wrapper
    .instructor-single-top
    .instructor-single-item
    .instructor-single-thumb {
    margin-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .instructor-single-section
    .instructor-wrapper
    .instructor-single-top
    .instructor-single-item
    .instructor-single-thumb {
    width: calc(50% - 25px);
    margin-bottom: 0;
  }
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-thumb
  img {
  z-index: 1;
  border: 8px solid #fff;
}
@media (min-width: 1200px) {
  .instructor-single-section
    .instructor-wrapper
    .instructor-single-top
    .instructor-single-item
    .instructor-single-content {
    width: calc(50% - 15px);
  }
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  .title {
  margin-bottom: 3px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  .subtitle {
  line-height: 38px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  .ratting
  i {
  font-size: 1rem;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  p {
  margin-bottom: 7px;
  line-height: 1.5;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  p.ins-desc {
  margin-bottom: 25px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li {
  line-height: 38px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  span.list-name {
  width: calc(100px);
}
@media (min-width: 576px) {
  .instructor-single-section
    .instructor-wrapper
    .instructor-single-top
    .instructor-single-item
    .instructor-single-content
    ul
    li
    span.list-name {
    width: calc(115px);
  }
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr {
  width: calc(100% - 115px);
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr::after,
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr::before {
  width: 2px;
  height: 2px;
  left: -10px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr::after {
  top: 20px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr::before {
  top: 15px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr
  li {
  margin-right: 20px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr
  li:last-child {
  margin: 0;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr
  li
  a.behance {
  color: #1769ff;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr
  li
  a.vimeo {
  color: #3b5998;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr
  li
  a.twitter {
  color: #55acee;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr
  li
  a.basketball {
  color: #e94c89;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-top
  .instructor-single-item
  .instructor-single-content
  ul
  li
  .list-attr
  li
  a.instagram {
  color: #e4405f;
}
.instructor-single-section .instructor-wrapper .instructor-single-bottom {
  padding: 30px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
@media (min-width: 576px) {
  .instructor-single-section .instructor-wrapper .instructor-single-bottom {
    padding: 60px 50px;
  }
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .subtitle {
  margin-bottom: 25px;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .skill-item {
  margin-bottom: 30px;
  margin-right: 15px;
}
@media (min-width: 992px) {
  .instructor-single-section
    .instructor-wrapper
    .instructor-single-bottom
    .skill-item {
    margin-right: 40px;
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .instructor-single-section
    .instructor-wrapper
    .instructor-single-bottom
    .skill-item:last-child {
    margin-right: 0;
  }
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .skill-item
  .skill-thumb
  .circles {
  position: relative;
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .skill-item
  .skill-thumb
  .circles
  .circle
  canvas {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.instructor-single-section
  .instructor-wrapper
  .instructor-single-bottom
  .skill-item
  .skill-thumb
  .circles
  .circle
  strong {
  text-align: center;
}
.event-section::after {
  top: 0;
  left: 0;
  background: url(../../assets/images/shape-img/08.png) no-repeat;
  background-position: center;
  background-size: 115% 30%;
}
.event-section * {
  z-index: 1;
}
@media (max-width: 991px) {
  .event-section
    .section-wrapper
    .event-left
    .event-item
    .event-inner
    .event-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
.event-section.style-2 .section-wrapper .event-navi-item {
  width: 40px;
  height: 40px;
  line-height: 36px;
  top: 100px;
  z-index: 11;
  cursor: pointer;
  border: 2px solid transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.event-section.style-2 .section-wrapper .event-navi-item:hover,
.event-section.style-2 .section-wrapper .event-navi-item.active {
  border-color: #fff;
}
.event-section.style-2 .section-wrapper .event-navi-item.event-slider-next {
  right: -20px;
}
.event-section.style-2 .section-wrapper .event-navi-item.event-slider-prev {
  left: -20px;
}
.event-section.style-2 .event-sliders {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.workshop-section {
  background-image: url(../images/bg-img/04.jpg);
  background-size: cover;
  background-position: center;
}
.workshop-join .section-wrapper {
  margin-top: -41px;
}
.workshop-join .section-wrapper .workshop-title h5 {
  padding: 10px 30px;
  font-weight: 500;
  border-top-left-radius: 6px;
}
.workshop-join .section-wrapper .workshop-title h5::after {
  right: -30px;
  top: 0;
  border-left: 30px solid #318ce7;
  border-top: 45px solid transparent;
}
@media (max-width: 575px) {
  .workshop-join .section-wrapper .workshop-title h5::after {
    display: none;
  }
}
.workshop-join .workshop-join-form {
  padding: 30px;
  box-shadow: 0 10px 15px rgba(85, 85, 85, 0.3);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
@media (min-width: 992px) {
  .workshop-join .workshop-join-form form .input-area {
    width: calc(100% - 150px);
  }
}
.workshop-join .workshop-join-form form .input-area input {
  width: calc(100% / 1);
}
@media (min-width: 992px) {
  .workshop-join .workshop-join-form form .input-area input {
    width: calc(100% / 3 - 15px);
    margin-bottom: 0;
  }
}
.date li {
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  align-content: center;
}
@media (max-width: 767px) {
  .date li {
    margin: 10px;
  }
}
.newsletters-section {
  background: #f8f8f8;
}
.newsletters-section::after {
  bottom: 0;
  left: 0;
  background: url(../../assets/images/shape-img/06.png) no-repeat;
  background-position: center;
  background-size: cover;
}
.newsletters-section .newsletter-area {
  z-index: 1;
}
.newsletters-section .newsletter-area::after {
  width: 0.5px;
}
@media (max-width: 991px) {
  .newsletters-section .newsletter-area::after {
    display: none;
  }
}
.newsletters-section .newsletter-area .news-mass {
  width: 70px;
  height: 70px;
  line-height: 70px;
  z-index: 1;
  font-size: 1.875rem;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
@media (max-width: 991px) {
  .newsletters-section .newsletter-area .news-mass {
    display: none;
  }
}
.newsletters-section .newsletter-area .news-mass::after,
.newsletters-section .newsletter-area .news-mass::before {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.newsletters-section .newsletter-area .news-mass::before {
  width: 80px;
  height: 80px;
  opacity: 0.3;
}
.newsletters-section .newsletter-area .news-mass::after {
  width: 90px;
  height: 90px;
  opacity: 0.2;
}
.newsletters-section .newsletter-area .news-mass i {
  z-index: 1;
}
@media (min-width: 992px) {
  .newsletters-section .newsletter-area .section-header {
    margin-bottom: 0;
    text-align: right;
    padding-right: 60px;
  }
}
.newsletters-section .newsletter-area .section-header p {
  max-width: 70%;
}
@media (min-width: 992px) {
  .newsletters-section .newsletter-area .section-header p {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .newsletters-section .newsletter-area .section-wrapper {
    max-width: 60%;
    padding-left: 60px;
  }
}
.newsletters-section .newsletter-area .section-wrapper h5 {
  margin-bottom: 30px;
}
.newsletters-section.style-2 {
  background-image: url(../images/bg-img/13.jpg);
  background-size: cover;
  background-position: center;
}
.newsletters-section.style-2 .newsletter-area .section-header {
  margin-bottom: 60px;
}
.newsletters-section.style-2 .newsletter-area .section-header .title {
  margin-top: -14px;
}
.newsletters-section.style-2 .newsletter-area .section-header p {
  margin: 0 auto;
}
.newsletters-section.style-2 .newsletter-area .section-wrapper {
  margin: 0 auto;
}
.newsletter-form input {
  padding: 14px 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.newsletter-form button {
  padding: 15px 30px;
}
.feature-section {
  background: #f8f8f8;
}
.feature-section::after {
  bottom: 0;
  left: 0;
  background: url(../../assets/images/shape-img/07.png) no-repeat;
  background-position: center;
  background-size: cover;
}
.feature-section * {
  z-index: 1;
}
.feature-section.style-2 .section-wrapper .feature-item .feature-inner {
  padding: 15px 0;
  box-shadow: none;
  background: transparent;
  align-items: flex-start;
}
@media (max-width: 575px) {
  .feature-section.style-2 .section-wrapper .feature-item .feature-inner {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-section.style-2 .section-wrapper .feature-item .feature-inner {
    text-align: center;
  }
}
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner:hover
  .feature-icon {
  font-size: 2.5rem;
}
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border: 1px solid #ffdf40;
  font-size: 1.875rem;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 576px) {
  .feature-section.style-2
    .section-wrapper
    .feature-item
    .feature-inner
    .feature-icon {
    margin-bottom: 0;
    width: 80px;
  }
}
@media (max-width: 575px) {
  .feature-section.style-2
    .section-wrapper
    .feature-item
    .feature-inner
    .feature-icon {
    margin: 0 auto 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-section.style-2
    .section-wrapper
    .feature-item
    .feature-inner
    .feature-icon {
    margin: 0 auto 30px;
  }
}
.feature-section.style-2
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-content {
  width: calc(100% - 80px);
  padding: 0 15px;
}
@media (max-width: 575px) {
  .feature-section.style-2
    .section-wrapper
    .feature-item
    .feature-inner
    .feature-content {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-section.style-2
    .section-wrapper
    .feature-item
    .feature-inner
    .feature-content {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .feature-section.style-2
    .section-wrapper
    .feature-item
    .feature-inner
    .feature-content
    a
    h5
    span {
    display: block;
  }
}
.feature-section.style-3 .feature-shape.one {
  top: 0;
  left: 0;
}
.feature-section.style-3 .feature-shape.two {
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.feature-section.style-3
  .section-wrapper
  .feature-item
  .feature-inner
  .feature-thumb {
  margin: 0 auto 20px;
}
.feature-section.style-3 .section-wrapper .feature-register {
  padding: 37px 50px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 24px 46px rgba(85, 85, 85, 0.3);
}
.feature-section.style-3 .section-wrapper .feature-register form input,
.feature-section.style-3 .section-wrapper .feature-register form select {
  padding: 14px 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.clients-section {
  background: url(../../assets/images/bg-img/05.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
.clients-section.style-2 {
  background: transparent;
}
.clients-section.style-2 .section-header .title {
  margin-top: -14px;
}
@media (min-width: 992px) {
  .clients-section.style-2 .section-header p {
    max-width: 550px;
    margin: 0 auto;
  }
}
.clients-section.style-2 .section-wrapper {
  height: 400px;
  background: url(../../assets/images/clients/bg.png) no-repeat;
  background-position: center;
  background-size: 75% 100%;
}
@media (max-width: 575px) {
  .clients-section.style-2 .section-wrapper {
    background-size: 125% 100%;
  }
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:hover
  .client-content,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list.active
  .client-content {
  opacity: 1;
  visibility: visible;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:hover
  .client-thumb
  img,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list.active
  .client-thumb
  img {
  opacity: 1;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:hover
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:hover
  .client-thumb::before,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list.active
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list.active
  .client-thumb::before {
  -webkit-animation: circle-2 1.05s infinite;
  -moz-animation: circle-2 1.05s infinite;
  -ms-animation: circle-2 1.05s infinite;
  -o-animation: circle-2 1.05s infinite;
  animation: circle-2 1.05s infinite;
}
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(1) {
  top: 70px;
  left: 20%;
}
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(2) {
  top: 40px;
  left: 40%;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(2)
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(2)
  .client-thumb::before {
  background: #7256df;
}
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(3) {
  top: 115px;
  left: 60%;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(3)
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(3)
  .client-thumb::before {
  background: #eb59d5;
}
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(4) {
  top: 265px;
  left: 35%;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(4)
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(4)
  .client-thumb::before {
  background: #37d87b;
}
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(5) {
  top: 210px;
  left: 50%;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(5)
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(5)
  .client-thumb::before {
  background: #fe7855;
}
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(6) {
  top: 288px;
  left: 70%;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(6)
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(6)
  .client-thumb::before {
  background: #ffc313;
}
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(7) {
  top: 75px;
  left: 75%;
  left: 70%;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(7)
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list:nth-child(7)
  .client-thumb::before {
  background: #26aeea;
}
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb {
  width: 36px;
  height: 36px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::after,
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::before {
  top: 50%;
  left: 50%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::after {
  width: 25px;
  height: 25px;
  opacity: 0.3;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb::before {
  width: 15px;
  height: 15px;
  opacity: 0.5;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-thumb
  img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
}
.clients-section.style-2
  .section-wrapper
  .clients
  .client-list
  .client-content {
  opacity: 0;
  visibility: hidden;
  width: 140px;
  box-shadow: 0 0 20px rgba(136, 136, 136, 0.2);
  bottom: 50%;
  right: 50%;
  z-index: 3;
  border: 2px solid #ffdf40;
  font-weight: 700;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .clients-section.style-2
    .section-wrapper
    .clients
    .client-list
    .client-content {
    width: 170px;
    bottom: 50px;
    right: -30px;
    transform: translate(0%, 0%);
  }
}
.clients-section.style-3 {
  background: #f5f5f5;
}
.clients-section.style-3:hover
  .section-wrapper
  .clients-slider-nav.clients-slider2-next {
  left: 0px;
}
.clients-section.style-3:hover
  .section-wrapper
  .clients-slider-nav.clients-slider2-prev {
  right: 0px;
}
.clients-section.style-3 .section-header .abs-title {
  font-size: 52px;
  font-weight: 900;
  color: #efefef;
  left: 50%;
  bottom: -85px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 576px) {
  .clients-section.style-3 .section-header .abs-title {
    font-size: 130px;
    bottom: -160px;
  }
}
@media (min-width: 992px) {
  .clients-section.style-3 .section-header .abs-title {
    font-size: 160px;
    bottom: -200px;
  }
}
@media (min-width: 1200px) {
  .clients-section.style-3 .section-header .abs-title {
    font-size: 180px;
    bottom: -200px;
  }
}
.clients-section.style-3 .section-wrapper .client-item .client-inner {
  background: transparent;
  padding-bottom: 0;
}
@media (max-width: 991px) {
  .clients-section.style-3 .section-wrapper .client-item .client-inner {
    padding-top: 0;
  }
}
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-thumb {
  border: none;
  overflow: visible;
}
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-thumb
  img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-thumb
  .quote {
  width: 30px;
  height: 30px;
  line-height: 30px;
  top: 0px;
  left: 0px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-content
  p {
  line-height: 1.5;
  max-width: 750px;
  margin: 0 auto;
}
.clients-section.style-3
  .section-wrapper
  .client-item
  .client-inner
  .client-content
  .client-info
  .client-degi {
  font-size: small;
}
.clients-section.style-3 .section-wrapper .clients-slider-nav {
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.clients-section.style-3
  .section-wrapper
  .clients-slider-nav.clients-slider2-next {
  left: -50px;
}
.clients-section.style-3
  .section-wrapper
  .clients-slider-nav.clients-slider2-prev {
  right: -50px;
}
.register-section {
  background: url(../../assets/images/bg-img/07.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 991px) {
  .register-section .section-header {
    margin-bottom: 20px;
  }
}
.register-section .section-header .title {
  font-weight: 500;
}
.register-section .section-wrapper {
  margin: auto;
  padding: 50px 30px;
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
@media (min-width: 992px) {
  .register-section .section-wrapper {
    max-width: 70%;
    margin-right: 0;
  }
}
.register-section .section-wrapper * {
  z-index: 1;
}
.register-section .section-wrapper::after {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to top,
    #733270,
    #733270a6,
    #73327030,
    #73327000,
    #73327000
  );
}
.register-section .section-wrapper .register-form input {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.register-section .section-wrapper .register-form .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
.app-section .section-header .lab-btn {
  background: transparent;
  border: 2px solid #ffdf40;
}
.app-section .section-header .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 223, 64, 0.5);
}
@media (min-width: 576px) {
  .app-section .section-header p {
    max-width: 550px;
    margin: auto;
  }
}
.app-section .section-wrapper ul li {
  margin: 5px;
}
@media (min-width: 576px) {
  .app-section .section-wrapper ul li {
    margin: 0;
    margin-right: 10px;
  }
  .app-section .section-wrapper ul li:last-child {
    margin-right: 0;
  }
}
.student-feedbak-section::before {
  width: 50%;
  height: 100%;
  top: 0;
  border-bottom: 786px solid #26c976;
  border-right: 960px solid transparent;
}
@media (min-width: 1440px) {
  .student-feedbak-section::before {
    display: block;
  }
}
.student-feedbak-section .section-wrapper .sf-left {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .student-feedbak-section .section-wrapper .sf-left {
    margin-bottom: 0;
  }
}
.student-feedbak-section .section-wrapper .sf-left .sfl-thumb {
  border: 10px solid #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(16, 17, 21, 0.1);
}
.choose-section::after {
  left: 0%;
  top: 60%;
  width: 85%;
  height: 60%;
  background-image: url(../../assets/images/shape-img/10.png);
  background-repeat: no-repeat;
  transform: translateY(-50%);
  background-size: 70%;
  z-index: -1;
}
@media (min-width: 1440px) {
  .choose-section::after {
    left: 10%;
    top: 59%;
    width: 55%;
  }
}
@media (max-width: 767px) {
  .choose-section::after {
    display: none;
  }
}
.choose-section .section-header ul li {
  padding: 5px 0;
}
@media (min-width: 992px) {
  .choose-section .section-wrapper .choose-thumb {
    text-align: right;
  }
}
.achievement-section {
  background: url(../../assets/images/shape-img/02.png) no-repeat;
  background-position: center;
}
.achievement-section .section-header {
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .achievement-section .section-wrapper .counter-part {
    margin-bottom: 50px !important;
  }
}
@media (min-width: 992px) {
  .achievement-section.style-2 .section-header {
    margin-bottom: 40px;
  }
}
@media (max-width: 991px) {
  .achievement-section.style-2 .section-wrapper .counter-part {
    margin-bottom: 0px;
  }
}
.achievement-section.style-2 .section-wrapper .count-item .count-inner {
  padding: 0 30px;
}
.achievement-section.style-3 {
  background: #f9f9f9;
}
@media (min-width: 992px) {
  .achievement-section.style-3 {
    background: url(../../assets/images/shape-img/05.png) no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
}
.achievement-section.style-3 .counter-part {
  margin-bottom: 0;
}
.achievement-section.style-3
  .counter-part
  .col:nth-child(2)
  .count-item
  .count-inner
  .count-icon::before {
  background: rgba(38, 201, 118, 0.1);
}
.achievement-section.style-3
  .counter-part
  .col:nth-child(2)
  .count-item
  .count-inner
  .count-icon::after {
  background: rgba(38, 201, 118, 0.2);
}
.achievement-section.style-3
  .counter-part
  .col:nth-child(3)
  .count-item
  .count-inner
  .count-icon::before {
  opacity: 0.1;
}
.achievement-section.style-3
  .counter-part
  .col:nth-child(3)
  .count-item
  .count-inner
  .count-icon::after {
  opacity: 0.2;
}
.achievement-section.style-3
  .counter-part
  .col:nth-child(4)
  .count-item
  .count-inner
  .count-icon::before {
  opacity: 0.1;
}
.achievement-section.style-3
  .counter-part
  .col:nth-child(4)
  .count-item
  .count-inner
  .count-icon::after {
  opacity: 0.2;
}
.achieve-part
  .col:last-child
  .achieve-item
  .achieve-inner
  .achieve-content
  .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(38, 201, 118, 0.5);
}
.achieve-part .col .achieve-item .achieve-inner .achieve-content .lab-btn {
  background: #f9e8b0;
}
.achieve-part
  .col
  .achieve-item
  .achieve-inner
  .achieve-content
  .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(249, 232, 176, 0.5);
}
.skill-section::before {
  top: 0;
}
@media (min-width: 992px) {
  .skill-section::before {
    width: 50%;
  }
}
.skill-section .container {
  z-index: 1;
}
@media (min-width: 992px) {
  .skill-section .section-header {
    margin-bottom: 0;
  }
}
.skill-section .section-header .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.5);
}
.group-select-section {
  padding: 26px 0;
}
.group-select-section .group-select-left i {
  font-size: 1.25rem;
}
.group-select-section .group-select-left span {
  font-size: 1.125rem;
  font-weight: 500;
}
.shop-page .section-header h4 span {
  font-weight: 400;
}
.shop-page .sidebar-widget .widget-search {
  border: none;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.shop-page .sidebar-widget .widget-search form input {
  border: 1px solid #ecf0f3;
}
.shop-page .shop-title {
  padding: 16px 20px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.shop-page .shop-title .product-view-mode a {
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.shop-page .shop-title .product-view-mode a:last-child {
  margin-left: 5px;
}
.shop-page .shop-product-wrap {
  height: auto !important;
}
.shop-page .shop-product-wrap.grid {
  height: auto;
}
.shop-page .shop-product-wrap.grid .product-list-item {
  display: none;
}
.shop-page .shop-product-wrap.list .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.shop-page .shop-product-wrap.list .product-item {
  display: none;
}
.shop-page .shop-product-wrap .product-item,
.shop-page .shop-product-wrap .product-list-item {
  padding: 10px;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.shop-page
  .shop-product-wrap
  .product-item:hover
  .product-thumb
  .pro-thumb::after,
.shop-page
  .shop-product-wrap
  .product-list-item:hover
  .product-thumb
  .pro-thumb::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.shop-page
  .shop-product-wrap
  .product-item:hover
  .product-thumb
  .product-action-link,
.shop-page
  .shop-product-wrap
  .product-list-item:hover
  .product-thumb
  .product-action-link {
  opacity: 1;
  z-index: 9;
  bottom: 50%;
  transform: translate(-50%, 50%);
}
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb::after,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .pro-thumb::after {
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb img,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb img {
  transform-origin: left;
}
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .product-action-link {
  opacity: 0;
  bottom: -100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.shop-page
  .shop-product-wrap
  .product-item
  .product-thumb
  .product-action-link
  a,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .product-action-link
  a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  margin: 5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.shop-page
  .shop-product-wrap
  .product-item
  .product-thumb
  .product-action-link
  a.view-modal,
.shop-page
  .shop-product-wrap
  .product-list-item
  .product-thumb
  .product-action-link
  a.view-modal {
  cursor: pointer;
}
.shop-page .shop-product-wrap .product-item .product-content,
.shop-page .shop-product-wrap .product-list-item .product-content {
  padding-top: 20px;
  text-align: center;
}
.shop-page .shop-product-wrap .product-item .product-content h5,
.shop-page .shop-product-wrap .product-list-item .product-content h5 {
  margin-bottom: 5px;
}
.shop-page .shop-product-wrap .product-item .product-content h5 a,
.shop-page .shop-product-wrap .product-list-item .product-content h5 a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.shop-page .shop-product-wrap .product-item .product-content p,
.shop-page .shop-product-wrap .product-list-item .product-content p {
  color: #555;
  margin-bottom: 5px;
}
.shop-page .shop-product-wrap .product-item .product-content p i,
.shop-page .shop-product-wrap .product-list-item .product-content p i {
  color: #ecb341;
}
@media (min-width: 768px) {
  .shop-page .shop-product-wrap .product-list-item .product-thumb {
    width: 35%;
  }
}
.shop-page .shop-product-wrap .product-list-item .product-content {
  text-align: left;
  padding-left: 20px;
}
@media (min-width: 768px) {
  .shop-page .shop-product-wrap .product-list-item .product-content {
    width: 65%;
  }
}
.shop-page .modal.show {
  display: block;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
  pointer-events: visible;
  z-index: 99999999;
  -webkit-animation: slideInDown 0.4s forwards;
  animation: slideInDown 0.4s forwards;
  overflow-y: scroll;
  background: #000;
}
.shop-page .modal .modal-dialog .modal-content button.close {
  width: 40px;
  height: 40px;
  top: 0;
  right: 2px;
  background: transparent;
  z-index: 9;
  text-shadow: none;
  opacity: 1;
  outline: none;
}
.shop-page .modal .modal-dialog .modal-content .modal-body {
  background: #f6faf9;
  border-radius: 8px;
}
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .product-content {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .shop-page
    .modal
    .modal-dialog
    .modal-content
    .modal-body
    .product-details-inner
    .product-content {
    margin-top: 0;
  }
}
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .product-content
  p
  i {
  color: #e9ba17;
}
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .cart-button {
  margin-top: 30px;
}
.shop-page
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .product-details-inner
  .cart-button
  .lab-btn {
  border-radius: 2px;
  padding: 9px 24px;
  margin-left: 20px;
  margin-top: 0;
}
.shop-single .sidebar-widget .widget-search {
  border: none;
}
.shop-single .sidebar-widget .widget-search form input {
  border: 1px solid #ecf0f3;
}
.shop-single .product-details {
  padding: 20px;
  margin-bottom: 40px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.shop-single .product-details .product-thumb .pro-single-top {
  margin-bottom: 20px;
}
.shop-single .product-details .product-thumb .pro-single-top .single-thumb img {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.shop-single .product-details .product-thumb .pro-single-thumbs {
  max-width: 80%;
  margin: auto;
}
.shop-single
  .product-details
  .product-thumb
  .pro-single-thumbs
  .single-thumb
  img {
  cursor: pointer;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.shop-single .product-details .product-thumb .pro-single-prev,
.shop-single .product-details .product-thumb .pro-single-next {
  cursor: pointer;
  width: 24px;
  height: 60px;
  line-height: 60px;
  font-size: 15px;
  text-align: center;
  background: #318ce7;
  top: 50%;
  outline: none;
  transform: translateY(-50%);
  z-index: 1;
}
.shop-single .product-details .product-thumb .pro-single-prev {
  right: 0;
}
.shop-single .product-details .product-thumb .pro-single-next {
  left: 0;
}
.shop-single .product-details .post-content {
  padding-top: 30px;
}
@media (min-width: 768px) {
  .shop-single .product-details .post-content {
    padding-top: 0;
  }
}
.shop-single .product-details .post-content h6 {
  font-size: 18px;
  font-weight: 500;
}
.shop-single .product-details .post-content p.rating i {
  color: #e9ba17;
}
.shop-single .product-details .post-content form .select-product {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .shop-single .product-details .post-content form .select-product {
    width: 48%;
  }
}
.shop-single .product-details .post-content form .select-product select {
  height: 40px;
  border: 1px solid #ecf0f3;
  color: #555;
  outline: none;
  -webkit-appearance: none;
  z-index: 1;
  background: transparent;
  padding-left: 20px;
  font-size: 14px;
}
.shop-single .product-details .post-content form .select-product i {
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ecf0f3;
  text-align: center;
  z-index: 0;
}
.shop-single .product-details .post-content form .cart-plus-minus {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .shop-single .product-details .post-content form .cart-plus-minus {
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .shop-single .product-details .post-content form .discount-code {
    width: 60%;
  }
}
.shop-single .product-details .post-content form .discount-code input {
  border-color: #ecf0f3;
}
.shop-single .product-details .post-content form .lab-btn {
  margin-top: 20px;
  box-shadow: none;
  outline: none;
  border: none;
}
.shop-single .review {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
@media (min-width: 992px) {
  .shop-single .review {
    margin-bottom: 0;
  }
}
.shop-single .review .review-nav li {
  list-style: none;
  padding: 13px 33px;
  background: #06264d;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
}
.shop-single .review .review-nav li:last-child {
  margin-right: 0;
}
.shop-single .review .review-content.review-content-show .review-showing {
  display: block;
}
.shop-single .review .review-content.description-show .description {
  display: block;
}
.shop-single .review .review-content .review-showing {
  display: none;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.shop-single .review .review-content .review-showing .content li {
  padding: 25px 30px;
  list-style: none;
  border-bottom: 1px solid #ecf0f3;
}
@media (min-width: 768px) {
  .shop-single .review .review-content .review-showing .content li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}
.shop-single .review .review-content .review-showing .content li:last-child {
  border-bottom: none;
}
.shop-single .review .review-content .review-showing .content li .post-thumb {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .shop-single .review .review-content .review-showing .content li .post-thumb {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .shop-single
    .review
    .review-content
    .review-showing
    .content
    li
    .post-content {
    width: calc(100% - 70px);
    padding-left: 20px;
  }
}
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta {
  margin-bottom: 5px;
}
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .posted-on {
  width: calc(100% - 110px);
}
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .posted-on
  a {
  padding-right: 20px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: none;
  line-height: 28px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .posted-on
  p {
  line-height: 30px;
}
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .rating {
  width: 110px;
}
.shop-single
  .review
  .review-content
  .review-showing
  .content
  li
  .post-content
  .entry-meta
  .rating
  i {
  color: #ecb341;
}
.shop-single .review .review-content .review-showing .client-review {
  padding: 30px;
  border-top: 1px solid #ecf0f3;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  .review-title {
  margin-bottom: 40px;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  .review-title
  h5 {
  position: relative;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  .review-title
  h5::before {
  width: 50px;
  height: 4px;
  bottom: -20px;
  border-radius: 2px;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  .review-title
  h5::after {
  border-bottom: 4px dotted #318ce7;
  width: 20px;
  bottom: -20px;
  left: 55px;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  input {
  border: 1px solid #ecf0f3;
  padding: 10px;
}
@media (max-width: 767px) {
  .shop-single
    .review
    .review-content
    .review-showing
    .client-review
    .review-form
    form
    .rating {
    margin-bottom: 20px;
  }
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  .rating
  .rating-title {
  padding-right: 5px;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  .rating
  ul
  li {
  list-style: none;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  .rating
  ul
  li
  i {
  color: #e9ba17;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  textarea {
  border: 1px solid #ecf0f3;
  padding: 10px;
  outline: none;
}
.shop-single
  .review
  .review-content
  .review-showing
  .client-review
  .review-form
  form
  button {
  color: #fff;
  font-weight: 500;
  padding: 10px 30px;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.shop-single .review .review-content .description {
  padding: 30px;
  display: none;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.shop-single .review .review-content .description .post-item {
  flex-direction: row-reverse;
}
.shop-single .review .review-content .description .post-item .post-thumb {
  text-align: center;
}
@media (min-width: 768px) {
  .shop-single .review .review-content .description .post-item .post-thumb {
    width: calc(100% / 2);
  }
}
.shop-single .review .review-content .description .post-item .post-content {
  padding: 30px 0;
}
@media (min-width: 768px) {
  .shop-single .review .review-content .description .post-item .post-content {
    width: calc(100% / 2);
  }
}
.shop-single
  .review
  .review-content
  .description
  .post-item
  .post-content
  ul
  li {
  padding: 8px 0;
  padding-left: 30px;
}
.shop-single
  .review
  .review-content
  .description
  .post-item
  .post-content
  ul
  li::before {
  content: "";
  top: 8px;
  left: 0;
  color: #318ce7;
  font-family: IcoFont !important;
}
.shop-single .review .review-content .ratting i {
  color: #318ce7;
}
.shop-cart .section-wrapper .cart-top {
  margin-bottom: 40px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.shop-cart .section-wrapper .cart-top table {
  font-weight: 700;
}
@media (max-width: 767px) {
  .shop-cart .section-wrapper .cart-top table .cat-product .p-content {
    display: none;
  }
}
@media (max-width: 767px) {
  .shop-cart .section-wrapper .cart-top table .cat-quantity .cart-plus-minus {
    width: 96px;
  }
  .shop-cart
    .section-wrapper
    .cart-top
    table
    .cat-quantity
    .cart-plus-minus
    .cart-plus-minus-box {
    width: 96px;
  }
  .shop-cart
    .section-wrapper
    .cart-top
    table
    .cat-quantity
    .cart-plus-minus
    .qtybutton {
    width: 30px;
  }
}
@media (max-width: 767px) {
  .shop-cart .section-wrapper .cart-top table .cat-price {
    display: none;
  }
}
.shop-cart .section-wrapper .cart-top table thead tr th {
  color: #fff;
  padding: 18px;
  border: none;
}
@media (max-width: 575px) {
  .shop-cart .section-wrapper .cart-top table thead tr th {
    font-size: 14px;
    padding: 10px;
  }
}
.shop-cart .section-wrapper .cart-top table thead tr th:last-child {
  text-align: right;
}
.shop-cart .section-wrapper .cart-top table tbody tr {
  border-bottom: 1px solid #ecf0f3;
  border-right: none;
  border-left: none;
  border-top: none;
}
.shop-cart .section-wrapper .cart-top table tbody tr:last-child {
  border-bottom: none;
}
.shop-cart .section-wrapper .cart-top table tbody tr td {
  padding: 10px;
  border: none;
}
@media (min-width: 768px) {
  .shop-cart .section-wrapper .cart-top table tbody tr td {
    padding: 20px;
  }
}
.shop-cart .section-wrapper .cart-top table tbody tr td:last-child {
  text-align: right;
}
.shop-cart .section-wrapper .cart-top table tbody tr td.product-item {
  align-items: center;
}
.shop-cart .section-wrapper .cart-top table tbody tr td.product-item .p-thumb {
  width: 50px;
  margin: auto;
}
@media (min-width: 768px) {
  .shop-cart
    .section-wrapper
    .cart-top
    table
    tbody
    tr
    td.product-item
    .p-thumb {
    width: 85px;
  }
}
.shop-cart
  .section-wrapper
  .cart-top
  table
  tbody
  tr
  td.product-item
  .p-content {
  width: calc(100% - 85px);
  padding-left: 10px;
}
@media (max-width: 767px) {
  .shop-cart
    .section-wrapper
    .cart-top
    table
    tbody
    tr
    td.product-item
    .p-content {
    display: none;
  }
}
.shop-cart
  .section-wrapper
  .cart-top
  table
  tbody
  tr
  td.product-item
  .p-content
  a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 767px) {
  .shop-cart .section-wrapper .cart-top table tbody tr td.cat-toprice,
  .shop-cart .section-wrapper .cart-top table tbody tr td.cat-edit {
    text-align: center;
    font-size: 14px;
  }
}
.shop-cart .section-wrapper .cart-bottom {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box {
  padding: 20px 30px;
  margin-top: 30px;
}
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon {
  max-width: 340px;
  display: flex;
}
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon input {
  padding: 10px;
  border: 1px solid #ecf0f3;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .cart-checkout-box
  .coupon
  input[type="submit"] {
  color: #fff;
  font-weight: 500;
  margin-left: -10px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout {
    max-width: 390px;
    text-align: right;
    margin-top: 0;
  }
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .cart-checkout-box
  .cart-checkout
  input {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .cart-checkout-box
  .cart-checkout
  input[type="submit"] {
  padding: 10px 30px;
  border: 1px solid #ecf0f3;
  width: auto;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  .shop-cart
    .section-wrapper
    .cart-bottom
    .cart-checkout-box
    .cart-checkout
    input[type="submit"] {
    width: 100%;
    margin-bottom: 15px;
  }
  .shop-cart
    .section-wrapper
    .cart-bottom
    .cart-checkout-box
    .cart-checkout
    input[type="submit"]:last-child {
    margin-bottom: 0;
  }
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .cart-checkout-box
  .cart-checkout
  input[type="submit"]:hover {
  border-color: #318ce7;
}
.shop-cart .section-wrapper .cart-bottom .shiping-box {
  padding: 30px 20px;
  border-top: 1px solid #fff;
}
@media (max-width: 767px) {
  .shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping {
    margin-bottom: 30px;
  }
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .outline-select {
  margin-bottom: 20px;
  position: relative;
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .outline-select
  select {
  padding: 9px 20px;
  background: transparent;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ecf0f3;
  position: relative;
  outline: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
@media (min-width: 992px) {
  .shop-cart
    .section-wrapper
    .cart-bottom
    .shiping-box
    .calculate-shiping
    .outline-select.shipping-select {
    width: 50%;
    float: left;
  }
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .outline-select
  .select-icon {
  top: 0;
  right: 0;
  padding: 9px 20px;
  color: #fff;
  border: 1px solid #ecf0f3;
  border-radius: 0px 3px 3px 0px;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  .cart-page-input-text {
  float: right;
  padding: 10px 20px;
  font-size: 14px;
  margin-bottom: 22px;
  border: 1px solid #ecf0f3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
@media (min-width: 992px) {
  .shop-cart
    .section-wrapper
    .cart-bottom
    .shiping-box
    .calculate-shiping
    .cart-page-input-text {
    width: 45%;
  }
}
.shop-cart
  .section-wrapper
  .cart-bottom
  .shiping-box
  .calculate-shiping
  button {
  width: 170px;
  font-size: 14px;
  color: #fff;
  padding: 10px;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li {
  list-style: none;
  border: 1px solid #ecf0f3;
  padding: 8px 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
@media (max-width: 991px) {
  .four-zero-section .four-zero-content {
    margin-bottom: 30px;
  }
}
.four-zero-section .four-zero-content .title {
  font-size: 42px;
}
@media (min-width: 1200px) {
  .four-zero-section .four-zero-content .title {
    font-size: 100px;
    line-height: 1;
  }
}
@media (min-width: 576px) {
  .four-zero-section .four-zero-content p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
@media (min-width: 992px) {
  .map-address-section .section-header {
    max-width: 60%;
    margin: 0 auto 40px;
  }
}
@media (min-width: 992px) {
  .map-area {
    margin-bottom: 0;
  }
}
.map-area .maps {
  height: 398px;
  border: 10px solid #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media (min-width: 1200px) {
  .contact-section .section-header,
  .contact-section .contact-form {
    max-width: 60%;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .contact-section .section-header {
    margin: 0 auto 40px;
  }
}
.contact-form {
  justify-content: space-between;
  margin-bottom: -30px;
}
.contact-form .form-group {
  margin-bottom: 30px;
  width: 100%;
}
@media (min-width: 576px) {
  .contact-form .form-group {
    width: calc(50% - 15px);
  }
}
.contact-form .form-group input {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  height: 57px;
  padding: 0 25px;
}
.contact-form .form-group textarea {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  padding: 25px;
  color: #391965;
  display: flex;
}
.contact-form .form-group button {
  height: 55px;
}
@media (max-width: 575px) {
  .contact-form {
    margin-bottom: -20px;
  }
  .contact-form .form-group {
    margin-bottom: 20px;
  }
}
.course-select-area .csa-title .csa-right span {
  display: inline-block;
}
@media (min-width: 576px) {
  .course-select-area .csa-title .csa-right span.total-lecture {
    margin-right: 20px;
  }
}
.course-select-area .csa-body ul li .card .card-header h5 button {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #101115;
  text-align: left;
}
.course-select-area .csa-body ul li .card .card-header h5 button span {
  font-size: small;
}
.course-select-area .csa-body ul li .card .card-header h5 button .icon {
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.course-select-area .csa-body ul li .card .card-header h5 button .icon i {
  font-size: 20px;
  font-weight: 700;
  color: #101115;
}
.course-select-area .csa-body ul li .card .card-body .course-lists {
  padding: 10px 1.25rem;
  margin: 0 -1.25rem;
  border-top: 1px solid #ecf0f3;
}
.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left {
  padding-left: 20px;
}
.course-select-area
  .csa-body
  ul
  li
  .card
  .card-body
  .course-lists
  .csa-left
  > i {
  width: 16px;
  height: 16px;
  top: 5px;
  left: 0;
  font-size: 16px;
}
.course-select-area
  .csa-body
  ul
  li
  .card
  .card-body
  .course-lists
  .csa-left
  > i.complite {
  color: #318ce7;
}
.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left h6,
.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left p {
  margin-bottom: 0;
}
.course-select-area
  .csa-body
  ul
  li
  .card
  .card-body
  .course-lists
  .csa-left
  h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left p {
  font-size: 15px;
  line-height: 26px;
  color: rgba(60, 59, 55, 0.62);
}
.course-select-area
  .csa-body
  ul
  li
  .card
  .card-body
  .course-lists
  .csa-left
  p
  i {
  margin-right: 5px;
}
.course-select-area
  .csa-body
  ul
  li
  .card
  .card-body
  .course-lists
  .csa-right
  span.run-watch {
  margin-right: 20px;
}
.course-view-section .course-view .video-part .vp-title h3 {
  color: #000;
}
.course-view-section .course-view .video-part .vp-video video {
  width: 100%;
  border: none;
  outline: none;
}
.course-view-section .course-view .video-part .vp-content h4,
.course-view-section .course-view .video-part .vp-content p {
  color: #000;
}
@media (max-width: 991px) {
  .course-view-section .course-view .video-part .vp-author {
    margin-bottom: 30px;
  }
}
.course-view-section
  .course-view
  .video-part
  .vp-author
  .vpa-item
  .vpa-inner
  .vpa-thumb {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .course-view-section
    .course-view
    .video-part
    .vp-author
    .vpa-item
    .vpa-inner
    .vpa-thumb {
    width: 80px;
    height: 80px;
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .course-view-section
    .course-view
    .video-part
    .vp-author
    .vpa-item
    .vpa-inner
    .vpa-contnet {
    width: calc(100% - 100px);
  }
}
.course-view-section
  .course-view
  .video-part
  .vp-author
  .vpa-item
  .vpa-inner
  .vpa-contnet
  h6,
.course-view-section
  .course-view
  .video-part
  .vp-author
  .vpa-item
  .vpa-inner
  .vpa-contnet
  p {
  color: #000;
}
.course-view-section
  .course-view
  .video-part
  .vp-author
  .vpa-item
  .vpa-inner
  .vpa-contnet
  h6 {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .course-view-section
    .course-view
    .video-part
    .vp-author
    .vpa-item
    .vpa-inner
    .vpa-contnet
    h6 {
    margin-bottom: 0;
  }
}
.course-view-section
  .course-view
  .video-part
  .vp-author
  .vpa-item
  .vpa-inner
  .vpa-contnet
  .social-media
  a {
  margin: 2px;
  font-size: 20px;
  color: #000;
}
@media (max-width: 991px) {
  .course-view-section .course-view .video-part .content-wrapper {
    height: 30px;
    overflow: hidden;
  }
}
.course-view-section .course-view .video-part .content-wrapper .content-icon {
  top: -5px;
  right: 5px;
  color: #000;
  font-size: 28px;
}
.course-view-section .course-view .video-part .content-wrapper.open {
  height: auto;
}
.course-view-section
  .course-view
  .video-list-area
  .video-body
  .course-select-area {
  padding: 30px 15px;
}
.course-view-section
  .course-view
  .video-list-area
  .video-body
  .course-select-area
  .csa-title
  h5 {
  color: #000;
}
.course-view-section
  .course-view
  .video-list-area
  .video-body
  .course-select-area
  .csa-body {
  height: 100vh;
  overflow-y: auto;
}
.course-view-section
  .course-view
  .video-list-area
  .video-body
  .course-select-area
  .csa-body::-webkit-scrollbar {
  width: 5px;
  background: #29303b;
}
.course-view-section
  .course-view
  .video-list-area
  .video-body
  .course-select-area
  .csa-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.course-view-section
  .course-view
  .video-list-area
  .video-body
  .course-select-area
  .csa-body::-webkit-scrollbar-thumb {
  background: #318ce7;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.course-view-section
  .course-view
  .video-list-area
  .video-body
  .course-select-area
  .csa-body
  ul {
  margin-right: 5px;
}
.overview-announce-section {
  background: #1c222d;
  padding-top: 50px;
}
.overview-announce-section .course-view-bottom ul {
  border: none !important;
  margin-bottom: 30px;
}
.overview-announce-section .course-view-bottom ul li a {
  border: none;
  color: #fff;
  font-size: 24px;
  padding: 0;
  padding-right: 20px;
  color: rgba(183, 198, 210, 0.62);
}
.overview-announce-section .course-view-bottom ul li a::after {
  top: -50px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #318ce7;
  transform-origin: left;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}
.overview-announce-section .course-view-bottom ul li a.active,
.overview-announce-section .course-view-bottom ul li a:hover {
  color: #fff;
  background: transparent;
}
.overview-announce-section .course-view-bottom ul li a.active::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-head
  h6 {
  color: #fff;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-head
  p {
  color: rgba(183, 198, 210, 0.62);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li {
  padding: 15px 0;
  border-top: 1px solid #29303b;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-left {
  width: 100%;
}
@media (min-width: 576px) {
  .overview-announce-section
    .course-view-bottom
    .tab-content
    .overview-area
    .overview-body
    ul
    li
    .overview-left {
    width: 200px;
  }
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-left
  p {
  color: rgba(183, 198, 210, 0.62);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li.fullview
  .overview-right.overview-description {
  height: auto;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li.fullview
  .view-details {
  background: none;
  padding-top: 0px;
  bottom: -5px;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li.fullview
  .view-details
  span.more {
  display: none;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li.fullview
  .view-details
  span.less {
  display: block;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right {
  width: 100%;
}
@media (min-width: 576px) {
  .overview-announce-section
    .course-view-bottom
    .tab-content
    .overview-area
    .overview-body
    ul
    li
    .overview-right {
    width: calc(100% - 200px);
  }
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right.overview-description {
  height: 70px;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  .or-items {
  padding: 5px 0;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  .or-items
  .or-left,
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  .or-items
  .or-right {
  color: rgba(183, 198, 210, 0.62);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  .or-items
  .or-left {
  width: 100px;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  .or-items
  .or-left:after {
  content: ":";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  p {
  color: rgba(183, 198, 210, 0.62);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  h6 {
  color: rgba(183, 198, 210, 0.62);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  ul {
  padding-left: 20px;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .overview-right
  ul
  li {
  color: rgba(183, 198, 210, 0.62);
  padding: 5px 0;
  list-style: disc;
  border: none;
  overflow: visible;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .view-details {
  bottom: 10px;
  left: 0;
  background: linear-gradient(
    rgba(28, 34, 45, 0.17),
    rgba(28, 34, 45, 0.9),
    #1c222d
  );
  width: 100%;
  padding-top: 20px;
}
@media (min-width: 576px) {
  .overview-announce-section
    .course-view-bottom
    .tab-content
    .overview-area
    .overview-body
    ul
    li
    .view-details {
    left: 190px;
  }
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .view-details
  span {
  color: #fff;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .overview-area
  .overview-body
  ul
  li
  .view-details
  span.less {
  display: none;
}
@media (min-width: 992px) {
  .overview-announce-section
    .course-view-bottom
    .tab-content
    .announcement-area
    .announcement-lists {
    width: 75%;
  }
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  ul
  li {
  padding: 15px 0;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top {
  width: 100%;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items {
  justify-content: space-between;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-thumb {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-content {
  width: calc(100% - 75px);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-content
  h5 {
  color: #b7c6d2;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-content
  p {
  color: rgba(183, 198, 210, 0.62);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-content
  p
  span {
  margin-left: 15px;
  padding-left: 15px;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-top
  .ap-items
  .ap-content
  p
  span::after {
  left: 0;
  top: 50%;
  width: 4px;
  height: 4px;
  background: rgba(60, 59, 55, 0.62);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-bottom
  h4 {
  color: #b7c6d2;
  margin-bottom: 10px;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .administer-post
  .ap-bottom
  p {
  color: rgba(183, 198, 210, 0.62);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe {
  justify-content: space-between;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-thumb {
  width: 52px;
  height: 52px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-content {
  width: calc(100% - 70px);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-content
  form
  textarea {
  background: #29303b;
  border-color: transparent;
  color: rgba(183, 198, 210, 0.62);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-content
  h6 {
  color: #b7c6d2;
  margin-bottom: 0px;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-content
  h6
  span {
  margin-left: 15px;
  padding-left: 15px;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-content
  h6
  span::after {
  left: 0;
  top: 50%;
  width: 4px;
  height: 4px;
  background: rgba(60, 59, 55, 0.62);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.overview-announce-section
  .course-view-bottom
  .tab-content
  .announcement-area
  .announcement-lists
  .student-reviwe
  .student-content
  p {
  color: rgba(183, 198, 210, 0.62);
  margin-bottom: 0;
}
.authors {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
  justify-content: space-between;
  align-items: center;
  padding: 30px 25px;
  margin: 30px 0;
}
@media (max-width: 575px) {
  .authors {
    justify-content: center;
  }
}
.authors .author-thumb {
  width: 150px;
  height: 150px;
}
.authors .author-thumb img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.authors .author-content {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}
@media (min-width: 576px) {
  .authors .author-content {
    width: calc(100% - 180px);
    margin-top: 0;
    text-align: left;
  }
}
.authors .author-content h5 {
  margin-bottom: 2px;
}
.authors .author-content span {
  font-size: 1.125rem;
}
.authors .author-content p {
  font-size: 17px;
}
.authors .author-content .scocial-media {
  margin-bottom: 0;
}
.authors .author-content .scocial-media a {
  font-size: 1rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: transparent;
}
.authors .author-content .scocial-media a.facebook {
  color: #3b5998;
}
.authors .author-content .scocial-media a.twitter {
  color: #55acee;
}
.authors .author-content .scocial-media a.linkedin {
  color: #007bb5;
}
.authors .author-content .scocial-media a.vimeo {
  color: #3b5998;
}
@media (max-width: 575px) {
  .authors .author-content .social-icons {
    justify-content: center;
  }
}
.course-side-detail .csd-title {
  padding: 15px;
}
.course-side-detail .csd-title .csdt-left sup {
  top: -5px;
}
.course-side-detail .csd-content .sidebar-payment {
  padding: 15px 20px 5px;
}
.course-side-detail .csd-content .sidebar-social {
  padding: 15px 20px 5px;
}
.course-side-detail .csd-content .sidebar-social .ss-content ul li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 3px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.course-side-detail .csd-content .course-enroll {
  padding: 15px 20px;
}
.course-side-detail .csd-content .course-enroll .lab-btn:hover {
  box-shadow: 0 5px 10px rgba(38, 201, 118, 0.3);
}
.csdc-lists ul li {
  font-size: 1.125rem;
  padding: 15px 20px;
}
.csdc-lists ul li:nth-child(even) {
  background: #f9f9f9;
}
.course-side-cetagory .csc-title {
  padding: 19px 30px;
}
.course-side-cetagory .csc-title h5 {
  font-weight: 600;
}
.course-side-cetagory .csc-content .csdc-lists ul li .csdc-left a,
.course-side-cetagory .csc-content .csdc-lists ul li .csdc-right {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.title-border {
  font-size: 1.25rem;
  margin: 0 0 28px 0;
  padding: 20px 30px;
  border-bottom: 1px solid #ecf0f3;
}
@media (min-width: 992px) {
  .title-border {
    padding: 20px 30px;
  }
}
.navigations-part {
  padding: 25px;
  margin: 30px 0;
}
.navigations-part .left {
  margin: 0;
}
@media (min-width: 768px) {
  .navigations-part .left {
    width: calc(100% / 2);
  }
}
@media (max-width: 575px) {
  .navigations-part .right {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .navigations-part .right {
    width: calc(100% / 2);
  }
}
@media (min-width: 768px) {
  .navigations-part .left .title,
  .navigations-part .right .title {
    max-width: 90%;
  }
}
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev {
  font-weight: 700;
}
.comments {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
@media (min-width: 992px) {
  .comments .comment-list {
    padding: 20px 30px;
  }
}
@media (max-width: 575px) {
  .comments .comment-list {
    margin: 0;
  }
}
.comments .comment-list .comment .com-thumb {
  height: 50px;
  width: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
@media (min-width: 576px) {
  .comments .comment-list .comment .com-thumb {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .comments .comment-list .comment .com-thumb {
    height: 80px;
    width: 80px;
  }
}
.comments .comment-list .comment .com-content {
  border-bottom: 1px solid #ecf0f3;
  width: calc(100% - 100px);
}
@media (max-width: 575px) {
  .comments .comment-list .comment .com-content {
    width: 100%;
  }
}
.comments .comment-list .comment .com-content:last-child {
  border-bottom: none;
}
@media (min-width: 576px) {
  .comments .comment-list .comment .com-content .com-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
.comments .comment-list .comment .com-content .com-title .com-title-meta h6 a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  .comments .comment-list .comment .com-content .com-title .reply {
    margin-bottom: 10px;
    display: inline-block;
  }
}
.comments
  .comment-list
  .comment
  .com-content
  .com-title
  .reply
  .comment-reply-link {
  font-weight: 600;
  font-size: 14px;
}
@media (min-width: 576px) {
  .comments .comment-list .comment .comment-list {
    padding-left: 30px;
  }
}
@media (max-width: 575px) {
  .comments .comment-list .comment .comment-list {
    padding: 0px;
  }
}
.comments .comment-list .comment .comment-list .comment {
  border-bottom: 1px solid #ecf0f3;
  padding: 20px 0px;
}
.comments .comment-list .comment .comment-list .comment:last-child {
  border: none;
}
.comment-respond {
  margin: 30px 0;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.comment-respond .add-comment {
  padding: 10px 30px 40px;
}
.comment-respond .add-comment .comment-form input[type="text"] {
  padding: 10px 15px;
}
@media (min-width: 576px) {
  .comment-respond .add-comment .comment-form input[type="text"] {
    width: calc(100% / 2 - 15px);
  }
}
@media (min-width: 768px) {
  .comment-respond .add-comment .comment-form input[type="text"]:nth-child(3) {
    width: 100% !important;
  }
}
.comment-respond
  .add-comment
  .comment-form
  p.comment-form-cookies-consent
  input {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
}
.blog-single .section-wrapper .post-item .post-inner .post-content {
  padding: 50px 30px;
}
.blog-single .section-wrapper .post-item .post-inner .post-content iframe {
  height: 370px;
}
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote {
  margin: 0 0 20px;
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  blockquote::before {
  width: 40px;
  height: 40px;
  content: "";
  font-size: 1.875rem;
  font-family: IcoFont !important;
}
@media (max-width: 767px) {
  .blog-single
    .section-wrapper
    .post-item
    .post-inner
    .post-content
    blockquote::before {
    display: none;
  }
}
@media (min-width: 768px) {
  .blog-single
    .section-wrapper
    .post-item
    .post-inner
    .post-content
    blockquote
    p {
    padding-left: 60px;
    font-size: 18px;
    line-height: 30px;
  }
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section {
  margin: 40px -30px -25px;
  padding: 23px 30px 0;
  border-top: 1px solid #ecf0f3;
}
@media (max-width: 767px) {
  .blog-single
    .section-wrapper
    .post-item
    .post-inner
    .post-content
    .tags-section
    .tags {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .blog-single
    .section-wrapper
    .post-item
    .post-inner
    .post-content
    .tags-section
    .tags {
    width: calc(100% / 2);
  }
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li {
  margin: 5px;
}
@media (min-width: 992px) {
  .blog-single
    .section-wrapper
    .post-item
    .post-inner
    .post-content
    .tags-section
    .tags
    li:first-child {
    margin-left: 0;
  }
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li
  a {
  padding: 5px 18px;
  border: 1px solid #ecf0f3;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li
  a:hover,
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .tags
  li
  a
  .active {
  border-color: #318ce7;
}
.blog-single
  .section-wrapper
  .post-item
  .post-inner
  .post-content
  .tags-section
  .scocial-media {
  width: calc(100% / 1);
}
@media (min-width: 576px) {
  .blog-single
    .section-wrapper
    .post-item
    .post-inner
    .post-content
    .tags-section
    .scocial-media {
    text-align: right;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .blog-single
    .section-wrapper
    .post-item
    .post-inner
    .post-content
    .tags-section
    .scocial-media {
    width: calc(100% / 2);
  }
}
.widget {
  padding: 25px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.widget .widget-header {
  padding: 25px 25px 0;
  border-bottom: 1px solid #ecf0f3;
}
.widget .widget-header .title {
  font-size: 1.25rem;
}
.widget .widget-wrapper {
  padding: 15px 0;
}
.widget .widget-wrapper li {
  list-style: none;
  border-bottom: 1px solid #ecf0f3;
  padding: 15px 25px;
}
.widget .widget-wrapper li:last-child {
  border-bottom: none;
}
@media (max-width: 991px) {
  .widget.widget-search {
    margin-top: 30px;
  }
}
.widget.widget-search .search-wrapper input {
  height: 50px;
  padding-left: 10px;
  line-height: 50px;
  margin-bottom: 0;
  border: none;
  width: 100%;
  outline: none;
}
.widget.widget-search .search-wrapper button {
  top: 0;
  right: 0;
  line-height: 50px;
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  font-size: 1.25rem;
}
.widget.widget-archive,
.widget.widget-category {
  padding: 0;
}
.widget.widget-archive .widget-wrapper li a,
.widget.widget-category .widget-wrapper li a {
  padding: 15px 25px;
}
.widget.widget-post ul.widget-wrapper li .post-thumb {
  width: 90px;
  height: 80px;
}
.widget.widget-post ul.widget-wrapper li .post-thumb a {
  display: inline-flex;
}
.widget.widget-post ul.widget-wrapper li .post-content {
  width: calc(100% - 110px);
}
.widget.widget-post ul.widget-wrapper li .post-content a h6 {
  line-height: 1.2;
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.widget.widget-instagram ul.widget-wrapper {
  padding: 25px 20px;
}
.widget.widget-instagram ul.widget-wrapper li {
  width: calc(100% / 3);
  border: none;
}
.widget.widget-instagram ul.widget-wrapper li a {
  margin: 5px;
}
.widget.widget-tags ul.widget-wrapper {
  padding: 20px;
}
.widget.widget-tags ul.widget-wrapper li {
  border: none;
}
.widget.widget-tags ul.widget-wrapper li a {
  padding: 5px 10px;
  border: 1px solid #ecf0f3;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 5px;
  font-size: 14px;
}
.widget.widget-tags ul.widget-wrapper li a.active,
.widget.widget-tags ul.widget-wrapper li a:hover {
  border-color: #fff;
}
.widget.recipe-categori .widget-wrapper {
  padding: 25px 15px;
}
.widget.recipe-categori .widget-wrapper .recipe-item {
  border: 1px dotted #ecf0f3;
  border-width: 1px;
  border-top: none;
  border-right: none;
  margin: 5px;
}
.widget.shop-widget {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
  margin-top: 35px;
}
.widget.shop-widget .widget-wrapper .shop-menu li {
  list-style: none;
  border-bottom: 1px solid #ecf0f3;
  padding: 12px 15px;
}
.widget.shop-widget .widget-wrapper .shop-menu li:last-child {
  border-bottom: none;
}
.widget.shop-widget .widget-wrapper .shop-menu li a {
  font-weight: 500;
}
.widget.shop-widget .widget-wrapper .shop-menu li a.show::before {
  display: none;
}
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu {
  margin: 0;
  display: none;
  transform: translate(0px, 0px) !important;
  position: static !important;
}
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu.show {
  display: block;
  padding: 10px;
  border: none;
}
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li {
  padding: 2px 0;
  border-bottom: none;
}
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a {
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.widget.letest-product {
  margin-top: 35px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
}
.widget.letest-product .widget-wrapper ul li {
  padding: 15px;
  list-style: none;
  border-bottom: 1px solid #ecf0f3;
}
.widget.letest-product .widget-wrapper ul li:last-child {
  border-bottom: none;
}
.widget.letest-product .widget-wrapper ul li:hover .product-thumb img {
  transform: scale(1.1);
}
.widget.letest-product .widget-wrapper ul li .product-thumb {
  width: 70px;
  height: 70px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .widget.letest-product .widget-wrapper ul li .product-thumb {
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
  }
}
.widget.letest-product .widget-wrapper ul li .product-thumb img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.widget.letest-product .widget-wrapper ul li .product-content {
  width: calc(100% - 70px);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .widget.letest-product .widget-wrapper ul li .product-content {
    width: 100%;
    margin-bottom: 15px;
  }
}
.widget.letest-product .widget-wrapper ul li .product-content h6 a {
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.widget.letest-product .widget-wrapper ul li .product-content p i {
  font-size: 12px;
  color: #e9ba17;
}
.widget.letest-product .widget-wrapper ul li .product-content p span {
  font-size: 14px;
}
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title {
  margin-top: -8px;
}
@media (min-width: 576px) {
  footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title {
    margin-bottom: 15px;
  }
}
footer.style-2
  .footer-top
  .footer-item
  .footer-inner
  .footer-content
  .content
  ul
  li {
  padding: 3px 0;
}
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  p {
  font-size: 1rem;
}
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul.office-address
  li {
  padding: 2px 0;
  font-weight: 500;
}
footer.style-2
  .footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul.social-icons
  li
  a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
footer.style-2
  .footer-top
  .footer-item.twitter-post
  .footer-inner
  .footer-content
  .content
  ul
  li
  i {
  font-size: 1.5rem;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
}
footer.style-2
  .footer-top
  .footer-item.twitter-post
  .footer-inner
  .footer-content
  .content
  ul
  li
  p {
  width: calc(100% - 35px);
  font-size: 1rem;
}
footer.style-2 .footer-top.dark-view * {
  color: #fff !important;
}
.footer-top .footer-item {
  margin: 15px 0;
}
@media (min-width: 992px) {
  .footer-top .footer-item {
    margin: 0;
  }
}
.footer-top .footer-item .footer-inner .footer-content .title {
  margin-top: -8px;
}
.footer-top .footer-item .footer-inner .footer-content .title h4::after {
  width: 50px;
  height: 2px;
  bottom: -15px;
  left: 0;
}
.footer-top .footer-item .footer-inner .footer-content .content ul li {
  padding: 8px 0;
}
.footer-top
  .footer-item.our-address
  .footer-inner
  .footer-content
  .content
  ul
  li
  i {
  margin-top: 10px;
}
.footer-bottom {
  padding: 35px 0;
  background: #fff;
}
.footer-bottom .section-wrapper p {
  text-align: center;
  margin-bottom: 0;
}
.footer-bottom .section-wrapper p a {
  padding: 5px;
  font-weight: 600;
}
.footer-bottom .section-wrapper p a:hover {
  color: #318ce7;
}
.news-footer-wrap {
  background: #1f1f33;
}
@media (max-width: 1439px) {
  .news-footer-wrap .fs-shape {
    display: none;
  }
}
.news-footer-wrap .fs-shape img.fst-1 {
  top: 0;
  left: -60px;
}
.news-footer-wrap .fs-shape img.fst-2 {
  bottom: 104px;
  right: 0;
}
.news-letter {
  padding: 60px 0;
}
.news-letter .section-wrapper {
  border-bottom: 1px solid #363648;
  padding-bottom: 40px;
  z-index: 1;
}
@media (min-width: 992px) {
  .news-letter .section-wrapper {
    margin-bottom: 40px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .news-letter .section-wrapper {
    justify-content: space-between;
    text-align: left;
  }
}
.news-letter .section-wrapper .news-title {
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .news-letter .section-wrapper .news-title {
    width: 40%;
    margin-bottom: 0;
  }
}
.news-letter .section-wrapper .news-form {
  width: 100%;
}
@media (min-width: 1200px) {
  .news-letter .section-wrapper .news-form {
    width: calc(100% - 50%);
  }
}
@media (min-width: 576px) {
  .news-letter .section-wrapper .news-form form .nf-list {
    background: #fff;
  }
}
.news-letter .section-wrapper .news-form form .nf-list::after {
  content: "";
  width: 30px;
  height: 30px;
  top: 18px;
  left: 10px;
  font-size: 1.5rem;
  font-family: IcoFont !important;
}
@media (min-width: 576px) {
  .news-letter .section-wrapper .news-form form .nf-list::after {
    top: 50%;
    transform: translateY(-50%);
  }
}
.news-letter .section-wrapper .news-form form .nf-list input {
  box-shadow: none;
  border: none;
  outline: none;
}
.news-letter .section-wrapper .news-form form .nf-list input[type="email"] {
  padding: 20px;
  padding-left: 50px;
  width: 100%;
}
@media (min-width: 576px) {
  .news-letter .section-wrapper .news-form form .nf-list input[type="email"] {
    padding-right: 170px;
  }
}
@media (min-width: 1200px) {
  .news-letter .section-wrapper .news-form form .nf-list input[type="email"] {
    width: auto;
  }
}
.news-letter .section-wrapper .news-form form .nf-list input[type="submit"] {
  width: 200px;
  font-size: 1rem;
  font-weight: 700;
  width: 162px;
  height: 54px;
  border: none;
  outline: none;
  margin-top: 20px;
}
@media (min-width: 576px) {
  .news-letter .section-wrapper .news-form form .nf-list input[type="submit"] {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    margin-top: 0;
  }
} 